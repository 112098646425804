import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_REMOTE } from "../../../routes";
import axios from "axios";
import User from "../../../requests/currentUser/User";

const VerifProfil = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const [verifPicture, setVerifPicture] = useState(null)

  // Récupération de l'utilisateur courant
  const userObj = User();

  if(verifPicture) {
    userObj = verifPicture
  }

  const handleImageUpload = () => {
    const formData = new FormData();
    formData.append("image", selectedImage);
    const userId = userObj.user.id;

    axios
      .post(`${API_REMOTE}/upload/${userId}/verif`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log(response.data);
        const updatedUserObj = {
          ...userObj,
          user: {
            ...userObj.user,
            image_verif: response.data.imagePath,
          },
        };
        setVerifPicture(updatedUserObj);
        setSelectedImage(null);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleLogout = () => {
    Cookies.remove("jwt");
    window.location.href = "/login";
  };

  return (
    <>
      <h1 className="text-center">Vous devez être vérifié par notre staff</h1>
      <section className="h-[50vh] flex flex-col justify-center items-center">
        <img
          src={
            userObj && userObj.user.image_verif
              ? `${API_REMOTE}/${userObj.user.image_verif}`
              : "/joker.jpg"
          }
          className="h-64"
          alt="profil"
        />
        <input type="file" accept="image/*" onChange={handleImageChange} />
        <button
          className="bg-purple-800 my-6 p-6 text-white rounded-xl"
          onClick={handleImageUpload}
        >
          Mettre à jour l'image
        </button>
        <div>
          <button
            className="bg-purple-600 text-white rounded p-6"
            onClick={handleLogout}
          >
            ME DÉCONNECTER
          </button>
        </div>
      </section>
    </>
  );
};

export default VerifProfil;
