import CookieConsent from "react-cookie-consent";
export default function CookieCon() {
  return (
    <section>
      <CookieConsent
        location="bottom"
        buttonText="J'accepte les cookies"
        cookieName="CookieConsent"
        style={{ background: "#572373" }}
        buttonStyle={{
          background: "#fefefe",
          padding: ".5em",
          fontWeight: "600",
        }}
        expires={150}
        declineButtonText="Je refuse les cookies"
        enableDeclineButton
        declineButtonStyle={{ padding: ".5em", fontWeight: "600" }}
      >
        <div style={{display:"flex", alignItems:"center" ,gap:"1em"}}>
          <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
            Ce site Web utilise des cookies pour améliorer l'expérience
            utilisateur.
          </p>
          <img
            style={{
              width: "50px",
              height: "50px",
              objectFit: "cover",
              borderRadius:"50%"
            }}
            src="/cookie.jpg"
            alt=""
          />
        </div>
      </CookieConsent>
    </section>
  );
}
