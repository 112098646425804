import { useNavigate } from "react-router-dom";
import "../css/register/register.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faHouse,
  faPhoneFlip,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { API_REMOTE } from "../routes";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Cookies from "js-cookie";

///
import useDocumentTitle from "../components/DocumentTitle";
import Seo from "../components/Seo";

export default function Register() {
  const navigate = useNavigate();
  let { register, handleSubmit } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  // const [isLoading, setIsLoading] = useState(false);

  let onSubmit = async (e) => {
    try {
      // setIsLoading(true);
      const response = await axios.post(`${API_REMOTE}/register`, e);
      console.log(response.data);
      Cookies.set("user", JSON.stringify(response.data)); // enregistrement des données de l'utilisateur dans les cookies
      // setIsLoading(false);
      navigate("/login");
    } catch (error) {
      console.log(error.response.data);
      setErrorMessage(error.response.data);
      // setIsLoading(false);
    }
  };

  useDocumentTitle("Inscription - Retrogems");
  return (
    <section>
      <Seo title={"Inscription Gratuite au Monde du Jeu rétro, next gen, des figurines et des objets de collecrions rares"}
      description={"Bienvenue sur la page d'inscription de Retrogems ! Ici commence votre voyage vers une communauté dynamique de passionnés de jeux vidéo rétros comme next gen, de figurines et des objets de collections rares. Rejoignez-nous dès maintenant pour créer votre profil gaming personnalisé, accéder à des offres exclusives et faire partie intégrante de notre univers virtuel."}/>
      <div id="login-contain" style={{ backgroundImage: `url("/market.jpg")` }}>
        <form  class="login-form" onSubmit={handleSubmit(onSubmit)}>
          <div id="login-flex" className="subs-form">
            <h1 className="">INSCRIPTION</h1>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="text"
                  id="nom"
                  name="lastname"
                  required
                  placeholder="Nom"
                  {...register("lastname", { required: true })}
                />
                <FontAwesomeIcon icon={faUser} className="text-black" />
              </div>
            </div>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="text"
                  id="prenom"
                  name="firstname"
                  required
                  placeholder="Prénom"
                  {...register("firstname", { required: true })}
                />
                <FontAwesomeIcon icon={faUser} className="text-black" />
              </div>
            </div>

            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="E-mail"
                  {...register("email", { required: true })}
                />
                <FontAwesomeIcon icon={faEnvelope} className="text-black" />
              </div>
            </div>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="number"
                  id="numero"
                  name="number"
                  required
                  placeholder="Numéro de téléphone"
                  {...register("number", { required: true })}
                />
                <FontAwesomeIcon icon={faPhoneFlip} className="text-black" />
              </div>
            </div>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="text"
                  id="adresse"
                  name="address"
                  required
                  placeholder="Adresse"
                  {...register("address", { required: true })}
                />
                <FontAwesomeIcon icon={faHouse} className="text-black" />
              </div>
            </div>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="password"
                  id="password"
                  name="password"
                  required
                  placeholder="Mot de passe"
                  {...register("password", { required: true })}
                />
                <FontAwesomeIcon icon={faLock} className="text-black" />
              </div>
            </div>
            <div id="login-btn-container" className="mt-4">
              <button id="login-btn" type="submit">
                S'INSCRIRE
              </button>
            </div>
          </div>
        </form>
        {errorMessage && (
          <p className="text-red-500 my-4 max-w-[10rem] text-center">
            {errorMessage.message}
          </p>
        )}
      </div>
    </section>
  );
}
