import Seo from "../../components/Seo";
import "../../css/politiques/mentions.scss";
export default function MentionsLegales() {
  return (
    <section>
      <Seo
        title={"Mentions Légales et Conditions d'Utilisation"}
        description={
          "Bienvenue sur la page des mentions légales de Retrogems. Cette section est dédiée à vous fournir des informations transparentes et essentielles sur nos politiques, conditions d'utilisation et engagements juridiques. Nous nous engageons à respecter votre confidentialité et à assurer une expérience en ligne sécurisée et conforme aux normes légales."
        }
      />
      <div id="mention-bloc" style={{ backgroundImage: `url('/market.jpg')` }}>
        <div id="mention-container">
          <h1>Mentions Légales</h1>
          <p>
            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004
            pour la confiance en l'économie numérique, il est précisé aux
            utilisateurs du Site l'identité des différents intervenants dans le
            cadre de sa réalisation et de son suivi.
          </p>
          <div>
            <h2>Édition du site :</h2>
            <p>
              Le site retrogems.fr est édité par la société Yolaw S.A.S., au
              capital social de 1 000,00 € , immatriculée au Registre du
              commerce et des sociétés de Paris sous le n° 753 892 926 et dont
              le siège social est situé au 20-26 Boulevard du Parc 92200
              Neuilly-sur-Seine (Siret n° 980 136 410 00019 et TVA
              intracommunautaire n° FR33980136410).
            </p>
          </div>
          <div>
            <h2>Responsable de publication :</h2>
            <p>Monsieur Richard Attal, CEO de retrogems</p>
          </div>
          <div>
            <h2>Hébergeur :</h2>
            <p>
              Le site retrogems.fr est hébergé par la société Heroku Inc., 650
              7th Street, San Francisco, CA (tel : +33 1 (877) 563-4311). Le
              stockage des données personnelles des utilisateurs est
              exclusivement réalisé sur les centres de données ("clusters") de
              la société Amazon Inc, dont le siège social est situé 10 Terry
              Avenue North, Seattle, WA. Tous les clusters Amazon sur lesquels
              les données du Site sont stockées sont localisés dans des Etats
              membres de l'Union Européenne.
            </p>
          </div>
          <div>
            <h2>Nous contacter :</h2>
            <p>
              Par téléphone : 01 76 39 00 60{" "}
              <p>Par email : contact@legalstart.fr</p>{" "}
              <p>
                Par courrier : Legalstart.fr (Yolaw SAS), 50 rue d'Hauteville,
                75010 Paris
              </p>
            </p>
          </div>
          <div>
            {" "}
            <h2>Votre vie privée :</h2>
            <p>
              Le traitement de vos données à caractère personnel est régi par
              notre Charte du respect de la vie privée conformément au Règlement
              Général sur la Protection des Données 2016/679 du 27 avril 2016 («
              RGPD »). Yolaw SAS a désigné un Délégué à la Protection des
              Données (DPO) auprès de la CNIL (Désignation N° DPO-1777). Les
              coordonnées de notre Délégué à la Protection des Données sont les
              suivantes: Me Yaël Cohen-Hadria - Cabinet Marvell Avocats Adresse
              : 20-26 Boulevard du Parc 92200 Neuilly-sur-Seine Tél : 0153431313
              Email : ycohenhadria@marvellavocats.com Pour toute question
              concernant vos données personnelles ou si vous souhaitez supprimer
              votre Compte, merci de nous contacter à l’adresse suivante: Yolaw
              SAS, 50 rue d'Hauteville, 75010 Paris (en indiquant "Vie Privée -
              Protection des Données") ou par email à privacy@legalstart.fr.
            </p>
          </div>
          <div>
            <h2>En savoir plus :</h2>
            <p>
              Cliquez ici pour en savoir plus sur les mentions légales
              obligatoires.
            </p>
          </div>
          <div>
            <h2>Index de l'égalité hommes-femmes :</h2>
            <p>
              L'index de l'égalité hommes-femmes 2023 de Legalstart est de 86
              (période de référence 2022). Découvrez le détail du calcul en
              cliquant ici.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
