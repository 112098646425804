import "../css/footer/footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { API_REMOTE } from "../routes";
import { Link, useNavigate } from "react-router-dom";
import ChatModal from "./Chat/ChatModal";
import BtnBWborder from "./asset/btn/BtnTWborder";
import User from "../requests/currentUser/User";

const socket = io.connect(API_REMOTE, {
  transports: ["websocket"],
  withCredentials: true,
});

export default function Footer() {
  const navigate = useNavigate();
  const [notif, setNotif] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Récupération de l'utilisateur courant
  const userObj = User();

  const handleUserClick = () => {
    setSelectedUser(userObj);
    setNotif(false);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModalIsOpen(false);
  };

  // Gestionnaire d'événements "connect" pour émettre "user-connect" après le rechargement de la page
  useEffect(() => {
    socket.on("notification", (senderId) => {
      console.log("notif ok ", senderId);
    });

    socket.on("unique_notification", () => {
      console.log(socket);
      setNotif(true);
    });

    // Lorsque le composant est monté, émettez l'événement "is_connect" avec l'identifiant de l'utilisateur si disponible
    if (userObj && userObj?.user?.id) {
      socket.emit("is_connect", userObj.user.id);
    }

    // Écoutez l'événement "connect" du socket.io et réémettez "is_connect" lorsque la connexion est établie
    socket.on("connect", () => {
      if (userObj && userObj?.user?.id) {
        socket.emit("is_connect", userObj.user.id);
      }
    });

    // Lorsque le composant est démonté, émettez l'événement "is_not_connect" avec l'identifiant de l'utilisateur si disponible
    return () => {
      if (userObj && userObj?.user?.id) {
        socket.emit("is_not_connect", userObj.user.id);
      }
    };
  }, [socket, userObj]);

  return (
    <footer>
      <div
        className={`${
          notif ? "motion-safe:animate-bounce" : ""
        } hidden md:flex fixed bottom-6 right-6 p-3 rounded-full bg-white cursor-pointer shadow-xl z-40`}
        onClick={handleUserClick}
      >
        <div className="text-purple-600">
          {notif && (
            <span class="absolute right-0 top-0 mr-1 mt-1 flex h-3 w-3">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-500 opacity-75"></span>
              <span class="relative inline-flex rounded-full h-3 w-3 bg-purple-600"></span>
            </span>
          )}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-12 h-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
            />
          </svg>
        </div>
      </div>
      {modalIsOpen && (
        <ChatModal
          selectedUserDefault={userObj && userObj.user}
          closeModal={closeModal}
          modalIsOpen={true}
        />
      )}
      <video autoPlay loop muted src="/videoRetro.mp4"></video>
      <div id="footer-contain">
        <div id="footer-flex">
          <div id="footer-newsletter">
            <div id="newsletter-title">
              <h4>ÊTRE ALERTER SUR L'ARRIVÉE DES NOUVEAUX JOYAUX</h4>
            </div>
            <div id="footer-input">
              <div className="footer-input-container">
                <input type="text" className="text-black" />
                <FontAwesomeIcon icon={faEnvelope} className="text-black" />
              </div>
              <BtnBWborder
                id={"newsletter-subscribe"}
                contenu={"S'ABONNER"}
              />
            </div>
           <div className="flex gap-5">
            <Link to={"https://www.facebook.com/profile.php?id=100094818308693"}><img className="w-8" src="/facebook.png" alt="" /></Link>
            <Link to={"https://www.instagram.com/retrogems.fr/"}>
            <img className="w-8" src="/insta.png" alt="" />
            </Link>
           </div>
          </div>
          <div id="footer-list">
            <div className="footer-title">
              <h4 className="footer-h4">A PROPOS</h4>
              <div>
                <p>NOTRE HISTOIRE</p>
                <p>CGV-CGU</p>
                <Link to={"/mentions-legales"}>MENTIONS LEGALES</Link>
                <p>EVENEMENT</p>
              </div>
            </div>
            <div className="footer-title">
              <h4 className="footer-h4">MARKETPLACE</h4>
              <div>
                <p>NEXT GEN</p>
                <p>RETROGAMING</p>
                <p>FIGURINES</p>
                <p>Funko pop</p>
                <p>Hikari funko</p>
                <p>Bandai</p>
                {/* <p>Dragon Ball</p>
                <p>Power Rangers</p>
                <p>Tortues Ninja</p>
                <p>Naruto</p>
                <p>Saint Seiya</p>
                <p>Cowboy Bebop</p>
                <p>Fairy Tail</p>
                <p>GTO</p>
                <p>Bleach</p>
                <p>Death Note</p>
                <p>My Hero Academia</p>
                <p>One Piece</p>
                <p>Goldorak</p>
                <p>Autres...</p>
                <p>Square Enix</p>
                <p>Final Fantasy</p>
                <p>Dragon Quest</p>
                <p>Kingdom Hearts</p>
                <p>Amiibo</p>
                <p>DC Comics</p>
                <p>Marvel</p>
                <p>Tsume</p>
                <p>Dragon Ball</p>
                <p>Saint Seiya</p>
                <p>Cowboy Bebop</p>
                <p>Fairy Tail</p>
                <p>GTO</p>
                <p>Bleach</p>
                <p>Death Note</p>
                <p>My Hero Academia</p>
                <p>Terminator</p>
                <p>DC Comics</p>
                <p>One Piece</p>
                <p>Goldorak</p>
                <p>Autres...</p>
                <p>GOODIES</p>
                <p>Objet Promotionnel / PLV</p>
                <p>T-Shirt Geek</p>
                <p>Porte-clés</p>
                <p>Autres...</p>
                <p>JEUX CONSTRUCTION</p>
                <p>Lego</p>
                <p>Playmobil</p>
                <p>Mecano</p>
                <p>Kapla</p>
                <p>Autres...</p>
                <p>JOUET ANCIEN</p>
                <p>Barbie</p>
                <p>Corolle</p>
                <p>Bandai</p>
                <p>Dragon Ball</p>
                <p>Power Rangers</p>
                <p>Saint Seiya</p>
                <p>Goldorak</p>
                <p>DC Comics</p>
                <p>Tortues Ninja</p>
                <p>Autres...</p>
                <p>Jouet en bois</p>
                <p>Autres...</p>
                <p>CARTE A COLLECTIONNÉ</p>
                <p>Pokemon</p>
                <p>Yu-Gi-Oh</p>
                <p>Dragon Ball</p>
                <p>Panini</p>
                <p>Carte Nintendo</p>
                <p>Magic</p>
                <p>Autres...</p>
                <p>MINIATURE AUTOMOBILE</p>
                <p>Hotwheels</p>
                <p>Majorette</p>
                <p>Burago</p>
                <p>Autres...</p> */}
              </div>
            </div>
            <div className="footer-title">
              <h4 className="footer-h4">GRADING</h4>
              <div>
                <p>GRADEZ VOS JEUX</p>
                <p>GRADEZ VOS CARTES A COLLECTIONNÉ</p>
                <p>GRADEZ VOS FIGURINES</p>
                <p>GRADEZ VOS JOUETS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
