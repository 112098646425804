export const API_REMOTE = "https://api.retrogems.fr";
// export const API_REMOTE = "http://localhost:3000";

export const PAIEMENT = "/payment";
export const CREDIT = "/credit";
export const PANIER = "/panier";
export const CONTACT = "/contact";
export const MESSAGES = "/messages";

export const PROFIL = "/profil";
export const FAVORIS = PROFIL + "/favoris";
export const ADMIN = "/admin";
export const ADMINVERIFUSER = ADMIN + "/verif-users";
export const ADMINVERIFANNOUNCE = ADMIN + "/verif-announces";
export const ADMINACTUS = ADMIN + "/actus";
export const ADMINCREATEACTUS = ADMINACTUS + "/create";

//buil https://api.retrogems.fr"
//build https://api.retrogems.fr"
