import ActualiteSlider from "../Introduc/ActualiteSlider";
import ActualiteBody from "../Body/ActualiteBody";
import './ActualiteAccueil.scss'

const ActualiteAccueil = () => {
    return (
    <div className="pag">
        <ActualiteSlider />
        <ActualiteBody />
       
    </div>
    )
}

export default ActualiteAccueil;