import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_REMOTE } from "../../routes";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import HeaderAdmin from "./HeaderAdmin";
import ImageModal from "../Profil/ImageModal";
import User from "../../requests/currentUser/User";

const AdminVerifUser = () => {
  const [users, setUsers] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(""); // Nouvel état pour le mot-clé de recherche
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedUser, setSelectedUser] = useState("");
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [verifenplus, setVerifenplus] = useState(0);
  const navigate = useNavigate();

  // Récupération de l'utilisateur courant
  const userObj = User();

  const handleImageModal = (image) => {
    setImageModalOpen(true);
    setSelectedUser(image);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const handleAccept = async (userId) => {
    const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");

    const response = await axios.put(
      `${API_REMOTE}/users/${userId}/verif`,
      { is_verified: 1 },
      {
        headers: {
          authorization: `${jwtCookie}`,
        },
      }
    );

    if (response && response.data) {
      setVerifenplus(verifenplus + 1);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
        const response = await axios.get(`${API_REMOTE}/users/verif`, {
          headers: {
            authorization: `${jwtCookie}`,
          },
        });

        if (response && response.data) {
          // console.log(response.data);
          setUsers(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUsers();
  }, [verifenplus]);

  const deleteUser = async (userId) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir bannir cet utilisateur ?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.delete(`${API_REMOTE}/users/${userId}`, {
        headers: {
          authorization: `${jwtCookie}`,
        },
      });
      const updatedUsers = users.filter((user) => user.id !== userId);
      setUsers(updatedUsers);
      // console.log(updatedUsers)
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur :", error);
    }
  };

  // Fonction pour filtrer les utilisateurs en fonction du mot-clé de recherche
  const filterUsers = (users) => {
    if (!searchKeyword) {
      return users;
    }
    const keyword = searchKeyword.toLowerCase();
    return users.filter((user) => {
      return (
        user.firstname.toLowerCase().includes(keyword) ||
        user.lastname.toLowerCase().includes(keyword) ||
        user.email.toLowerCase().includes(keyword) ||
        user.roles.toLowerCase().includes(keyword)
      );
    });
  };

  // Fonction pour trier les utilisateurs en fonction de la clé et de la direction de tri
  const sortUsers = (users, key, direction) => {
    return [...users].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Fonction pour gérer le clic sur les boutons de tri
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Appliquer le tri et le filtre aux utilisateurs
  const sortedUsers = sortUsers(
    filterUsers(users),
    sortConfig.key,
    sortConfig.direction
  );

  // Fonction pour obtenir la classe CSS du bouton de tri actif
  const getSortButtonClass = (key) => {
    if (sortConfig.key === key) {
      return `focus:outline-none ${
        sortConfig.direction === "asc" ? "text-color-one" : "text-color-two"
      }`;
    }
    return "focus:outline-none";
  };

  // Fonction pour obtenir la classe CSS de la flèche de tri
  const getSortArrowClass = (key) => {
    if (sortConfig.key === key) {
      return `inline-block ml-1 ${
        sortConfig.direction === "asc" ? "transform rotate-180" : ""
      }`;
    }
    return "inline-block ml-1 hidden";
  };

  if (userObj && userObj.user.roles.includes("admin")) {
    return (
      <section className="flex h-full">
        <HeaderAdmin />
        <div
          className="flex flex-col items-center p-6 w-full"
          style={{ backgroundImage: `url('/market.jpg')` }}
        >
          <div className="flex flex-col w-full">
            <div className="relative flex items-center md:w-2/3 max-w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden mb-4">
              <div className="grid place-items-center h-full w-12 text-gray-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                type="text"
                id="search"
                placeholder="Recherche..."
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              {/* Ajouter un petit bouton pour tri ascendant et descendant */}
            </div>
            <h1 className="text-3xl underline">
              Liste des utilisateurs à valider ({sortedUsers.length}) :
            </h1>
            <div className="w-1/3">{/* Boutons filtre roles */}</div>
            <div className="w-full overflow-auto">
              <table className="md:w-full">
                <thead>
                  <tr>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("firstname")}
                        onClick={() => handleSort("firstname")}
                      >
                        Prénom
                        <span className={getSortArrowClass("firstname")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("lastname")}
                        onClick={() => handleSort("lastname")}
                      >
                        Nom
                        <span className={getSortArrowClass("lastname")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("email")}
                        onClick={() => handleSort("email")}
                      >
                        Email
                        <span className={getSortArrowClass("email")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("roles")}
                        onClick={() => handleSort("roles")}
                      >
                        Roles
                        <span className={getSortArrowClass("roles")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1">Domicile</th>
                    <th className="border-2 p-1 ">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUsers.map((user) => (
                    <tr
                      className="odd:bg-purple-200 even:bg-purple-300"
                      key={user.id}
                    >
                      <td className="border p-1">{user.firstname}</td>
                      <td className="border p-1">{user.lastname}</td>
                      <td className="border p-1">{user.email}</td>
                      <td className="border p-1">{user.roles}</td>
                      <td className="border p-1">{user.address}</td>
                      <td>
                        <button
                          onClick={() => deleteUser(user.id)}
                          className="border p-2 bg-red-500 rounded-md ml-4"
                        >
                          Bannir l'utilisateur
                        </button>
                        <button
                          className="border p-2 bg-green-400 rounded-md ml-4"
                          onClick={() => handleAccept(user.id)}
                        >
                          Accepter l'utilisateur
                        </button>
                        <button
                          className="border p-2 bg-purple-800 text-white rounded-md ml-4"
                          onClick={() => handleImageModal(user.image_verif)}
                        >
                          Afficher la vérification de l'utilisateur
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {isImageModalOpen && (
          <ImageModal
            imageUrl={
              userObj.user.image
                ? `${API_REMOTE}/${selectedUser}`
                : "/joker.jpg"
            }
            closeModal={closeImageModal}
          />
        )}
      </section>
    );
  }
  return (
    <div className="w-full flex justify-center">
      <iframe
        src="https://giphy.com/embed/bKj0qEKTVBdF2o5Dgn"
        width="480"
        height="352"
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default AdminVerifUser;
