import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_REMOTE, PROFIL } from "../routes";
import "../css/panier/panier.scss";
import "../css/store/store.scss";
import StoreCard from "../components/StoreCard";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../components/Pagination";
import useDocumentTitle from "../components/DocumentTitle";
import Scroll from "../components/Scroll";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import BtnBW from "../components/asset/btn/BtnBW";
import User from "../requests/currentUser/User";
import FilterSearchBar from "../components/asset/filter/FilterSearchBar";
import FilterCategory from "../components/asset/filter/FilterCategory";
import Seo from "../components/Seo";

const Store = (filter) => {
  const [annonces, setAnnonces] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [activeCategory, setActiveCategory] = useState("Tout");
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(8);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const currentPosts = annonces.slice(firstPostIndex, lastPostIndex);
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(filter);

  const getAllAnnonces = async (searchQuery = "") => {
    try {
      const response = await axios.get(
        `${API_REMOTE}/annonces/search?search=${searchQuery}`
      );
      setFiltered(response.data);
      console.log(response.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleSearchChange = (e) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
    setTimeout(() => {
      if (newSearchValue === "") {
        getAllAnnonces([]);
      } else {
        getAllAnnonces(newSearchValue);
      }
    }, 300);
  };
  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleClick = (event) => {
    const searchQuery = event.target.value;
    return searchQuery;
  };

  const s = (cat) => {
    const NewItems = annonces.filter((newVal) => newVal.categorie === cat);
    setAnnonces(NewItems);
    //fetchAnnonces()
    console.log();
  };
  // Récupération de l'utilisateur courant
  const userObj = User();

  // Fonction pour supprimer un article du panier
  const removeFromCart = (articleId) => {
    setArticles(articles.filter((item) => item.id !== articleId));
  };

  // Fonction pour mettre à jour la quantité d'un article dans le panier
  const updateQuantity = (articleId, newQuantity) => {
    setArticles(
      articles.map((item) =>
        item.id === articleId
          ? { ...item, quantity: Math.max(newQuantity, 1) }
          : item
      )
    );
  };
  useEffect(() => {
    const jwtCookie = Cookies.get("jwt");
    if (!jwtCookie) {
      navigate("/login");
    }
  }, [navigate]);

  const getNumberFavorites = async (annonceId) => {
    try {
      const jwtCookie = Cookies.get("jwt");
      const response = await axios.get(
        `${API_REMOTE}/favoris/annonce/${annonceId}`,
        {
          headers: {
            authorization: `${jwtCookie}`,
          },
        }
      );
      return response.data.length;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du nombre de favoris :",
        error
      );
      return 0; // En cas d'erreur, retournez 0
    }
  };

  const toggleFavorite = async (annonceId) => {
    const jwtCookie = Cookies.get("jwt");
    try {
      console.log(userObj, annonceId);
      const response = await axios.get(
        `${API_REMOTE}/favoris/${userObj.user.id}/${annonceId}`,
        {
          headers: {
            authorization: `${jwtCookie}`,
          },
        }
      );

      // Si la réponse est 200, l'annonce est déjà en favori, supprimez le favori
      await axios.delete(`${API_REMOTE}/favoris/${response.data.id}`, {
        headers: {
          authorization: `${jwtCookie}`,
        },
      });

      // Rafraîchir la liste des annonces après la mise à jour des favoris
      fetchAnnonces();
    } catch (error) {
      // Si la requête échoue avec une erreur (y compris 404), ajoutez le favori
      if (error.response.status === 404) {
        try {
          await axios.post(
            `${API_REMOTE}/favoris`,
            { annonceId },
            {
              headers: {
                authorization: `${jwtCookie}`,
              },
            }
          );

          // Rafraîchir la liste des annonces après la mise à jour des favoris
          fetchAnnonces();
        } catch (innerError) {
          console.error("Erreur lors de l'ajout des favoris :", innerError);
        }
      } else {
        console.error("Erreur lors de la gestion des favoris :", error);
      }
    }
  };

  // Fonction pour calculer le total du panier
  const calculateTotal = (cartItems) => {
    return cartItems.reduce(
      (total, item) => total + item.prix * item.quantity,
      0
    );
  };

  useEffect(() => {
    fetchAnnonces();
  }, [articles]);

  const fetchAnnonces = async () => {
    try {
      const jwtCookie = Cookies.get("jwt");
      const response = await axios.get(`${API_REMOTE}/annonces`);
      const annoncesData = response.data;

      // Récupérer les favoris de l'utilisateur
      const favorisResponse = await axios.get(
        `${API_REMOTE}/favoris/${userObj && userObj?.user?.id}`,
        {
          headers: {
            authorization: `${jwtCookie}`,
          },
        }
      );
      const favoris = favorisResponse.data;

      const annoncesAvecFavoris = await Promise.all(
        annoncesData.map(async (annonce) => {
          const numberFavorite = await getNumberFavorites(annonce.id);
          return {
            ...annonce,
            isFavorite: favoris.some(
              (favori) => favori.annonce_id === annonce.id
            ),
            numberFavorite: numberFavorite,
          };
        })
      );

      setFiltered(annoncesAvecFavoris);
      setAnnonces(annoncesAvecFavoris);
    } catch (error) {
      console.error(error);
      console.log(annonces && annonces);
    }
  };

  console.log(filtered);

  return (
    <section>
      <Seo
        title={"Marketplace,profiter de nos jeux pépites,rétro et next gen"}
        description={
          "Parcourez notre vaste gamme d'objets de jeux vidéo rétro, next gen ,de figurines,des cartes de collections , des classiques aux dernières nouveautés. Que vous recherchiez des équipements de gaming de pointe, des pépites, des objets de collection rares ou des accessoires uniques, Retrogems a ce qu'il vous faut. Découvrez une expérience d'achat unique sur Rétrogems. Profitez de prix compétitifs, d'une variété exceptionnelle de produits et d'une garantie de satisfaction. Trouvez les objets de vos rêves jeux rétros, next gen, figurines, cartes de collections et faites-les livrer chez vous en toute sécurité."
        }
      />
      <div id="store-contain" style={{ backgroundImage: `url('/market.jpg')` }}>
        <div id="store-grid">
          <div id="store-category">
            <h1 className="text-3xl font-semibold underline-offset-4 underline mb-4">
              Marketplace
            </h1>
            <div
              id="mk-heading"
              className="flex items-center justify-between mx-2"
            >
              <FilterSearchBar
                handleSearchChange={handleSearchChange}
                searchValue={searchValue}
              />
              <BtnBW contenu={"CREER UNE ANNONCE"} action={"/annonce"} />
            </div>
            <FilterCategory
              fetchAnnonces={fetchAnnonces}
              handleClick={handleClick}
              setFiltered={setFiltered}
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              annonce={annonces}
            />
            <div id="basket" className="flex justify-center"></div>
          </div>
          <div id="store-product" className="mt-10">
            {filtered &&
              filtered.map((annonce) => (
                <StoreCard
                  annonce={annonce}
                  toggleFavorite={toggleFavorite}
                  isAnnouncementInFavorites={annonce.isFavorite}
                  numberFavorites={annonce.numberFavorite}
                />
              ))}
          </div>
          <Pagination
            totalPosts={annonces.length}
            postPerPage={postPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </div>
      <Scroll />
      <ToastContainer />
    </section>
  );
};

export default Store;
