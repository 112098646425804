import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_REMOTE } from "../../routes";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import HeaderAdmin from "./HeaderAdmin";
import AnnonceModal from "../Profil/AnnonceModal";
import User from "../../requests/currentUser/User";

const AdminVerifAnnouncement = () => {
  const [annonces, setAnnonces] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(""); // Nouvel état pour le mot-clé de recherche
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedAnnonce, setSelectedAnnonce] = useState("");
  const [isAnnonceModalOpen, setAnnonceModalOpen] = useState(false);
  const [verifenplus, setVerifenplus] = useState(0);
  const navigate = useNavigate();

  // Récupération de l'utilisateur courant
  const userObj = User();

  const handleAnnonceModal = (annonceId) => {
    setAnnonceModalOpen(true);
    setSelectedAnnonce(annonceId);
  };

  const closeAnnonceModal = () => {
    setAnnonceModalOpen(false);
  };

  const handleAccept = async (annonceId) => {
    const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");

    const response = await axios.put(
      `${API_REMOTE}/annonces/${annonceId}/verif`,
      { is_verified: 1 },
      {
        headers: {
          authorization: `${jwtCookie}`,
        },
      }
    );

    if (response && response.data) {
      setVerifenplus(verifenplus + 1);
    }
  };

  useEffect(() => {
    const getAnnounces = async () => {
      try {
        const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
        const response = await axios.get(`${API_REMOTE}/annonces/verif`, {
          headers: {
            authorization: `${jwtCookie}`,
          },
        });

        if (response && response.data) {
          // Récupération des annonces
          const fetchedAnnounces = response.data;

          // Récupération des utilisateurs associés à chaque annonce
          const usersPromises = fetchedAnnounces.map(async (annonce) => {
            const userResponse = await axios.get(
              `${API_REMOTE}/users/${annonce.user_id}`,
              {
                headers: {
                  authorization: `${jwtCookie}`,
                },
              }
            );
            return userResponse.data; // Retourne l'utilisateur associé à l'annonce
          });

          // Attendre que toutes les requêtes d'utilisateur soient terminées
          const users = await Promise.all(usersPromises);

          // Mapper les annonces avec les utilisateurs correspondants
          const mergedAnnounces = fetchedAnnounces.map((annonce, index) => {
            return {
              ...annonce,
              user: users[index], // Ajoutez l'utilisateur associé à chaque annonce
            };
          });

          // Mettre à jour l'état des annonces avec les utilisateurs associés
          setAnnonces(mergedAnnounces);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAnnounces();
  }, [verifenplus]);

  const deleteUser = async (userId) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir bannir cet utilisateur ?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.delete(`${API_REMOTE}/users/${userId}`, {
        headers: {
          authorization: `${jwtCookie}`,
        },
      });
      const updatedAnnonces = annonces.filter((user) => user.id !== userId);
      setAnnonces(updatedAnnonces);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur :", error);
    }
  };

  // Fonction pour filtrer les utilisateurs en fonction du mot-clé de recherche
  const filterAnnonces = (annonces) => {
    if (!searchKeyword) {
      return annonces;
    }
    const keyword = searchKeyword.toLowerCase();
    return annonces.filter((annonce) => {
      return (
        annonce.name?.toLowerCase().includes(keyword) ||
        annonce.description?.toLowerCase().includes(keyword) ||
        annonce.categorie?.toLowerCase().includes(keyword) ||
        annonce.sous_categorie?.toLowerCase().includes(keyword) ||
        annonce.sous_sous_categorie?.toLowerCase().includes(keyword) ||
        annonce.user?.firstname?.toLowerCase().includes(keyword) ||
        annonce.user?.lastname?.toLowerCase().includes(keyword)
      );
    });
  };

  // Fonction pour trier les utilisateurs en fonction de la clé et de la direction de tri
  const sortAnnonces = (annonces, key, direction) => {
    return [...annonces].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Fonction pour gérer le clic sur les boutons de tri
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Appliquer le tri et le filtre aux utilisateurs
  const sortedAnnonces = sortAnnonces(
    filterAnnonces(annonces),
    sortConfig.key,
    sortConfig.direction
  );

  // Fonction pour obtenir la classe CSS du bouton de tri actif
  const getSortButtonClass = (key) => {
    if (sortConfig.key === key) {
      return `focus:outline-none ${
        sortConfig.direction === "asc" ? "text-color-one" : "text-color-two"
      }`;
    }
    return "focus:outline-none";
  };

  // Fonction pour obtenir la classe CSS de la flèche de tri
  const getSortArrowClass = (key) => {
    if (sortConfig.key === key) {
      return `inline-block ml-1 ${
        sortConfig.direction === "asc" ? "transform rotate-180" : ""
      }`;
    }
    return "inline-block ml-1 hidden";
  };

  if (userObj && userObj.user.roles.includes("admin")) {
    return (
      <section className="flex h-full">
        <HeaderAdmin />
        <div
          className="flex flex-col items-center p-6 w-full"
          style={{ backgroundImage: `url('/market.jpg')` }}
        >
          <div className="flex flex-col w-full">
            <div className="relative flex items-center md:w-2/3 max-w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden mb-4">
              <div className="grid place-items-center h-full w-12 text-gray-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                type="text"
                id="search"
                placeholder="Recherche..."
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              {/* Ajouter un petit bouton pour tri ascendant et descendant */}
            </div>
            <h1 className="text-3xl underline">
              Liste des annonces à valider ({annonces.length}) :
            </h1>
            <div className="w-1/3">{/* Boutons filtre roles */}</div>
            <div className="w-full overflow-auto">
              <table className="md:w-full">
                <thead>
                  <tr>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("name")}
                        onClick={() => handleSort("name")}
                      >
                        Titre
                        <span className={getSortArrowClass("name")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("description")}
                        onClick={() => handleSort("description")}
                      >
                        Description
                        <span className={getSortArrowClass("description")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("categorie")}
                        onClick={() => handleSort("categorie")}
                      >
                        Catégorie
                        <span className={getSortArrowClass("categorie")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("prix")}
                        onClick={() => handleSort("prix")}
                      >
                        Prix
                        <span className={getSortArrowClass("prix")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("user_id")}
                        onClick={() => handleSort("user_id")}
                      >
                        Utilisateur
                        <span className={getSortArrowClass("user_id")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1 ">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedAnnonces.map((annonce) => {
                    return (
                      <tr
                        className="odd:bg-purple-200 even:bg-purple-300"
                        key={annonce.id}
                      >
                        <td className="border p-1">{annonce.name}</td>
                        <td className="border p-1 max-w-[200px]">
                          <div className="max-h-14 overflow-hidden overflow-ellipsis relative">
                            {annonce.description}
                            {annonce.description.length > 60 && (
                              <span className="absolute right-0 bottom-0 text-2xl text-gray-500">
                                ...
                              </span>
                            )}
                          </div>
                        </td>

                        <td className="border p-1">
                          <div>
                            <div className="flex">
                              <span className="text-purple-600">#</span>{" "}
                              {annonce.categorie}
                            </div>
                            <div className="flex">
                              <span className="text-purple-600"># </span>
                              {annonce.sous_categorie}
                            </div>
                            {annonce.sous_sous_categorie && (
                              <div className="flex">
                                <span className="text-purple-600">#</span>{" "}
                                {annonce.sous_sous_categorie}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="border p-1">
                          {new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          }).format(annonce.prix / 100)}
                        </td>
                        <td className="border p-1">
                          <div className="flex items-center">
                            <img
                              className="h-10 w-10 object-cover rounded-full mr-2"
                              src={`${API_REMOTE}/${
                                annonce.user && annonce.user.image
                              }`}
                              alt=""
                            />
                            <span>
                              {annonce.user && annonce.user.firstname}{" "}
                              {annonce.user && annonce.user.lastname}
                            </span>
                          </div>
                        </td>
                        <td>
                          <button
                            onClick={() => deleteUser(annonce.id)}
                            className="border p-2 bg-red-500 rounded-md ml-4"
                          >
                            Refuser la publication
                          </button>
                          <button
                            className="border p-2 bg-green-400 rounded-md ml-4"
                            onClick={() => handleAccept(annonce.id)}
                          >
                            Accepter la publication
                          </button>
                          <button
                            className="border p-2 bg-purple-800 text-white rounded-md ml-4"
                            onClick={() => handleAnnonceModal(annonce.id)}
                          >
                            Afficher la publication
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {isAnnonceModalOpen && (
          <AnnonceModal
            annonceId={selectedAnnonce}
            closeModal={closeAnnonceModal}
          />
        )}
      </section>
    );
  }
  return (
    <div className="w-full flex justify-center">
      <iframe
        src="https://giphy.com/embed/bKj0qEKTVBdF2o5Dgn"
        width="480"
        height="352"
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default AdminVerifAnnouncement;
