import React from "react";

const Btn = ({ action, value, contenu, classe, id }) => {
    return (
      <button id={id} onClick={action} value={value} className={classe}>
        {contenu}
      </button>
    );
  };
  
export default Btn;