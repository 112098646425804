import "../../css/HomeStyle/hero/hero.scss";
import { useState } from "react";
import axios from "axios";
import { API_REMOTE } from "../../routes";
import { Link } from "react-router-dom";

export default function Hero() {
  const [annonces, setAnnonces] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const getAllAnnonces = async (searchQuery = "") => {
    try {
      const response = await axios.get(
        `${API_REMOTE}/annonces/search?search=${searchQuery}`
      );
      setAnnonces(response.data);
      // console.log(response.data);
    } catch (e) {
      console.log(e.message);
    }
  };
  const handleSearch = async () => {
    if (searchValue === "") {
      setAnnonces([]);
    } else {
      await getAllAnnonces(searchValue);
    }
  };

  const truncateName = (name) => {
    if (name.length > 10) {
      return name.substring(0, 10) + "...";
    }
    return name;
  };

  const handleSearchChange = (e) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
    setTimeout(() => {
      if (newSearchValue === '') {
        setAnnonces([]);
      } else {
        getAllAnnonces(newSearchValue);
      }
    }, 300);
  };
  

  return (
    <section>
      <div id="hero-contain">
        <div id="hero-flex">
          <div id="hero-search">
            <div id="hero-title">
              <h1 className="">
                COLLECTIONNEZ LES JOYAUX <br />
                DU RETROGAMING
              </h1>
              <h2>QUE RECHERCHEZ-VOUS ?</h2>
            </div>
            <div className="relative w-1/2 mb-2" id="hero-input">
              <input
                type="search"
                className="block p-2.5 w-full dark:placeholder-gray-400 dark:text-black"
                id="keyword"
                placeholder="Mot clé..."
                required
                value={searchValue}
                // onChange={(e) => setSearchValue(e.target.value)}
                onChange={handleSearchChange}
              />
              {annonces.slice(0, 5).map((annonce) => (
                <Link to={`/annonces/id/${annonce.id}`}>
                  <div
                    id="search"
                    className="p-2 bg-white text-black flex items-start border  "
                    key={annonce.id}
                  >
                    <div
                      className="p-1 w-1/4 overflow-hidden"
                      style={{ width: 75, height: 75 }}
                    >
                      {annonce.images !== null &&
                        annonce.images
                          .slice(1, -1)
                          .split(",")
                          .map((image, index) => (
                            <div key={index}>
                              <img
                                className="object-cover w-full"
                                src={API_REMOTE + "/" + image}
                                style={{ width: 75, height: 75 }}
                                alt=""
                              />
                            </div>
                          ))}
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold">{annonce.name}</h3>
                      <p className="pt-2 text-sm">
                        {truncateName(annonce.description)}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
              <button
                className="absolute top-0 right-0 p-2.5 text-sm font-medium h-11 text-white "
                onClick={handleSearch}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
            <h3 className="text-xl font-semibold" id="text-cate">CATEGORIES :</h3>
            <div className="flex gap-10 mb-3 flex-wrap" id="cate">
              <div className="flex flex-col gap-5 text-center">
                {" "}
                <Link className="link-cate">Retrogaming</Link>
                <Link className="link-cate">Collector</Link>
                <Link className="link-coll">
                  Carte de <br /> collection
                </Link>
              </div>
              <div className="flex flex-col text-center  gap-5">
                {" "}
                <Link className="link-cate " id="nt-gen">
                  Next Gen
                </Link>
                <Link className="link-cate">Goodies</Link>
                <Link className="link-cate">Les pépites</Link>
              </div>
              <div className="flex flex-col text-center gap-5">
                {" "}
                <Link className="link-cate">Figurines</Link>
                <Link className="link-cate">Geek Art</Link>
                <Link className="link-cate">Autres...</Link>
              </div>
            </div>
            {/* <div id="hero-btn" className="space-x-4">
              <Link className="hero-btn-search" onClick={handleSearch}>
                LANCER LA <br /> RECHERCHE
              </Link>
              <Link to={"/annonce"} className="hero-btn-sell">
                VENDRE
              </Link>
            </div> */}
          </div>
          <div id="superman-container">
            <img className="superman" src="/superman.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
