import Header from "../Header";
import Hero from "./Hero";
import "../../css/HomeStyle/header-hero/header-hero.scss";

export default function Main() {
  return (
    <section>
      <div id="header-hero-contain">
        <video id="main-video" autoPlay muted loop>
          <source src="videoRetro.mp4" />
        </video>
        <Header/>
        <Hero />
      </div>
    </section>
  );
}
