import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { API_REMOTE } from "../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHouse,
  faPhoneFlip,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

export default function UpdateProfile() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const updateUser = async (data) => {
    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.put(`${API_REMOTE}/users/${userId}`, data, {
        headers: {
          authorization: `${jwtCookie}`,
        },
      });
      navigate("/profil");
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${API_REMOTE}/users/${userId}`);
        const user = response.data;
        setValue("lastname", user.lastname);
        setValue("firstname", user.firstname);
        setValue("email", user.email);
        setValue("number", user.number);
        setValue("address", user.address);
      } catch (e) {
        console.log(e.message);
      }
    };
    getUser();
  }, [userId, setValue]);

  return (
    <section>
      <div
        id="login-contain"
        className=""
        style={{ backgroundImage: `url("/market.jpg")` }}
      >
        <form
          className="login-form border"
          onSubmit={handleSubmit(updateUser)}
        >
          <div id="login-flex">
            <h1>MODIFIER MES INFORMATIONS</h1>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="text"
                  id="nom"
                  name="lastname"
                  required
                  placeholder="Nom"
                  {...register("lastname", { required: true })}
                />
                <FontAwesomeIcon icon={faUser} className="text-black" />
                {errors.lastname && (
                  <span className="text-red-500">Ce champ est requis</span>
                )}
              </div>
            </div>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="text"
                  id="prenom"
                  name="firstname"
                  required
                  placeholder="Prénom"
                  {...register("firstname", { required: true })}
                />
                <FontAwesomeIcon icon={faUser} className="text-black" />
                {errors.firstname && (
                  <span className="text-red-500">Ce champ est requis</span>
                )}
              </div>
            </div>

            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="E-mail"
                  {...register("email", { required: true })}
                />
                <FontAwesomeIcon icon={faEnvelope} className="text-black" />
                {errors.email && (
                  <span className="text-red-500">Ce champ est requis</span>
                )}
              </div>
            </div>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="number"
                  id="numero"
                  name="number"
                  required
                  placeholder="Numéro de téléphone"
                  {...register("number", { required: true })}
                />
                <FontAwesomeIcon icon={faPhoneFlip} className="text-black" />
                {errors.number && (
                  <span className="text-red-500">Ce champ est requis</span>
                )}
              </div>
            </div>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  type="text"
                  id="adresse"
                  name="address"
                  required
                  placeholder="Adresse"
                  {...register("address", { required: true })}
                />
                <FontAwesomeIcon icon={faHouse} className="text-black" />
                {errors.address && (
                  <span className="text-red-500">Ce champ est requis</span>
                )}
              </div>
            </div>
            <div id="login-btn-container" className="mt-4">
              <button id="login-btn" type="submit">
                MODIFIER
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
