import axios from "axios";
import { useEffect, useState } from "react";
import { API_REMOTE } from "../routes";
import { Link, useParams } from "react-router-dom";
import "../css/vitrine/vitrine.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Vitrine() {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [userAnnouncements, setUserAnnouncements] = useState([]);
  const [note, setNote] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          const userResponse = await axios.get(`${API_REMOTE}/users/${userId}`);
          setUser(userResponse.data);
          // console.log(userResponse.data);
          const noteResponse = await axios.get(`${API_REMOTE}/note/${userId}`);
          setNote(noteResponse.data);
          console.log(noteResponse.data);
          // Récupérer les annonces de l'utilisateur
          const userAnnouncementsResponse = await axios.get(
            `${API_REMOTE}/annonces/user_id/${userId}`
          );
          setUserAnnouncements(userAnnouncementsResponse.data);
          // console.log(userAnnouncements);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données utilisateur :",
          error
        );
      }
    };

    fetchData();
  }, [userId]);

  const truncateName = (name) => {
    if (name.length > 25) {
      return name.substring(0, 25) + "...";
    }
    return name;
  };

  if (!user) {
    return <p>Loading...</p>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section>
      <div
        id="vitrine-contain"
        style={{ backgroundImage: `url('/market.jpg')` }}
      >
        <div id="vitrine-flex">
          {" "}
          <div className="flex items-center gap-4">
            <div className="w-28 ">
              <img
                className="rounded-full h-28 object-cover w-full "
                src={API_REMOTE + "/" + user.image}
                alt=""
              />
            </div>
            <div>
              <h1 className="font-bold">{user.firstname}</h1>
              <h3 className="text-lg">{user.lastname}</h3>
            </div>
          </div>
          <div className="p-2 mt-6 text-lg">
            <p>
              <span className="font-bold">Nombre d'articles vendu</span> :{" "}
              {note && note.length}
            </p>
            <p>
              <span className="font-bold">Avis</span> :
              {(
                note.reduce(
                  (accu, current) => current.note_attribuée + accu,
                  0
                ) / note.length
              ).toFixed(1)}
            </p>
          </div>
          <div className="p-2 mt-6 text-lg">
            <p className="text-2xl underline mb-6">
              Les autres annonces de{" "}
              <span className="font-bold">{user.firstname}</span> :
            </p>
            <div className="">
              {userAnnouncements.map((announcement) => (
                <div
                  className="relative border border-red-500 text-center"
                  id="store-card"
                >
                  <Link
                    to={`/annonces/id/${announcement.id}`}
                    key={announcement.id}
                  >
                    {" "}
                    <div className="flex gap-10 p-5 items-center">
                      <div
                        className="relative "
                        style={{ width: 191, height: 200 }}
                      >
                        <Slider {...settings}>
                          {announcement.images !== null &&
                            announcement.images
                              .slice(1, -1)
                              .split(",")
                              .map((image, index) => (
                                <div
                                  key={index}
                                  className="flex justify-center "
                                >
                                  <img
                                    className="object-cover relative"
                                    style={{ height: 191, width: 200 }}
                                    //  src={API_REMOTE + "/" + image}
                                    alt=""
                                  />
                                  <FontAwesomeIcon
                                    icon={faHeart}
                                    className="text-black p-3 ml-2 rounded-full bg-white cursor-pointer absolute top-2 right-2"
                                  />
                                </div>
                              ))}
                        </Slider>
                      </div>
                      <div id="store-info" className="text-left">
                        <p className="pt-4 text-3xl font-semibold">
                          {truncateName(announcement.name)}
                        </p>
                        <p className="pt-2 font-semibold ">{announcement.categorie}</p>
                        <p className="font-bold text-xl pt-4">
                          {new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          }).format(announcement.prix / 100)}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
