import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { API_REMOTE } from "../../../routes";
import "../../../css/panier/panier.scss";
import User from "../../../requests/currentUser/User";
import Seo from "../../../components/Seo";

const Favoris = () => {
  const navigate = useNavigate();
  const [favoriteAnnouncements, setFavoriteAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  // Récupération de l'utilisateur courant
  const userObj = User();

  const getNumberFavorites = async (annonceId) => {
    try {
      const jwtCookie = Cookies.get("jwt");
      const response = await axios.get(
        `${API_REMOTE}/favoris/annonce/${annonceId}`,
        {
          headers: {
            authorization: `${jwtCookie}`,
          },
        }
      );
      return response.data.length;
    } catch (error) {
      console.error(
        "Erreur lors de la récupération du nombre de favoris :",
        error
      );
      return 0; // En cas d'erreur, retournez 0
    }
  };

  const fetchFavoriteAnnonces = async () => {
    try {
      const jwtCookie = Cookies.get("jwt");

      // Récupérez les annonce_id des favoris de l'utilisateur
      const favorisResponse = await axios.get(
        `${API_REMOTE}/favoris/${userObj && userObj.user.id}`,
        {
          headers: {
            authorization: `${jwtCookie}`,
          },
        }
      );
      const favoris = favorisResponse.data;

      // Fetch toutes les annonces correspondantes
      const annoncesAvecFavoris = await Promise.all(
        favoris.map(async (favori) => {
          // Fetch l'annonce correspondante
          const annonceResponse = await axios.get(
            `${API_REMOTE}/annonces/id/${favori.annonce_id}`
          );
          const annonce = annonceResponse.data;

          // Utilisez getNumberFavorites pour obtenir le nombre de favoris
          const numberFavorite = await getNumberFavorites(favori.annonce_id);

          return {
            ...annonce,
            isFavorite: true, // Marquez ces annonces comme des favoris
            numberFavorite: numberFavorite, // Stockez le nombre de favoris
          };
        })
      );

      // Stockez les annonces dans votre état ou variable
      setFavoriteAnnouncements(annoncesAvecFavoris);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFavoriteAnnonces();
  }, [userObj]);

  const toggleFavorite = async (annonceId) => {
    try {
      const jwtCookie = Cookies.get("jwt");

      const confirmed = window.confirm(
        "Êtes-vous sûr de vouloir retirer cet article des favoris ?"
      );
      if (!confirmed) {
        return;
      }

      if (isAnnouncementInFavorites(annonceId)) {
        const resp = await axios.get(
          `${API_REMOTE}/favoris/${userObj && userObj.user.id}/${annonceId}`,
          {
            headers: {
              authorization: jwtCookie,
            },
          }
        );
        await axios.delete(`${API_REMOTE}/favoris/${resp.data.id}`, {
          headers: {
            authorization: jwtCookie,
          },
        });
        console.log(favoriteAnnouncements);
        setFavoriteAnnouncements((prevFavorites) =>
          prevFavorites.filter((fav) => fav.id !== annonceId)
        );
      }
    } catch (error) {
      console.error("Erreur lors de la gestion des favoris :", error);
    }
  };

  const isAnnouncementInFavorites = (annonceId) =>
    favoriteAnnouncements.some((fav) => fav.id === annonceId);

  return (
    <section style={{ backgroundImage: `url('/market.jpg')` }}>
      <Seo title={"Ma collection de jeux rétros, next gen, figurines,objects de collections"}
      description={"Retrouvez instantanément vos jeux rétros , next gen, figurines, cartes de collections préférés sans avoir à chercher."}/>
      {loading ? (
        <p>Chargement en cours...</p>
      ) : userObj ? (
        <div id="profil-contain">
          <h2 className="text-3xl underline font-semibold">VOS FAVORIS : </h2>
          {favoriteAnnouncements.length > 0 ? (
            <ul className="">
              {favoriteAnnouncements.map((fav) => (
                <li
                  id="fav-card"
                  className="border-2 border-purple-600 p-4 my-6"
                  key={fav.id}
                >
                  <div className="annonce-card">
                    <div className="annonce-images">
                      <img src={`${API_REMOTE} / ${fav.image}`} alt="" />
                    </div>
                    <div className="flex">
                      <div className="annonce-details w-2/3">
                        <h1 className="text-3xl font-bold">{fav.name}</h1>
                        <div className="font-semibold">
                          <p className="text-xl pl-2 capitalize">
                            {fav.description}
                          </p>
                          <p className="pt-4">
                            <span className="text-purple-600">#</span>{" "}
                            {fav.categorie}
                          </p>
                          {fav.sous_categorie && (
                            <p>
                              <span className="text-purple-600">#</span>{" "}
                              {fav.sous_categorie}
                            </p>
                          )}
                          {fav.sous_sous_categorie && (
                            <p>
                              <span className="text-purple-600">#</span>{" "}
                              {fav.sous_sous_categorie}
                            </p>
                          )}
                          {fav.etat && (
                            <p>
                              <span className="text-purple-600">#</span>{" "}
                              {fav.etat}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="annonce-actions flex flex-col justify-center gap-4">
                        <span className="text-xl font-semibold text-center">
                          {new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          }).format(fav.prix / 100)}
                        </span>
                        <button
                          className="p-2 retirer"
                          onClick={() => toggleFavorite(fav.id)}
                        >
                          {isAnnouncementInFavorites(fav.id)
                            ? "Retirer des favoris"
                            : "Ajouter aux favoris"}
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>Aucune annonce en favori.</p>
          )}
        </div>
      ) : (
        <p>Aucun utilisateur connecté.</p>
      )}
    </section>
  );
};

export default Favoris;
