import { Link, useNavigate } from "react-router-dom";
import { PAIEMENT } from "../../routes";
import User from "../../requests/currentUser/User";

const Credit = () => {
  // Récupération de l'utilisateur courant
  const userObj = User();

  const formatAmount = (amountInCents) => {
    const amountInEuros = amountInCents / 100; // Convertir le montant en euros
    return amountInEuros.toLocaleString("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2, // Afficher deux décimales
      maximumFractionDigits: 2, // Afficher deux décimales
    });
  };

  return (
    <section>
      <div
        id="credi-contain"
        style={{ backgroundImage: `url('/market.jpg')` }}
        className="p-6"
      >
        <div className="max-w-screen-xl mx-auto">
          <h1 className="text-3xl	font-bold underline mb-4">Crédits :</h1>
          <h2 className="text-xl mb-4">
            VOTRE SOLDE : {userObj && formatAmount(userObj.user.solde)}
          </h2>
          <Link className="see-more" to={PAIEMENT}>
            CREDITER
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Credit;
