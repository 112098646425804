import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_REMOTE } from "../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHouse,
  faPhoneFlip,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import User from "../../requests/currentUser/User";

export default function AdminUpdate() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // Récupération de l'utilisateur courant
  const userObj = User()

  const updateUser = async (data) => {
    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.put(`${API_REMOTE}/users/${id}`, data, {
        headers: {
          authorization: `${jwtCookie}`,
        },
      });
      navigate("/admin");
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get(`${API_REMOTE}/users/${id}`);
        const user = response.data;
        setValue("lastname", user.lastname);
        setValue("firstname", user.firstname);
        setValue("email", user.email);
        setValue("number", user.number);
        setValue("address", user.address);
      } catch (e) {
        console.log(e.message);
      }
    };
    getUser();
  }, [id, setValue]);

  if (userObj && userObj.user.roles.includes('admin')){

    return (
      <section>
        <div
          id="subscribe-contain"
          className=""
          style={{ backgroundImage: `url("/namek.jpg")` }}
        >
          <form
            class="subscribe-form border"
            onSubmit={handleSubmit(updateUser)}
          >
            <div id="subscribe-flex">
              <h1>INSCRIPTION</h1>
              <div className="subscribe-name space-x-5">
                <div className="subscribe-name-container">
                  <input
                    type="text"
                    id="nom"
                    name="lastname"
                    required
                    placeholder="Nom"
                    {...register("lastname", { required: true })}
                    
                  />
                  <FontAwesomeIcon icon={faUser} className="text-black" />
                </div>
                <div className="subscribe-name-container">
                  <input
                    type="text"
                    id="prenom"
                    name="firstname"
                    required
                    placeholder="Prénom"
                    {...register("firstname", { required: true })}
                    
                  />
                  <FontAwesomeIcon icon={faUser} className="text-black" />
                </div>
              </div>
              <div className="subscribe-info">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="E-mail"
                  {...register("email", { required: true })}
                 
                />
                <FontAwesomeIcon icon={faEnvelope} className="text-black" />
              </div>
              <div className="subscribe-info">
                <input
                  type="number"
                  id="numero"
                  name="number"
                  required
                  placeholder="Numéro de téléphone"
                  {...register("number", { required: true })}
                 
                />
                <FontAwesomeIcon icon={faPhoneFlip} className="text-black" />
              </div>
              <div className="subscribe-info">
                <input
                  type="text"
                  id="adresse"
                  name="address"
                  required
                  placeholder="Adresse"
                  {...register("address", { required: true })}
                  
                />
                <FontAwesomeIcon icon={faHouse} className="text-black" />
              </div>
              {/* <div>
              <select
                      name="roles"
                      value={roles}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ${
                        roles && "mr-2"
                      }`}
                      onChange={isCategorie}
                    >
                      <option value="">Rôle</option>
                      {roles.map((role) => {
                        return <option value={role}>{role}</option>;
                      })}
                    </select>
              </div> */}
              <div id="subscribe-btn-container" className="mt-4">
                <button id="subscribe-btn" type="submit">
                  MODIFIER
                </button>
              </div>
            </div>
          </form>
          {/* {errorMessage && (
            <p className="text-red-500 my-4 max-w-[10rem] text-center">
              {errorMessage.message}
            </p>
          )} */}
        </div>
      </section>
    );
  }

  return (
    <div className="w-full flex justify-center">
      <iframe src="https://giphy.com/embed/bKj0qEKTVBdF2o5Dgn" width="480" height="352" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    </div>
  )
}