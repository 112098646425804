import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Home from "../src/pages/Home";
import Footer from "./components/Footer";
import LogIn from "./pages/LogIn";
import HeaderGeneral from "./components/HeaderGeneral";
import Register from "./pages/Register";
import Profile from "./pages/Profil/Sub-profil/Profil";
import Annonce from "./pages/Annonce";
import Store from "./pages/Store";
import {
  ADMIN,
  ADMINACTUS,
  ADMINCREATEACTUS,
  ADMINVERIFANNOUNCE,
  ADMINVERIFUSER,
  CONTACT,
  CREDIT,
  FAVORIS,
  MESSAGES,
  PAIEMENT,
  PANIER,
  PROFIL,
} from "./routes";
import Payment from "./pages/Profil/Payment";
import Credit from "./pages/Profil/Credit";
import PanierPage from "./pages/PanierPage";
import Favoris from "./pages/Profil/Sub-profil/Favoris";
import GetAnnonce from "./pages/GetAnnonce";
import Vitrine from "./pages/Vitrine";
import AdminRead from "./components/Admin/AdminRead";
import AdminCreate from "./components/Admin/AdminCreate";
import Contact from "./pages/Contact";
import AdminUpdate from "./components/Admin/AdminUpdate";
import MaVitrine from "./pages/MaVitrine";
import UpdateProfile from "./pages/UpdateProfile";
import Note from "./components/note";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import UpdateAnnonce from "./pages/UpdateAnnonce";
import Messages from "./components/Profil/Messages";
import MentionsLegales from "./pages/Politiques/MentionsLegales";
import AdminVerifUser from "./components/Admin/AdminVerifUser";
import AdminVerifAnnouncement from "./components/Admin/AdminVerifAnnounce";
import PageActu from "./components/Actualité/Pages/ActualiteAccueil";
import AdminActus from "./components/Admin/Actus/AdminActus";
import AdminCreateActu from "./components/Admin/Actus/AdminCreateActu";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <HeaderGeneral />
        <Routes>
          <Route index path={"/"} element={<Home />} />
          <Route path={PROFIL} element={<Profile />} />
          <Route path={FAVORIS} element={<Favoris />} />
          <Route path={"/login"} element={<LogIn />} />
          <Route path={"/register"} element={<Register />}></Route>
          <Route path={"/forgot-password"} element={<ForgotPassword />}></Route>
          <Route
            path={"/reset-password/:resetToken"}
            element={<ResetPassword />}
          />
          <Route path={"/annonce"} element={<Annonce />}></Route>
          <Route path={"/store"} element={<Store />}></Route>
          <Route path={ADMIN} element={<AdminRead />}></Route>
          <Route path={ADMINVERIFUSER} element={<AdminVerifUser />}></Route>
          <Route
            path={ADMINVERIFANNOUNCE}
            element={<AdminVerifAnnouncement />}
          ></Route>
          <Route path={ADMINACTUS} element={<AdminActus />}></Route>
          <Route path={ADMINCREATEACTUS} element={<AdminCreateActu />}></Route>
          <Route path={"/admin_create"} element={<AdminCreate />}></Route>
          <Route path={"/admin_update/:id"} element={<AdminUpdate />}></Route>
          <Route path={"/panier/note"} element={<Note />}></Route>
          <Route
            path={"/update_annonce/:announcementId"}
            element={<UpdateAnnonce />}
          ></Route>
          <Route
            path={"/mentions-legales"}
            element={<MentionsLegales />}
          ></Route>
          <Route
            path={"/annonces/id/:annonceId"}
            element={<GetAnnonce />}
          ></Route>
          <Route path={"/users/:userId"} element={<Vitrine />} />
          <Route path={"/update_profile/:userId"} element={<UpdateProfile />} />
          <Route path={"/ma_vitrine/:userId"} element={<MaVitrine />}></Route>
          <Route path={PAIEMENT} element={<Payment />}></Route>
          <Route path={CREDIT} element={<Credit />}></Route>
          <Route path={PANIER} element={<PanierPage />}></Route>
          <Route path={CONTACT} element={<Contact />}></Route>
          <Route path={MESSAGES} element={<Messages />}></Route>
          <Route path={"/actualite"} element={<PageActu />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
  );
}
export default App;
