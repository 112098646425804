// FilterSortUsers.js
import React from "react";
import { Link } from "react-router-dom";
import DeleteUser from "../users/DeleteUser";

const FilterSortUsers = ({ users, searchKeyword, sortConfig }) => {
  const filteredUsers = users.filter((user) => {
    const fullName = `${user.firstname} ${user.lastname}`.toLowerCase();
    const email = user.email.toLowerCase();
    const roles = user.roles.toLowerCase();
    return (
      fullName.includes(searchKeyword.toLowerCase()) ||
      email.includes(searchKeyword.toLowerCase()) ||
      roles.includes(searchKeyword.toLowerCase())
    );
  });

  const sortedUsers = filteredUsers.sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  return (
    <tbody>
      {sortedUsers.map((user) => (
        <tr key={user.id} className="odd:bg-purple-200 even:bg-purple-300">
          <td>{user.firstname}</td>
          <td>{user.lastname}</td>
          <td>{user.email}</td>
          <td>{user.roles}</td>
          <td>
            <button
              onClick={() => DeleteUser(user.id)}
              className="my-1 p-2 bg-red-500 rounded-md"
            >
              Bannir l'utilisateur
            </button>
            <Link
              to={`/admin_update/${user.id}`}
              className="border p-2 bg-gray-400 rounded-md ml-4"
            >
              Modifier l'utilisateur
            </Link>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default FilterSortUsers;
