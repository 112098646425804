import axios from "axios";
import Cookies from "js-cookie";
import { API_REMOTE } from "../../routes";

const DeleteUser = async (userId) => {
  const confirmed = window.confirm(
    "Êtes-vous sûr de vouloir bannir cet utilisateur ?"
  );
  if (!confirmed) {
    return;
  }
  try {
    const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
    await axios.delete(`${API_REMOTE}/users/${userId}`, {
      headers: {
        authorization: `${jwtCookie}`,
      },
    });
    // const updatedUsers = users.filter((user) => user.id !== userId);
    // setUsers(updatedUsers);
    // console.log(updatedUsers)
  } catch (error) {
    console.error("Erreur lors de la suppression de l'utilisateur :", error);
  }

  return null;
};

export default DeleteUser;
