import { useEffect, useState } from "react";
import User from "../../../requests/currentUser/User";
import HeaderAdmin from "../HeaderAdmin";
import ImageModal from "../../Profil/ImageModal";
import Cookies from "js-cookie";
import axios from "axios";
import { ADMINCREATEACTUS, API_REMOTE } from "../../../routes";
import { Link, useNavigate } from "react-router-dom";

const AdminActus = () => {
  const [actus, setActus] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [selectedActu, setSelectedActu] = useState("");
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [verifenplus, setVerifenplus] = useState(0);
  const navigate = useNavigate();

  // Récupération de l'utilisateur courant
  const userObj = User();

  const handleImageModal = (actuId) => {
    setImageModalOpen(true);
    setSelectedActu(actuId);
    console.log(actuId);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  useEffect(() => {
    const getActus = async () => {
      try {
        const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
        const response = await axios.get(`${API_REMOTE}/actus`, {
          headers: {
            authorization: `${jwtCookie}`,
          },
        });

        if (response && response.data) {
          // Récupération des annonces
          const fetchedActus = response.data;

          // Mettre à jour l'état des annonces avec les utilisateurs associés
          setActus(fetchedActus);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getActus();
  }, [verifenplus]);

  const deleteActu = async (actuId) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir bannir cet utilisateur ?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.delete(`${API_REMOTE}/actus/${actuId}`, {
        headers: {
          authorization: `${jwtCookie}`,
        },
      });
      const updatedActus = actus.filter((actu) => actu.id !== actuId);
      setActus(updatedActus);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur :", error);
    }
  };

  // Fonction pour trier les utilisateurs en fonction de la clé et de la direction de tri
  const sortActus = (actus, key, direction) => {
    return [...actus].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  // Fonction pour gérer le clic sur les boutons de tri
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Appliquer le tri et le filtre aux utilisateurs
  const sortedActus = sortActus(actus, sortConfig.key, sortConfig.direction);

  // Fonction pour obtenir la classe CSS du bouton de tri actif
  const getSortButtonClass = (key) => {
    if (sortConfig.key === key) {
      return `focus:outline-none ${
        sortConfig.direction === "asc" ? "text-color-one" : "text-color-two"
      }`;
    }
    return "focus:outline-none";
  };

  // Fonction pour obtenir la classe CSS de la flèche de tri
  const getSortArrowClass = (key) => {
    if (sortConfig.key === key) {
      return `inline-block ml-1 ${
        sortConfig.direction === "asc" ? "transform rotate-180" : ""
      }`;
    }
    return "inline-block ml-1 hidden";
  };

  if (userObj && userObj.user.roles.includes("admin")) {
    return (
      <section className="flex h-full">
        <HeaderAdmin />
        <div
          className="flex flex-col items-center p-6 w-full"
          style={{ backgroundImage: `url('/market.jpg')` }}
        >
          <div className="flex flex-col w-full">
            <div className="flex justify-between">
              <h1 className="text-3xl underline">
                Liste des actualités ({actus.length}) :
              </h1>
              <button
                className="w-1/5 border p-2 bg-purple-800 text-white rounded-md ml-4"
                onClick={() => navigate(ADMINCREATEACTUS)}
              >
                Créer une publication
              </button>
            </div>
            <div className="w-1/3">{/* Boutons filtre roles */}</div>
            <div className="w-full overflow-auto">
              <table className="md:w-full">
                <thead>
                  <tr>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("title")}
                        onClick={() => handleSort("title")}
                      >
                        Titre
                        <span className={getSortArrowClass("title")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1">
                      <button
                        className={getSortButtonClass("description")}
                        onClick={() => handleSort("description")}
                      >
                        Description
                        <span className={getSortArrowClass("description")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 3a1 1 0 011 1v10a1 1 0 01-2 0V4a1 1 0 011-1zm4.293 9.707a1 1 0 01-1.414 1.414L10 10.414l-2.879 2.879a1 1 0 11-1.414-1.414l4-4a1 1 0 011.414 0l4 4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      </button>
                    </th>
                    <th className="border-2 p-1 ">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedActus.map((actu) => {
                    return (
                      <tr
                        className="odd:bg-purple-200 even:bg-purple-300"
                        key={actu.id}
                      >
                        <td className="border p-1">{actu.title}</td>
                        <td className="border p-1 max-w-[200px]">
                          <div className="max-h-14 overflow-hidden overflow-ellipsis relative">
                            {actu.description}
                            {actu.description.length > 60 && (
                              <span className="absolute right-0 bottom-0 text-2xl text-gray-500">
                                ...
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <button
                            onClick={() => deleteActu(actu.id)}
                            className="border p-2 bg-red-500 rounded-md ml-4"
                          >
                            Supprimer la publication
                          </button>
                          <button
                            className="border p-2 bg-green-400 rounded-md ml-4"
                            // onClick={() => handleAccept(actu.id)}
                          >
                            Modifier la publication
                          </button>
                          <button
                            className="border p-2 bg-purple-800 text-white rounded-md ml-4"
                            onClick={() => handleImageModal(actu)}
                          >
                            Afficher la publication
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {isImageModalOpen && (
          <ImageModal
            imageUrl={API_REMOTE + "/" + selectedActu.image}
            closeModal={closeImageModal}
          />
        )}
      </section>
    );
  }
  return (
    <div className="w-full flex justify-center">
      <iframe
        src="https://giphy.com/embed/bKj0qEKTVBdF2o5Dgn"
        width="480"
        height="352"
        frameBorder="0"
        className="giphy-embed"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default AdminActus;
