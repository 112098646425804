import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import Cookies from "js-cookie";
import { API_REMOTE } from "../../routes";
import UserList from "../Profil/UserList";
import moment from "moment/moment";
import User from "../../requests/currentUser/User";

const socket = io.connect(API_REMOTE, {
  transports: ["websocket"],
  withCredentials: true,
});

const Messages = ({ selectedUserDefault }) => {
  const jwtToken = Cookies.get("jwt") && Cookies.get("jwt");
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const chatContainerRef = useRef(null);
  const inputRef = useRef(10);
  const [isResizing, setIsResizing] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [unreadMessageCount, setUnreadMessageCount] = useState({});
  const [chatIsOpen, setChatIsOpen] = useState(false);

  // Récupération de l'utilisateur courant
  const userObj = User();

  const handleUserModalClick = (user) => {
    // markMessageAsRead()
    setSelectedUser(user);
    setChatIsOpen(true);
  };

  useEffect(() => {
    if (selectedUserDefault) {
      setSelectedUser(selectedUserDefault);
    }
  }, []);

  useEffect(() => {
    // Après chaque mise à jour des messages, défilez automatiquement vers le bas
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    // adjustInputHeight(); // Appel de la fonction pour ajuster la hauteur de l'input
  };

  useEffect(() => {
    if (userObj && userObj.user.id) {
      // Si userObj existe et userObj.user.id est défini, alors appelez socket.emit("joinRoom")
      socket.emit("joinRoom", userObj.user.id);
    }

    // Nettoyez l'effet lors du démontage du composant
    return () => {
      if (userObj && userObj.user.id) {
        // Si userObj existe et userObj.user.id est défini, alors appelez socket.emit("leaveRoom")
        socket.emit("leaveRoom", userObj.user.id);
      }
    };
  }, [userObj]);

  useEffect(() => {
    if (selectedUser && selectedUser) {
      fetchMessages();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (userObj && userObj.user.id) {
      // Si userObj existe et userObj.user.id est défini, alors appelez socket.emit("joinRoom")
      socket.emit("joinRoom", userObj.user.id);
      // adjustInputHeight();
    }

    // Nettoyez l'effet lors du démontage du composant
    return () => {
      if (userObj && userObj.user.id) {
        // Si userObj existe et userObj.user.id est défini, alors appelez socket.emit("leaveRoom")
        socket.emit("leaveRoom", userObj.user.id);
      }
    };
  }, [selectedUser, userObj]);

  useEffect(() => {
    if (userObj) {
      fetchMessages();
    }

    // Créer une fonction de rappel séparée pour gérer les nouveaux messages
    const handleNewMessage = (message) => {
      if (message.sender_id === selectedUser.id) {
        setMessages((prevMessages) => [...prevMessages, message]);

        socket.on("updateUnreadCount", (count) => {
          setUnreadMessageCount((prevCount) => ({
            ...prevCount,
            [selectedUser.id]: count,
          }));
        });
      }
    };

    // Ajouter l'écouteur d'événement "newMessage" avec la fonction de rappel créée ci-dessus
    socket.on("newMessage", handleNewMessage);

    // Nettoyer l'effet lors du démontage du composant
    return () => {
      // Supprimer l'écouteur d'événement "newMessage" en utilisant la même fonction de rappel
      socket.off("newMessage", handleNewMessage);
    };
  }, [selectedUser, userObj]);

  const fetchMessages = async () => {
    try {
      if ((selectedUser && selectedUser.id) !== (userObj && userObj.user.id)) {
        const response = await axios.get(
          `${API_REMOTE}/messages/${selectedUser.id}/${userObj?.user.id}`, // Utilisez userObj?.id pour éviter les erreurs lorsque userObj est null ou undefined
          {
            headers: {
              Authorization: `${jwtToken}`,
            },
          }
        );
        setMessages(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const markMessageAsRead = async (messageId) => {
    try {
      // Appel à l'API pour marquer le message comme lu
      await axios.put(`${API_REMOTE}/messages/markAsRead/${messageId}`, null, {
        headers: {
          Authorization: jwtToken,
        },
      });

      // Mettre à jour l'état des messages (remarquer que le message a été lu)
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === messageId ? { ...message, is_read: true } : message
        )
      );
    } catch (error) {
      console.error("Erreur lors du marquage du message comme lu :", error);
    }
  };

  // function getISOStringWithLocalTime() {
  //     const now = new Date();
  //     const year = now.getFullYear();
  //     const month = String(now.getMonth() + 1).padStart(2, '0');
  //     const day = String(now.getDate()).padStart(2, '0');
  //     const hours = String(now.getHours()).padStart(2, '0');
  //     const minutes = String(now.getMinutes()).padStart(2, '0');
  //     const seconds = String(now.getSeconds()).padStart(2, '0');
  //     const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
  //     const offset = -now.getTimezoneOffset();
  //     const offsetHours = String(Math.floor(offset / 60)).padStart(2, '0');
  //     const offsetMinutes = String(offset % 60).padStart(2, '0');
  //     const offsetSign = offset < 0 ? '+' : '-';

  //     const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;

  //     return formattedDate;
  // }

  const sendMessage = async (receiverId, messageContent) => {
    try {
      // const response = await axios.post(
      //   `${API_REMOTE}/messages`,
      //   {
      //     receiver_id: receiverId,
      //     content: messageContent,
      //   },
      //   {
      //     headers: {
      //       Authorization: `${jwtToken}`,
      //     },
      //   }
      // );

      const newMessage = {
        sender_id: userObj.user.id,
        receiver_id: receiverId,
        content: messageContent,
        timestamp: moment().format(),
      };
      // Émettre l'événement "sendMessage" pour envoyer le message au serveur
      if (newMessage.content.trim() !== "") {
        socket.emit("sendMessage", newMessage);
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setMessage("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Vérifier si la touche pressée est "Enter"
    if (event.key === "Enter" && !event.shiftKey) {
      sendMessage(selectedUser.id, message);
      setMessage("");
    }
  };

  return (
    <>
      <style>
        {`

                    /* Personnaliser la scrollbar pour les navigateurs WebKit (Chrome, Safari) */

                    .scrollbar ::-webkit-scrollbar {
                        width: 0px;
                    }

                    .scrollbar ::-webkit-scrollbar-thumb {
                        background-color: rgba(0, 0, 0, 0.3);
                        border-radius: 5px;
                    }

                    .scrollbar ::-webkit-scrollbar-thumb:hover {
                        background-color: rgba(0, 0, 0, 0.5);
                    }

                    /* Personnaliser la scrollbar pour Firefox */
                    .scrollbar scrollbar-width: thin;
                    .scrollbar scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
                `}
      </style>
      <div className="relative">
        <div className="w-full flex items-center flex-col border border-r-2">
          <h1 className="text-2xl">Vos messages</h1>
          <div
            className="eachuser mb-4 w-full"
            style={{
              overflowY: "scroll",
            }}
          >
            <UserList
              currentUser={handleUserModalClick}
              direction={"message"}
            />
          </div>
        </div>
        {chatIsOpen && (
          <div className="absolute top-0 left-0 h-screen flex flex-col w-full p-6 bg-white">
            {(selectedUser && selectedUser.id) !==
              (userObj && userObj.user.id) && (
              <>
                <div className="relative flex items-center justify-between mb-3">
                  <div className="flex items-center">
                    {selectedUser.is_connected && (
                      <span class="relative right-0 mr-1 mt-1 flex h-3 w-3">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                      </span>
                    )}
                    <h2 className="text-lg font-semibold text-black">
                      {selectedUser.firstname} {selectedUser.lastname}
                    </h2>
                  </div>
                  <button
                    className="text-gray-500 hover:text-gray-800"
                    onClick={() => setChatIsOpen(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.293 3.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  ref={chatContainerRef}
                  className="rounded px-2 w-full h-full"
                  style={{ overflowY: "scroll" }}
                >
                  {messages.map((message, i) => {
                    const timestamp = message.timestamp; // Récupérez le timestamp du message
                    const date = new Date(timestamp);
                    // console.log(message.content, timestamp);
                    const localTimestamp = new Date(
                      date.getTime() - date.getTimezoneOffset() * 60000
                    );
                    // console.log(message.content, date);

                    return (
                      <div
                        key={i}
                        className={`relative my-2 mb-4 ${
                          message.sender_id === userObj.user.id
                            ? "flex justify-end ml-16"
                            : "flex justify-start mr-16"
                        }`}
                      >
                        <span
                          className={`text-white p-2 pb-3 rounded-t-lg ${
                            message.sender_id === userObj.user.id
                              ? "rounded-l-lg"
                              : "rounded-r-lg"
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: message.content.replace(/\n/g, "<br>"),
                          }}
                          style={{
                            minWidth: "3em",
                            backgroundColor: `${
                              message.sender_id === userObj.user.id
                                ? "#6D308E"
                                : "#5E5E5E"
                            }`,
                          }}
                        />
                        <span
                          className={`absolute bottom-0 text-xs text-gray-300 ${
                            message.sender_id === userObj.user.id
                              ? "right-1"
                              : "left-1"
                          }`}
                        >
                          {date.toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <form>
                  <textarea
                    ref={inputRef}
                    value={message}
                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault(); // Empêche le saut de ligne
                        sendMessage(selectedUser.id, message);
                      }
                    }}
                    placeholder="Écrire un message..."
                    className="border border-gray-300 p-2 w-full mt-2 resize-none overflow-hidden"
                  />
                  <button
                    type="button"
                    onClick={() => sendMessage(selectedUser.id, message)}
                    className="bg-[#6D308E] text-white py-2 px-4 mt-2 rounded-lg hover:bg-blue-600"
                  >
                    Envoyer
                  </button>
                </form>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Messages;
