import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import CookieCon from "./components/CookieCon";

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("/service-worker.js")
//     .then((registration) => {
//       console.log("Service Worker registered with scope:", registration.scope);
//     })
//     .catch((error) => {
//       console.error("Error registering Service Worker:", error);
//     });
// }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="116231442048-sjsfjn9i6cfblaetkvm0jrs74llu0l7s.apps.googleusercontent.com">
      <CookieCon />
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

reportWebVitals();
