const AccessDenied = () => {
  return (
    <iframe
      src="https://giphy.com/embed/bKj0qEKTVBdF2o5Dgn"
      width="480"
      height="352"
      frameBorder="0"
      className="giphy-embed"
      allowFullScreen
    ></iframe>
  );
};

export default AccessDenied;
