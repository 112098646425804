import React, { useEffect, useState } from "react";
import Panier from "../components/Payment/Panier";
import Store from "./Store";
import { API_REMOTE } from "../routes";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import User from "../requests/currentUser/User";

const PanierPage = () => {
  // Accéder aux fonctions et états déclarés dans le composant Store
  const { articles } = Store();
  const [panier, setPanier] = useState([]);
  const navigate = useNavigate();

  // Récupération de l'utilisateur courant
  const userObj = User();

  useEffect(() => {
    // Fonction pour récupérer le panier de l'utilisateur connecté
    const fetchPanier = async () => {
      try {
        // Récupérer le JWT du cookie
        const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
        const userId = userObj && userObj.user.id;

        if (userId) {
          const response = await axios.get(`${API_REMOTE}/panier/${userId}`, {
            headers: {
              Authorization: `${jwtCookie}`,
            },
          });

          setPanier(response.data);
        }
      } catch (error) {
        console.error(error); // Gérez l'erreur ici si quelque chose ne va pas
      }
    };

    fetchPanier();
  }, [articles, userObj, navigate]); // Ajoutez articles comme dépendance ici

  return (
    <div>
      
      <Panier articles={panier} />
    </div>
  );
};

export default PanierPage;
