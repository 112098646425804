import { Link } from "react-router-dom";
import "../css/nav/header.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHeart, faUser } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ADMIN, FAVORIS, MESSAGES } from "../routes";
import User from "../requests/currentUser/User";
import { GrUserAdmin } from "react-icons/gr";

export default function Header() {
  const [toggle_burger, setToggleBurger] = useState("false");

  // Récupération de l'utilisateur courant
  const userObj = User();

  const toggleSubMenu = () => {
    setToggleBurger(!toggle_burger);
  };

  return (
    <header id="headerHome">
      <div id="contain" className="">
        <div id="flexable">
          <div id="nav-pic">
            <span id="burger-menu">
              <FontAwesomeIcon onClick={toggleSubMenu} icon={faBars} />
            </span>
            <Link to={"/"}>
              <img src="/logo.png" alt="" />
            </Link>
          </div>
          <div id="log-sub-menu" className="space-x-2">
            <Link to={FAVORIS} id="">
              <span>
                <FontAwesomeIcon
                  className="icon-sub-menu text-red-500  sub-menu-basket"
                  icon={faHeart}
                />
              </span>
            </Link>
            <Link to={"/login"}>
              <span>
                <FontAwesomeIcon className="icon-sub-menu" icon={faUser} />
              </span>
            </Link>
          </div>
          <ul>
            <li>
              <Link className="nav-link" to={"/about"}>
                A PROPOS
              </Link>
            </li>
            <li>
              <Link className="nav-link" to={"/store"}>
                MARKETPLACE
              </Link>
            </li>
            <li>
              <Link className="nav-link" to={"/actualite"}>
                ACTUALITÉS
              </Link>
            </li>
            <li>
              <Link className="nav-link" to={"/sell"}>
                GRADING
              </Link>
            </li>
          </ul>
          <div id="shop">
            <Link to={FAVORIS} id="basket">
              {/* <img className="basket-pic" src="/panier.png" alt="" /> */}
              <span>
                <FontAwesomeIcon
                  className="icon-sub-menu text-red-500  basket-pic text-2xl"
                  icon={faHeart}
                />
              </span>
              <span id="shopping-text">MES FAVORIS</span>
            </Link>
            {userObj && userObj ? (
              <Link to={"/profil"} id="login-link">
                MON ESPACE
              </Link>
            ) : (
              <Link to={"/login"} id="login-link">
                CONNEXION
              </Link>
            )}
            {userObj && userObj.user.roles.includes("admin") && (
              <Link to={userObj && userObj.user.roles.includes("admin") && ADMIN} className="bg-white rounded-full p-2 ml-4">
                  <GrUserAdmin size={30} />
              </Link>
            )}
          </div>
        </div>
        <div id="sub-menu" className={toggle_burger ? "isVisible" : ""}>
          <Link className="sub-menu-link">A PROPOS</Link>
          <Link to={"/store"} className="sub-menu-link">
            MARKETPLACE
          </Link>
          <Link className="sub-menu-link">ACTUALITÉS</Link>
          <Link className="sub-menu-link">GRADING</Link>
          <Link to={MESSAGES} className="sub-menu-link">
            MESSAGES
          </Link>
        </div>
      </div>
    </header>
  );
}
