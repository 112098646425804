import PaymentContainer from "../../components/Payment/PaymentContainer";

const Payment = () => {
  return (
    <>
      <PaymentContainer />
    </>
  );
};

export default Payment;
