import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import "../../css/profil/profil.scss";
import { API_REMOTE, CREDIT, FAVORIS, MESSAGES, PROFIL } from "../../routes";
import axios from "axios";
import ImageModal from "./ImageModal";
import ChatModal from "../Chat/ChatModal";
import BtnPW from "../asset/btn/BtnPW";
import BtnBN from "../asset/btn/BtnBW";
import User from "../../requests/currentUser/User";

const HeaderProfil = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [notif, setNotif] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Récupération de l'utilisateur courant
  let userObj = User();

  // Mettre l'image de profil à jour en temps réel
  if (profilePicture) {
    userObj = profilePicture;
  }

  const handleUserClick = () => {
    setSelectedUser(userObj);
    setNotif(false);
    setModalIsOpen(true);
  };

  const openImageModal = () => {
    setImageModalOpen(true);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModalIsOpen(false);
  };

  const formatAmount = (amountInCents) => {
    const amountInEuros = amountInCents / 100; // Convertir le montant en euros
    return amountInEuros.toLocaleString("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2, // Afficher deux décimales
      maximumFractionDigits: 2, // Afficher deux décimales
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleImageUpload = () => {
    const formData = new FormData();
    formData.append("image", selectedImage);
    const userId = userObj.user.id;

    axios
      .post(`${API_REMOTE}/upload/${userId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log(response.data);
        const updatedUserObj = {
          ...userObj,
          user: {
            ...userObj.user,
            image: response.data.imagePath,
          },
        };
        setProfilePicture(updatedUserObj);
        setSelectedImage(null);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleLogout = () => {
    Cookies.remove("jwt");
    window.location.href = "/login";
  };

  return (
    <>
      {userObj && (
        <div id="profil-info">
          <div id="avatar">
            <img
              className=""
              src={
                userObj.user.image
                  ? `${API_REMOTE}/${userObj.user.image}`
                  : "/joker.jpg"
              }
              alt="profil"
              onClick={openImageModal}
            />
            <input type="file" accept="image/*" onChange={handleImageChange} />
            <BtnPW
              id={"co"}
              action={handleImageUpload}
              contenu={"Mettre à jour l'image"}
            />
            <h2 className=" mt-10">
              {userObj.user.firstname} {userObj.user.lastname}
            </h2>
            <h4 className=""> {userObj.user.email}</h4>
            <Link className="mt-10" to={CREDIT} id="credit">
              CREDIT : {userObj && formatAmount(userObj.user.solde)}
            </Link>
            <BtnPW id={"co"} action={handleLogout} contenu={"ME DECONNECTER"} />
          </div>
          <div id="dashbord" className="mt-10">
            <Link to={FAVORIS}>
              <div className="mb hover:bg-purple-800 hover:text-white">
                MES FAVORIS
                <img src="/heart.png" alt="" />
              </div>
            </Link>
            <Link>
              <div className="mb hover:bg-purple-800 hover:text-white">
                MES ACHATS
                <img src="/box.png" alt="" />
              </div>
            </Link>
            <Link>
              <div className="mb hover:bg-purple-800 hover:text-white">
                MES VENTES
                <img src="/coins.png" alt="" />
              </div>
            </Link>
            <Link to={`/ma_vitrine/${userObj.user.id}`}>
              <div className="mb hover:bg-purple-800 hover:text-white">
                MA VITRINE
                <img src="/vitrine.png" alt="" />
              </div>
            </Link>
            <Link to={MESSAGES}>
              <div
                className="mb hover:bg-purple-800 hover:text-white cursor-pointer"
                // onClick={handleUserClick}
              >
                MES MESSAGES
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-12 h-12 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                  />
                </svg>
              </div>
            </Link>
            <Link to={`/update_profile/${userObj.user.id}`}>
              <div className="hover:bg-purple-800 hover:text-white">
                PARAMETRE DU COMPTE
                <img src="/param.png" alt="" />
              </div>
            </Link>
          </div>
          {/* Affichez la modal d'image si elle est ouverte */}
          {isImageModalOpen && (
            <ImageModal
              imageUrl={
                userObj.user.image
                  ? `${API_REMOTE}/${userObj.user.image}`
                  : "/joker.jpg"
              }
              closeModal={closeImageModal}
            />
          )}
          {modalIsOpen && (
            <ChatModal
              selectedUserDefault={userObj}
              closeModal={closeModal}
              modalIsOpen={true}
            />
          )}
        </div>
      )}
    </>
  );
};

export default HeaderProfil;
