import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { API_REMOTE } from "../../routes";

const Users = ({ setUsers }) => {

  useEffect(() => {
    const getUsers = async () => {
      try {
        const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
        const response = await axios.get(`${API_REMOTE}/users`, {
          headers: {
            authorization: `${jwtCookie}`,
          },
        });

        if (response && response.data) {
          setUsers(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUsers();
  }, [setUsers]);

  return null;
};

export default Users;
