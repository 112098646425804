import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_REMOTE } from "../../routes";
import axios from "axios";

const User = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Fonction pour récupérer l'utilisateur actuel
    const fetchCurrentUser = async () => {
      try {
        // Récupérer le JWT du cookie
        const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");

        const listExclude = ['/', '/mentions-legales']
        const isExcluded = listExclude.some(excludedPath => location.pathname.includes(excludedPath));
        console.log(location.pathname, isExcluded && !jwtCookie);

        // Vérifier si le JWT est présent
        if (!isExcluded && !jwtCookie) {
          // Si le JWT est manquant, rediriger vers la page d'accueil ou une autre page
          navigate("/login");
          
          return;
        }

        // Inclure le JWT dans les en-têtes de la requête pour récupérer les données de l'utilisateur actuel
        const response = await axios.get(`${API_REMOTE}/users/me`, {
          headers: {
            authorization: `${jwtCookie}`,
          },
        });

        // Mettre à jour l'état avec les données de l'utilisateur
        if (response && response.data) {
          setUser(response.data);
        }
      } catch (error) {
        // Gérer les erreurs
        console.error(error);
        setUser(null);
      }
    };

    // Appeler la fonction pour récupérer l'utilisateur actuel
    fetchCurrentUser();
  }, [navigate, location.pathname]);

  return user;
};

export default User;
