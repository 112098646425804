import axios from "axios";
import { useEffect, useState } from "react";
import { API_REMOTE } from "../routes";

const Contact = () => {

    const [to, setTo] = useState('');
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(to + ' - ' + subject)
    }, [to, subject])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${API_REMOTE}/email/send-email`, { title, text });
            alert('E-mail envoyé avec succès');
            // Réinitialiser les champs du formulaire
            setTo('');
            setSubject('');
            setText('');
        } catch (error) {
            alert('Erreur lors de l\'envoi de l\'e-mail');
            console.error(error);
        }
    };

    return (
        <>
            <div className="min-h-[500px]">
                <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-5">
                    <h2 className="text-2xl font-semibold">Formulaire de Contact</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="to" className="block text-sm font-medium text-gray-700">
                                Destinataire
                            </label>
                            <input
                                type="email"
                                id="to"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={to}
                                onChange={(e) => setTo(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                                Sujet
                            </label>
                            <input
                                type="text"
                                id="subject"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="text" className="block text-sm font-medium text-gray-700">
                                Contenu
                            </label>
                            <textarea
                                id="text"
                                rows="3"
                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                required
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                                Envoyer
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Contact;