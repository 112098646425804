import "../css/login/login.scss";

import useDocumentTitle from "../components/DocumentTitle";
//////////
import axios from "axios";
import { API_REMOTE } from "../routes";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Cookies from "js-cookie";
import LoginForm from "../components/LoginForm";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import Seo from "../components/Seo";

const socket = io.connect(API_REMOTE, {
  transports: ["websocket"],
  withCredentials: true,
});

//////
export default function LogIn() {
  const navigate = useNavigate();
  let { register, handleSubmit } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [canSend, setCanSend] = useState(true);

  let onSubmit = async (e) => {
    try {
      const response = await axios.post(`${API_REMOTE}/login`, e);

      if (response && response.data) {
        Cookies.set("jwt", response.data.token, {
          secure: true,
        });
        if (canSend) {
          socket.emit("user-connect", response.data.user.id);
          setCanSend(false);
          setTimeout(() => {
            setCanSend(true);
          }, 1000); // Limitez les émissions à une par seconde
        }
        navigate("/profil");
      } else {
        console.error("Réponse invalide ou sans données.");
      }
    } catch (error) {
      // console.log(error.response.data);
      setErrorMessage(error.response.data);
    }
  };

  return (
    <section>
      <Seo
        title={
          "Connectez-vous à l'Univers du Gaming, des figurines, et des cartes de collections"
        }
        description={
          "Bienvenue sur la porte d'entrée de votre expérience de gaming personnalisée ! La page de connexion de Retrogems est votre passeport vers un monde de découvertes et de possibilités infinies. Connectez-vous dès maintenant pour accéder à votre profil, explorer vos favoris, suivre vos commandes et plonger au cœur de la communauté gaming, des jeux rétros , next gen, des figurines et des cartes de collections"
        }
      />
      <div id="login-contain" style={{ backgroundImage: `url("/market.jpg")` }}>
        <LoginForm
          register={register}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
        />
        {errorMessage && (
          <p className="text-red-500 my-4 max-w-[10rem] text-center">
            {errorMessage.message}
          </p>
        )}
      </div>
    </section>
  );
}
