import React from "react";
import { Link } from "react-router-dom";
function BtnBW({id,contenu,action}){
    return(
        <div id={id} className="m-0 text-black bg-white hover:bg-black hover:text-white font-bold duration-300 uppercase ease-in-out px-10 py-4 flex justify-center w-fit m-auto">           
            <Link to={action}>{contenu}</Link>
        </div>
    )
}

export default BtnBW