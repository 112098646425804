import React from "react";
import '../filter/filterSearchbar.scss'

const FilterSearchBar = ({handleSearchChange, searchValue})=>{
    return(
      <div className="relative w-1/2 mb-2" id="filter-input">
      <input
        type="search"
        className="block rounded p-2.5 w-full dark:placeholder-gray-400 dark:text-black"
        id="searchbar"
        placeholder="Chercher des pépites ..."
        required
        value={searchValue}
        onChange={handleSearchChange}
      />
    </div>
    )}
        

export default FilterSearchBar
