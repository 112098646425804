import axios from "axios";
import { useEffect, useState } from "react";
import { API_REMOTE } from "../routes";
import { useParams } from "react-router";
// import "../css/vitrine/vitrine.scss";
import { Link } from "react-router-dom";

export default function MaVitrine() {
  const { userId } = useParams();
  const [userAnnouncements, setUserAnnouncements] = useState([]);
  useEffect(() => {
    const fetchUserListings = async () => {
      try {
        const response = await axios.get(
          `${API_REMOTE}/annonces/user_id/${userId}`
        );
        // console.log(userId);
        setUserAnnouncements(response.data);
        // console.log(userAnnouncements);
      } catch (error) {
        console.error(error);
        // console.log(userId);
      }
    };

    fetchUserListings();
  }, [userId]);

  const truncateName = (name) => {
    if (name.length > 25) {
      return name.substring(0, 25) + "...";
    }
    return name;
  };

  const handleDelete = async (announcementId) => {
    try {
      await axios.delete(`${API_REMOTE}/annonces/${announcementId}`);
      setUserAnnouncements((prevAnnouncements) =>
        prevAnnouncements.filter(
          (announcement) => announcement.id !== announcementId
        )
      );
    } catch (error) {
      console.error("Erreur lors de la suppression de l'annonce :", error);
    }
  };

  return (
    <section>
      <div
        id="vitrine-contain"
        style={{ backgroundImage: `url('/market.jpg')` }}
      >
        <h1 className="text-3xl underline mb-5">MES ANNONCES :</h1>
        <div className="">
          {userAnnouncements.map((announcement) => (
            <div
              className={`relative border-2 p-2 ${announcement.is_verified === 1 ? 'border-purple-700' : 'border-orange-300'} text-center flex my-4 gap-4`}
              id="store-card"
            >
              <div className="relative h-[250px] w-[250px] bg-white/50 overflow-hidden">
                {announcement.images !== null &&
                  announcement.images
                    .slice(1, -1)
                    .split(",")
                    .map((image, index) => (
                      <div key={index} className="flex justify-center">
                        <img
                          className="object-cover"
                          style={{ height: 250, width: 250 }}
                          src={API_REMOTE + "/" + image}
                          alt=""
                        />
                      </div>
                    ))}
              </div>
              <div id="store-info" className="text-left w-3/5">
                <p className="pt-4 text-3xl font-semibold">
                  {truncateName(announcement.name)}
                </p>
                <p className="pt-2">{announcement.categorie}</p>
                <p className="font-bold text-xl pt-4">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(announcement.prix / 100)}</p>
              </div>
              <div className="flex flex-col gap-3 justify-center">
                <button
                  className="p-2 bg-red-500 text-white"
                  onClick={() => handleDelete(announcement.id)}
                >
                  Supprimer l'annonce
                </button>
                <Link
                  className="p-2 text-white bg-gray-500"
                  to={`/update_annonce/${announcement.id}`}
                >
                  Modifier l'annonce
                </Link>
              </div>
              {announcement && announcement.is_verified === 0 && <span className="absolute top-0 right-0">Cette publication est en cours de vérification.</span>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
