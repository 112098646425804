import axios from "axios";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { API_REMOTE } from "../../../routes";
import User from "../../../requests/currentUser/User";
import HeaderAdmin from "../HeaderAdmin";

export default function AdminCreateActu() {
  let { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  // Récupération de l'utilisateur courant
  const userObj = User();

  let onSubmit = async (data) => {
    const formDataActu = new FormData();
    formDataActu.append("title", title);
    formDataActu.append("description", description);
    // formDataActu.append("image", image);

    const jwtToken = Cookies.get("jwt");

    try {
      const response = await axios.post(`${API_REMOTE}/actus`, formDataActu, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${jwtToken}`,
        },
      });
      const actuId = response.data.id;

      // Envoyer l'image
      const formDataImage = new FormData();
      formDataImage.append("image", image);

      const annonceImage = await axios.post(
        `${API_REMOTE}/upload/${actuId}/image`,
        formDataImage,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${jwtToken}`,
          },
        }
      );

      console.log(response.data);
      navigate("/admin/actus");

      if (response.data) {
        console.log(response.data);
        // Le reste de votre code pour traiter la réponse
      } else {
        console.log("La réponse de l'API est vide ou mal formatée.");
      }
    } catch (error) {
      console.log(
        "Une erreur s'est produite lors de la requête vers l'API :",
        error
      );
      setErrorMessage(
        "Une erreur s'est produite lors de la requête vers l'API."
      );
    }
  };
  if (userObj && userObj.user.roles.includes("admin")) {
    return (
      <section className="flex h-full">
        <HeaderAdmin />
        <div
          className="w-full flex justify-center p-12"
          id="admin-create-actu-contain"
          style={{ backgroundImage: `url("/market.jpg")` }}
        >
          <form
            className="w-1/2 p-4 bg-white rounded-xl flex justify-center"
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
          >
            <div className="w-3/5">
              <h1 className="text-3xl text-center my-8 font-bold">
                CRÉER UNE ACTUALITÉ
              </h1>
              <div className="flex flex-col w-full">
                <div className="">
                  <input
                    className="w-full border-2 border-black rounded-full p-2 my-2"
                    type="text"
                    id="title"
                    name="title"
                    required
                    placeholder="Titre de l'actualité..."
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="subscribe-info">
                  <textarea
                    className="w-full border-2 border-black rounded-3xl p-2 my-2"
                    style={{ height: "150px", resize: "none" }}
                    type="text"
                    id="description"
                    name="description"
                    required
                    placeholder="Description de l'actualité..."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="subscribe-info">
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
                <div id="" className="flex justify-center mt-4">
                  <button className="" id="publier" type="submit">
                    PUBLIER CETTE ACTUALITÉ
                  </button>
                </div>
              </div>
            </div>
          </form>
          {errorMessage && (
            <p className="text-red-500 my-4 max-w-[10rem] text-center">
              {errorMessage.message}
            </p>
          )}
        </div>
      </section>
    );
  }
}
