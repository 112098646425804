import "./ActualiteBody.scss";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { API_REMOTE } from "../../../routes";

const ActualiteBody = () => { 

  const [actualite, setActualite] = useState("");

  

  useEffect(() => {
    const fetchActu = async () => {
      try {
        const jwtCookie = Cookies.get("jwt");

        const response = await axios.get(`${API_REMOTE}/actus`, {
          headers: {
            Authorization: `${jwtCookie}`,
          },
        });
        if(response && response.data){
            console.log(response.data);
            setActualite(response.data)
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchActu();
  }, []);

  console.log(actualite);

  function truncateDescription(description, wordLimit) {
    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  }

  return (
    <section className="mainbod">
      <div className="bod">
        <div
          className="container"
          style={{ backgroundImage: `url("/market.jpg")` }}
        >
          <h1
            style={{
              textAlign: "center",
              marginTop: "30px",
              fontSize: "30px",
              fontWeight: "900",
              margin: "5px 0 25px 0",
            }}
          >
            Hello!! Toutes nos actualités et Mags du momment
          </h1>
          <div className="content">
            {actualite && actualite.map((elt, index) => (
              <div key={index} className="img-actu">
                <div className="imag">
                  <img src={API_REMOTE + '/' + elt.image} alt="" />
                </div>
                <div className="act">
                  <h1 style={{ fontWeight: "bold", fontSize: "20px" }}>
                    {elt.title}
                  </h1>
                  <p>{truncateDescription(elt.description, 25)}</p>
                  <div className="dateCommen">
                    <p style={{ fontFamily: "monospace", paddingTop: "5px" }}>
                      {elt.date_publication}
                    </p>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginLeft: "-60px", fontSize: "15px" }}>
                        like
                      </div>
                      <div
                        style={{
                          height: "15px",
                          width: "20px",
                          marginLeft: "5px",
                        }}
                      >
                        <img src="heart.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="viewMore">
            <button>Voir plus</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ActualiteBody;
