import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import GooGleAuth from "./GoogleAuth";

export default function LoginForm({ register, handleSubmit, onSubmit }) {


  return (
    <form class="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div id="login-flex">
        <h1>CONNECTEZ-VOUS</h1>
        <div className="login-input">
          <div className="login-input-container">
            <input
              type="email"
              id="email"
              name="email"
              required
              placeholder="Adresse email"
              {...register("email", { required: true })}
            />
            <FontAwesomeIcon icon={faEnvelope} className="text-black" />
          </div>
        </div>
        <div className="login-input">
          <div className="login-input-container">
            <input
              type="password"
              id="password"
              name="password"
              required
              placeholder="Mot de passe"
              {...register("password", { required: true })}
            />
            <FontAwesomeIcon icon={faLock} className="text-black" />
          </div>
        </div>
        <div id="login-btn-container">
          <button id="login-btn" type="submit">
            SE CONNECTER
          </button>
        </div>
        <div id="reset-sub" className="space-x-5 bottom-2 border-red-500">
          <Link className="text-xl" to={"/register"}>S'inscrire</Link>
          <Link className="underline" to={"/forgot-password"}>Mot de passe oublié ?</Link>
        </div>
        <div className="social-login ">
          <GooGleAuth />
        </div>
      </div>
    </form>
  );
}
