import { GoogleLogin } from "@react-oauth/google";

import jwt_decode from "jwt-decode";


export default function GooGleAuth() {
  const handleGoogleLogin = async (credentialResponse) => {
      const decode = jwt_decode(credentialResponse.credential);
      console.log(decode);
  };
  return (
    <GoogleLogin
      onSuccess={handleGoogleLogin}
      onError={() => {
        console.log("Login Failed");
      }}
    />
  );
}
