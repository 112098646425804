import { Link } from "react-router-dom";
import { FiUsers, FiUserCheck } from "react-icons/fi";
import { CiMenuBurger } from "react-icons/ci";
import { GrAnnounce } from "react-icons/gr";
import { FaRegNewspaper } from "react-icons/fa";
import { ADMIN, ADMINACTUS, ADMINVERIFANNOUNCE, ADMINVERIFUSER } from "../../routes";
import { useState } from "react";

const HeaderAdmin = () => {
  const [hover, setHover] = useState(false);

  const handleMenuClick = () => {
    setHover(!hover);
  };

  return (
    <>
      <div
        className={`${hover ? "w-48" : "w-16"} h-screen transition-width delay-15 ease-out bg-slate-200 `}
      >
        <div className="w-full flex justify-center pt-4">
          <CiMenuBurger
            size={30}
            className="cursor-pointer"
            onClick={handleMenuClick}
          />
        </div>
        <div className={`h-full flex flex-col ${hover ? 'ml-2':'items-center'} mt-10`}>
          <Link to={ADMIN} className="h-16 flex items-center">
            <FiUsers size={30} />
            <h2 className={`transition-all duration-150 ml-2 ${hover ? "opacity-100" : "absolute opacity-0"} `}>Utilisateurs</h2>
          </Link>
          <Link to={ADMINVERIFUSER} className="h-16 flex items-center">
            <FiUserCheck size={30} />
            <h2 className={`transition-all duration-150 ml-2 ${hover ? "opacity-100" : "absolute opacity-0"} `}>Utilisateurs à vérifié</h2>
          </Link>
          <Link to={ADMINVERIFANNOUNCE} className="h-16 flex items-center">
            <GrAnnounce size={30} />
            <h2 className={`transition-all duration-150 ml-2 ${hover ? "opacity-100" : "absolute opacity-0"} `}>Annonces à vérifié</h2>
          </Link>
          <Link to={ADMINACTUS} className="h-16 flex items-center">
            <FaRegNewspaper size={30} />
            <h2 className={`transition-all duration-150 ml-2 ${hover ? "opacity-100" : "absolute opacity-0"} `}>Actualités</h2>
          </Link>
        </div>
      </div>
    </>
  );
};

export default HeaderAdmin;
