import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { API_REMOTE } from "../../routes";

const AnnonceModal = ({ annonceId, closeModal }) => {
  const [annonce, setAnnonce] = useState("");

  useEffect(() => {
    const fetchAnnonceById = async (annonceId) => {
      try {
        if (annonceId) {
          const response = await axios.get(
            `${API_REMOTE}/annonces/id/${annonceId}`
          );
          console.log(response.data);
          setAnnonce(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchAnnonceById(annonceId);
  }, [annonceId]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const deleteAnnonce = async (userId) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir bannir cet utilisateur ?"
    );
    if (!confirmed) {
      return;
    }
    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.delete(`${API_REMOTE}/annonces/${userId}`, {
        headers: {
          authorization: `${jwtCookie}`,
        },
      });
      closeModal();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'utilisateur :", error);
    }
  };

  const handleAccept = async (annonceId) => {
    const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");

    const response = await axios.put(
      `${API_REMOTE}/annonces/${annonceId}/verif`,
      { is_verified: 1 },
      {
        headers: {
          authorization: `${jwtCookie}`,
        },
      }
    );

    if (response && response.data) {
      closeModal();
    }
  };

  return (
    <>
      <div className="image-modal">
        <div className="bg-modal" onClick={closeModal} />
        <div className="modal-content h-full bg-purple-800/90 rounded shadow-lg text-white">
          <div className="w-2/5 h-2/5 bg-slate-400">
            <Slider {...settings} className="">
              {annonce &&
                annonce.images !== null &&
                annonce.images
                  .slice(1, -1)
                  .split(",")
                  .map((image, index) => (
                    <div className="pr-4 py-2   max-md:pr-0 max-md:py-0 rounded flex justify-center items-center w-full h-full ">
                      <div className=" flex justify-center items-center">
                        <img
                          key={index}
                          className="max-w-[250px] h-[250px] max-md:w-full max-md:h-full rounded-md max-md:rounded max-md:p-4 bg-cover bg-center"
                          src={API_REMOTE + "/" + image}
                          alt={annonce.name}
                        />
                      </div>
                    </div>
                  ))}
            </Slider>
          </div>
          <div className="relative">
            <h1 className="text-2xl">{annonce.name}</h1>
            {annonce.categorie}
            {annonce.sous_categorie}
            {annonce.sous_sous_categorie} 
            {annonce.description} 
          </div>
          <div>
            <button
              onClick={() => deleteAnnonce(annonce.id)}
              className="border p-2 bg-red-500 rounded-md ml-4"
            >
              Refuser la publication
            </button>
            <button
              className="border p-2 bg-green-400 rounded-md ml-4"
              onClick={() => handleAccept(annonce.id)}
            >
              Accepter la publication
            </button>
          </div>
          <button className="close-button" onClick={closeModal}>
            Fermer
          </button>
        </div>
      </div>
    </>
  );
};

export default AnnonceModal;
