// UserList.js
import React, { useEffect, useState } from "react";
import { API_REMOTE } from "../../routes";
import axios from "axios";
import Cookies from "js-cookie";
import { io } from "socket.io-client";
import User from "../../requests/currentUser/User";

const socket = io.connect(API_REMOTE);

const UserList = ({ currentUser, direction }) => {
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState("");
  const [lastMessage, setLastMessage] = useState("");

  // Récupération de l'utilisateur courant
  const userObj = User();

  // Fonction de tri personnalisée
  const customSort = (userA, userB) => {
    // Si l'utilisateur A a une notification et l'utilisateur B n'en a pas, mettez A en premier
    if (userA.has_notification && !userB.has_notification) {
      return -1;
    }
    // Si l'utilisateur B a une notification et l'utilisateur A n'en a pas, mettez B en premier
    else if (!userA.has_notification && userB.has_notification) {
      return 1;
    }
    // Si les deux ont une notification ou les deux n'en ont pas, passez au critère suivant

    // Si l'utilisateur A est connecté et l'utilisateur B n'est pas connecté, mettez A en premier
    if (userA.is_connected && !userB.is_connected) {
      return -1;
    }
    // Si l'utilisateur B est connecté et l'utilisateur A n'est pas connecté, mettez B en premier
    else if (!userA.is_connected && userB.is_connected) {
      return 1;
    }
    // Si les deux sont connectés ou les deux ne le sont pas, ou si les deux ont une notification ou les deux n'en ont pas, passez au critère suivant

    // Triez par ordre alphabétique des noms (prénom + nom)
    const nameA = userA.firstname + " " + userA.lastname;
    const nameB = userB.firstname + " " + userB.lastname;
    return nameA.localeCompare(nameB);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const jwtToken = Cookies.get("jwt") && Cookies.get("jwt");
      try {
        const response = await axios.get(`${API_REMOTE}/users`, {
          headers: {
            Authorization: `${jwtToken}`,
          },
        });

        if (response && response.data) {
          console.log(response.data);
          // Émettre un événement vers le serveur pour demander la liste des utilisateurs
          socket.emit("get-users-request");
          console.log(socket.emit("get-users-request"));

          socket.on("get-users", (connectedUsersFromServer) => {
            console.log("response.data");
            // Créez un ensemble (Set) des identifiants d'utilisateurs connectés
            const connectedUserIds = new Set(
              Object.keys(connectedUsersFromServer)
            );

            // Mettez à jour la liste des utilisateurs avec l'état de connexion
            const usersWithConnectionStatus = response.data.map((user) => {
              return {
                ...user,
                is_connected: connectedUserIds.has(user.id),
              };
            });

            const filteredUsers = usersWithConnectionStatus;
            console.log(connectedUserIds);

            // Triez le tableau pour que les utilisateurs connectés apparaissent en premier
            filteredUsers.sort(customSort);

            setUsers(filteredUsers);
            console.log("filtreuser", filteredUsers);
          });
        } else {
          console.log("Aucune donnée renvoyée par l'API.");
        }
      } catch (e) {
        console.log("Erreur lors de l'appel API :", e);
      }
    };

    // Appelez fetchUsers au chargement initial
    fetchUsers();

    return () => {
      socket.off("get-users");
    };
  }, [userObj]);

  // Gestionnaire d'événements "connect" pour émettre "user-connect" après le rechargement de la page
  useEffect(() => {
    socket.on("notification", (senderId) => {
      // Mettez à jour l'état pour marquer l'utilisateur avec une notification
      setUsers((prevUsers) =>
        prevUsers
          .map((user) => {
            console.log(
              user.firstname,
              user.id !== active && user.id === senderId,
              "userid ",
              user.id,
              "active ",
              active,
              "senderid ",
              senderId
            );
            return {
              ...user,
              has_notification: user.id !== active && user.id === senderId,
            };
          })
          .sort(customSort)
      );
    });

    // Lorsque le composant est monté, émettez l'événement "is_connect" avec l'identifiant de l'utilisateur si disponible
    if (userObj && userObj?.user?.id) {
      socket.emit("is_connect", userObj.user.id);
    }

    // Écoutez l'événement "connect" du socket.io et réémettez "is_connect" lorsque la connexion est établie
    socket.on("connect", () => {
      if (userObj && userObj?.user?.id) {
        socket.emit("is_connect", userObj.user.id);
      }
    });

    // Lorsque le composant est démonté, émettez l'événement "is_not_connect" avec l'identifiant de l'utilisateur si disponible
    return () => {
      if (userObj && userObj?.user?.id) {
        socket.emit("is_not_connect", userObj.user.id);
      }
    };
  }, [socket, userObj]);

  const handleUserClick = (user) => {
    console.log(user);
    setActive(user.id);
    currentUser(user);
    user.has_notification = false;
  };

  return (
    <div
      className={`eachuser user-list w-full flex divide-y flex-col ${
        direction === "message" ? "h-screen" : "items-center"
      }`}
    >
      {/* {console.log(users)} */}
      {users
        .filter((user) => user.id !== (userObj && userObj?.user?.id))
        .map((user) => (
          <div
            className={`py-2 flex flex-col ${
              direction === "message" ? "justify-center pl-3" : "items-center"
            } uppercase`}
            key={user.id}
            onClick={() => handleUserClick(user)}
          >
            {user.image ? (
              <div className="relative">
                <img
                  className={`rounded-full h-12 w-12 object-cover shadow-[0_3px_5px_rgba(109,48,142,0.55)] ${
                    active === user.id
                      ? "animate-pulse outline-double outline-4 outline-purple-600"
                      : ""
                  }`}
                  src={API_REMOTE + "/" + user.image}
                  alt={`${user.firstname} ${user.lastname}`}
                  title={`${user.firstname} ${user.lastname}`}
                />
                {user.is_connected && (
                  <span class="absolute right-0 bottom-0 mr-1 mt-1 flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                  </span>
                )}
                {user.has_notification && (
                  <span class="absolute right-0 top-0 mr-1 mt-1 flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-500 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-orange-600"></span>
                  </span>
                )}
              </div>
            ) : (
              <div className="relative">
                <img
                  className={`rounded-full h-12 w-12 object-cover shadow-[0_3px_5px_rgba(109,48,142,0.55)] ${
                    active === user.id
                      ? "animate-pulse outline-double outline-4 outline-purple-600"
                      : ""
                  }`}
                  src="/joker.jpg"
                  alt={`${user.firstname} ${user.lastname}`}
                  title={`${user.firstname} ${user.lastname}`}
                />
                {user.is_connected && (
                  <span class="absolute right-0 bottom-0 mr-1 mt-1 flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                  </span>
                )}
                {user.has_notification && (
                  <span class="absolute right-0 top-0 mr-1 mt-1 flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-500 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-orange-600 text-white"></span>
                  </span>
                )}
              </div>
            )}
            {/* <div className="ml-2 text-xs text-gray-500">{unreadMessageCounts[user.id]}</div> // Feature pas encore en place */}
            <div
              className={`text-sm max-w-[75px] overflow-hidden overflow-ellipsis ${
                active === user.id
                  ? "text-purple-600 [text-shadow:_1px_2px_1px_rgba(109,48,142,0.25)]"
                  : "[text-shadow:_1px_2px_1px_rgba(50,50,50,0.30)]"
              }`}
            >
              {user.firstname}
            </div>
            <div
              className={`text-[9px] max-w-[75px] overflow-hidden overflow-ellipsis ${
                active === user.id
                  ? "text-purple-600 [text-shadow:_1px_2px_1px_rgba(109,48,142,0.25)]"
                  : "[text-shadow:_1px_2px_1px_rgba(50,50,50,0.30)]"
              }`}
            >
              {user.lastname}
            </div>
            {direction === "message" && (
              <div className="text-sm max-w-[75px] overflow-hidden overflow-ellipsis">
                {user.last_message}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default UserList;

//
