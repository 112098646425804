import { ToastContainer } from "react-toastify";
import HeaderHero from "../components/HomeChild/HeaderHero";
import Magasine from "../components/HomeChild/Magasine";
import MarketPlace from "../components/HomeChild/MarketPlace";
import Seo from "../components/Seo";

// import Header from "../components/Header";
export default function Home() {
  return (
    <div>
      <Seo
        title={"Retrogems, ventes achats jeux rétros"}
        description={
          "Bienvenue sur Retrogems, la destination ultime pour acheter et vendre des objets, jeux vidéo rétro ,next gen, cartes de collections, figurines et bien d'autres. Explorez notre vaste sélection d'articles, des équipements aux accessoires,des objets de collections rares, des figurines et plongez dans l'univers du gaming comme jamais auparavant."
        }
      />
      <HeaderHero />
      <MarketPlace />
      <Magasine />
      <ToastContainer />
    </div>
  );
}
