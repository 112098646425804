import './ActualiteSlider.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { API_REMOTE } from "../../../routes";


const ActualiteSlider = () => {

    const [actualite, setActualite] = useState("");

  

    useEffect(() => {
      const fetchActu = async () => {
        try {
          const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
  
          const response = await axios.get(`${API_REMOTE}/actus`, {
            headers: {
              Authorization: `${jwtCookie}`,
            },
          });
          if(response && response.data){
              console.log(response.data);
              setActualite(response.data)
          }
        } catch (err) {
          console.log(err);
        }
      };
      fetchActu();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000
    };

    function truncateDescription(description, wordLimit) {
        const words = description.split(" ");
        if (words.length > wordLimit) {
          return words.slice(0, wordLimit).join(" ") + "...";
        }
        return description;
      }

    return (
        <div className="Intro-content">
            <div style={{ textAlign: 'center', margin: '20px', fontWeight: 'black' }}>
                <h1 style={{ fontSize: '30px' }}>Soyez informer de nos derniers actualités jeux et Retrogaming <span>🍄🏰🏠🏡</span></h1>
            </div>
            <div className='carroussel-contener'>
                <div className='carroussel-content'>
                    <Slider {...settings}>
                        {
                            actualite && actualite.map((elt, index) => (
                                // <div className='image-detail' key={index}>
                                //     <div id='imag'>
                                //         <img src={elt.image} alt="" />
                                //     </div>
                                //     <div className='detail-img-card'>
                                //         <div className='detail-img'>
                                //             <h1 style={{ fontSize: '30px', fontWeight: 'bold' }}>{elt.titre}</h1>
                                //             <p>{elt.description}</p>
                                //         </div>
                                //     </div>
                                // </div>
                                <div key={index} className="image-detail">
                                <div className="imag">
                                  <img src={API_REMOTE + '/' + elt.image} alt="" />
                                </div>
                                <div className="detail-img-card">
                                    <div  className='detail-img'    >
                                         <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
                                        {elt.title}
                                        </h1>
                                  <p>{truncateDescription(elt.description, 25)}</p>

                                  <div className="dateCommen">
                                    <p style={{ fontFamily: "monospace", paddingTop: "5px" }}>
                                      {elt.date_publication}
                                    </p>
                                    <div style={{ display: "flex" }}>
                                    </div>
                                  </div>
                                    </div>
                                </div>
                              </div>
                            ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default ActualiteSlider;