import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API_REMOTE } from "../routes";
import "../css/login/login.scss";

const ForgotPassword = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState('');
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${API_REMOTE}/users/reset-password-request`,
        data
      );

      if (response.status === 200) {
        setMessage(
          "Un e-mail de réinitialisation de mot de passe a été envoyé."
        );
      } else {
        const responseData = response.data;
        setMessage(
          responseData.message ||
          "Une erreur est survenue lors de la demande de réinitialisation de mot de passe."
        );
      }
    } catch (error) {
      console.error(
        "Erreur lors de la demande de réinitialisation de mot de passe:",
        error
      );
      setMessage(
        "Une erreur est survenue lors de la demande de réinitialisation de mot de passe."
      );
    }
  };

  return (
    <section>
      <div id="login-contain" style={{ backgroundImage: `url("/market.jpg")` }}>
        <form class="login-form" onSubmit={handleSubmit(onSubmit)}>
          <div id="login-flex">
            <h1 className="p-1 text-center">
              Entrez votre adresse e-mail pour réinitialiser votre mot de passe.
            </h1>
            <div className="login-input">
              <div className="login-input-container">
                <input
                  placeholder="Adresse email"
                  type="email"
                  {...register("email", { required: true })}
                />
                {errors.email && <p>Ce champ est requis.</p>}
              </div>
            </div>
            <div id="login-btn-container">
              <button id="login-btn" type="submit">Envoyer l'e-mail de réinitialisation</button>
            </div>
            {message && <h2 className="text-lg ">{message}</h2>}
          </div>
        </form>
      </div>
    </section>
  );
};

export default ForgotPassword;
