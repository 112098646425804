import "../css/headerGeneral/headerGeneral.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHeart, faUser } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ADMIN, API_REMOTE, FAVORIS, MESSAGES } from "../routes";
import User from "../requests/currentUser/User";
import { GrUserAdmin } from "react-icons/gr";
import axios from "axios";


export default function HeaderGeneral() {
  const [toggle_burger, setToggleBurger] = useState("false");
  const location = useLocation();
  const [annonces, setAnnonces] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // Récupération de l'utilisateur courant
  const userObj = User();

  const toggleSubMenu = () => {
    setToggleBurger(!toggle_burger);
  };

  const getAllAnnonces = async (searchQuery = "") => {
    try {
      const response = await axios.get(
        `${API_REMOTE}/annonces/search?search=${searchQuery}`
      );
      setAnnonces(response.data);
      // console.log(response.data);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleSearch = async () => {
    if (searchValue === "") {
      setAnnonces([]);
    } else {
      await getAllAnnonces(searchValue);
    }
  };

  const truncateName = (name) => {
    if (name.length > 10) {
      return name.substring(0, 10) + "...";
    }
    return name;
  };

  const handleSearchChange = (e) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);
    setTimeout(() => {
      if (newSearchValue === "") {
        setAnnonces([]);
      } else {
        getAllAnnonces(newSearchValue);
      }
    }, 300);
  };

  // Si l'URL est celle de la page d'accueil, retournez null
  if (location.pathname === "/") {
    return null;
  }

  return (
    <header id="headerGeneral" className="bg-black">
      <video id="header-video" autoPlay muted loop>
        <source src="videoRetro.mp4" type="video/mp4" />
      </video>
      <div id="contain" className="">
        <div id="flexable">
          <div id="nav-pic">
            <span id="burger-menu">
              <FontAwesomeIcon onClick={toggleSubMenu} icon={faBars} />
            </span>
            <Link to={"/"}>
              <img src="/logo.png" alt="" />
            </Link>
          </div>
          <div id="log-sub-menu" className="space-x-2">
            <Link to={FAVORIS} id="">
              <span>
                <FontAwesomeIcon
                  className="icon-sub-menu text-red-500  sub-menu-basket"
                  icon={faHeart}
                />
              </span>
            </Link>
            <Link to={"/login"}>
              <FontAwesomeIcon className="icon-sub-menu" icon={faUser} />
            </Link>
          </div>
          <ul>
            <li>
              <Link className="nav-link" to={"/about"}>
                A PROPOS
              </Link>
            </li>
            <li>
              <Link className="nav-link" to={"/store"}>
                MARKETPLACE
              </Link>
            </li>
            <li>
              <Link className="nav-link" to={"/actualite"}>
                ACTUALITÉS
              </Link>
            </li>
            <li>
              <Link className="nav-link" to={"/sell"}>
                GRADING
              </Link>
            </li>
          </ul>
          <div className="relative w-1/5" id="hero-input">
            <input
              type="search"
              className="block p-2.5 w-full dark:placeholder-gray-400 dark:text-black"
              id="keyword"
              placeholder="Mot clé..."
              required
              value={searchValue}
              // onChange={(e) => setSearchValue(e.target.value)}
              onChange={handleSearchChange}
            />
            <div className="absolute w-full">
              {annonces.slice(0, 5).map((annonce) => (
                <Link to={`/annonces/id/${annonce.id}`}>
                  <div
                    id="search"
                    className="p-2 bg-white text-black flex items-start border  "
                    key={annonce.id}
                  >
                    <div
                      className="p-1 w-1/4 overflow-hidden"
                      style={{ width: 75, height: 75 }}
                    >
                      {annonce.images !== null &&
                        annonce.images
                          .slice(1, -1)
                          .split(",")
                          .map((image, index) => (
                            <div key={index}>
                              <img
                                className="object-cover w-full"
                                src={API_REMOTE + "/" + image}
                                style={{ width: 75, height: 75 }}
                                alt=""
                              />
                            </div>
                          ))}
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold">{annonce.name}</h3>
                      <p className="pt-2 text-sm">
                        {truncateName(annonce.description)}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <button
              className="absolute top-0 right-0 p-2.5 text-sm font-medium h-11 bg-purple-800 text-white z-50"
              onClick={handleSearch}
            >
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
              <span className="sr-only">Search</span>
            </button>
          </div>
          <div id="shop">
            <Link to={FAVORIS} id="basket">
              <span>
                <FontAwesomeIcon
                  className="icon-sub-menu text-red-500  basket-pic text-2xl"
                  icon={faHeart}
                />
              </span>
              <span id="shopping-text">MES FAVORIS</span>
            </Link>
            {userObj && userObj ? (
              <Link to={"/profil"} id="login-link">
                MON ESPACE
              </Link>
            ) : (
              <Link to={"/login"} id="login-link">
                CONNEXION
              </Link>

            )}
            {userObj && userObj.user.roles.includes("admin") && (
              <Link
                to={userObj && userObj.user.roles.includes("admin") && ADMIN}
                className="bg-white rounded-full p-2 ml-4"
              >
                <GrUserAdmin size={30} />
              </Link>
            )}
            {/* <div className={`${notif ? 'motion-safe:animate-bounce' : ''} fixed bottom-6 right-6 p-3 rounded-full bg-white cursor-pointer shadow-xl z-50`} onClick={handleUserClick}>
              <div className="text-purple-600">
                {notif &&
                  <span class="absolute right-0 top-0 mr-1 mt-1 flex h-3 w-3">
                    <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-500 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-purple-600"></span>
                  </span>
                }
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-12 h-12">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                </svg>
              </div>
            </div> */}
          </div>
        </div>
        <div id="sub-menu" className={toggle_burger ? "isVisible" : ""}>
          <Link className="sub-menu-link">A PROPOS</Link>
          <Link to={"/store"} className="sub-menu-link">
            MARKETPLACE
          </Link>
          <Link className="sub-menu-link">ACTUALITÉS</Link>
          <Link className="sub-menu-link">GRADING</Link>
          <Link to={MESSAGES} className="sub-menu-link">
            MESSAGES
          </Link>
        </div>
      </div>
      {/* {modalIsOpen && (
        <ChatModal closeModal={closeModal} modalIsOpen={true} />
      )} */}
    </header>
  );
}
