import { Link } from "react-router-dom";
import "../../css/HomeStyle/magasine/magasine.scss";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { API_REMOTE } from "../../routes";


export default function Magasine() {

  function truncateDescription(title, wordLimit) {
    const words = title.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return title;
  }

  const [actualite, setActualite] = useState("");

  useEffect(() => {
    const fetchActu = async () => {
      try {
        const jwtCookie = Cookies.get("jwt");

        const response = await axios.get(`${API_REMOTE}/actus`, {
          headers: {
            Authorization: `${jwtCookie}`,
          },
        });
        if (response && response.data) {
          console.log(response.data);
          const sortedNews = [...response.data].sort((a, b) => new Date(b.date_publication
          ) - new Date(a.date_publication
          ));
          setActualite(sortedNews);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchActu();
  }, []);

  const actuJour = actualite.length > 0 ? actualite[0] : null;
  console.log(actualite);

  const getRandomNews = () => {
    let newsItems = [...actualite];
    let randomNews = [];
    

    //fonction aleatoire pour les actualités
    for (let i = 0; i < 3; i++) {
      if (newsItems.length === 0) break;
      const randomIndex = Math.floor(Math.random() * newsItems.length);
      randomNews.push(newsItems.splice(randomIndex, 1)[0]);
    }

    return randomNews;
  };

  const randomNewsItems = getRandomNews();


  return (
    <section style={{ backgroundImage: `url('/actualite.jpg')` }}>
      <div
        id="magasine-contain"
        className="flex flex-col items-center flex-wrap pt-24"
      >
        <div>
          <h2>LE MAGASINE POUR TOUS LES FANS DE JEUX-VIDÉO</h2>
          <div id="magasine-grid">
            <div id="dashbord">
              <div className="mb">
                <Link>TEST</Link>
                <img src="/test.jpg" alt="test" />
              </div>
              <div className="mb">
                <Link>COLLECTION</Link>
                <img src="/collection.jpg" alt="collection" />
              </div>
              <div className="mb">
                {" "}
                <Link>EVENEMENT</Link>
                <img src="/evenement.jpg" alt="evenement" />
              </div>
              <div className="mb">
                {" "}
                <Link to="/actualite">ACTUALITE</Link>
                <img src="/actu.jpg" alt="actualite" />
              </div>
              <div className="mb">
                {" "}
                <Link>HISTOIRE DU JV</Link>
                <img src="/histoire.jpg" alt="histoire" />
              </div>
              <div className="mb">
                {" "}
                <Link>ASTUCES</Link>
                <img src="/astuces.jpg" alt="astuces" />
              </div>
              <div>
                <Link>BANDES ANNONCES</Link>
              </div>
            </div>
            <div id="magasine-actu">
              <div id="actu-jour"><Link>
                {actuJour ? (
                  <>
                    <img src={API_REMOTE + '/' + actuJour.image} alt={actuJour.image} />
                    <h3 className="text-center">{truncateDescription(actuJour.title, 3)}</h3>
                  </>
                ) : (
                  <p>No recent news available.</p>
                )}</Link>
              </div>
              {randomNewsItems.map((newsItem, index) => (
          <div key={index} className="mini-actu">
            <img src={API_REMOTE + '/' + newsItem.image} alt={newsItem.title} />
            <p>{truncateDescription(newsItem.title, 3)}</p>
          </div>
        ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
