import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'; // Importez useParams et useNavigate
import { API_REMOTE } from '../routes';

const ResetPassword = () => {
  const { resetToken } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Utilisez useNavigate pour la redirection

  const onSubmit = async (data) => {
    try {
      console.log(resetToken, data);
      const response = await axios.post(`${API_REMOTE}/users/reset-password`, {
        password: data.password,
        token: resetToken,
      });

      if (response.status === 200) {
        setMessage('Mot de passe réinitialisé avec succès.');
        // Effectuez la redirection vers /login
        navigate('/login');
      } else {
        const responseData = response.data;
        setMessage(responseData.message || 'Une erreur est survenue lors de la réinitialisation du mot de passe.');
      }
    } catch (error) {
      console.error('Erreur lors de la réinitialisation du mot de passe:', error);
      setMessage('Une erreur est survenue lors de la réinitialisation du mot de passe.');
    }
  };

  return (
    <div className="min-h-[500px]">
      <h2>Réinitialisation de mot de passe</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>
            Nouveau mot de passe :
            <input
              type="password" {...register('password', { required: true })}
            />
          </label>
          {errors && <p>Ce champ est requis.</p>}
        </div>
        <button type="submit">Réinitialiser le mot de passe</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ResetPassword;
