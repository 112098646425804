import React from 'react';

const ImageModal = ({ imageUrl, closeModal }) => {
  
  return (
    <div className="image-modal">
      <div className="modal-content">
        <img src={imageUrl} alt="Image en taille réelle" />
        <button className="close-button" onClick={closeModal}>
          Fermer
        </button>
      </div>
      
    </div>
  );
};

export default ImageModal;
