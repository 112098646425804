import "../../css/HomeStyle/market/market.scss";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { API_REMOTE } from "../../routes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChatModal from "../Chat/ChatModal";
import { FaShoppingCart, FaHeart } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import { BsFillChatDotsFill } from "react-icons/bs";
import User from "../../requests/currentUser/User";

export default function MarketPlace() {
  const [annonces, setAnnonces] = useState([]);
  const [retros, setRetros] = useState([]);
  const [nexts, setNexts] = useState([]);
  const [annonceUsers, setAnnonceUsers] = useState([]);
  const [chatModalIsOpen, setChatModalIsOpen] = useState(false);
  const [userObj, setUserObj] = useState("");
  const [hasOffer, setHasOffer] = useState(false);
  const [annonce, setAnnonce] = useState({});
  const { annonceId } = useParams();
  const [offerModalIsOpen, setOfferModalIsOpen] = useState(false);
  const [proposedPrice, setProposedPrice] = useState("");

  
  // Récupération de l'utilisateur courant
  const currentUser = User();

  const handleOpenChatModal = () => {
    setChatModalIsOpen(true);
    console.log(chatModalIsOpen);
  };
  const handleCloseChatModal = () => {
    setChatModalIsOpen(false);
    console.log(chatModalIsOpen);
  };
  const closeOfferModal = () => {
    setOfferModalIsOpen(false);
  };
  const openOfferModal = () => {
    setOfferModalIsOpen(true);
  };

  const handleOfferSubmit = async (e) => {
    e.preventDefault();

    const newPrice = e.target.newPrice.value;

    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.post(
        `${API_REMOTE}/offer/`,
        {
          annonceId: annonceId,
          userId: currentUser.id,
          price: newPrice * 100,
        },
        {
          headers: {
            Authorization: jwtCookie,
          },
        }
      );

      // Mettez à jour l'état du prix de l'offre proposée
      setProposedPrice(newPrice * 100);
      setHasOffer(true);
      closeOfferModal();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const response = await axios.get(`${API_REMOTE}/users/${userId}`);
      return response.data; // Les données de l'utilisateur
    } catch (error) {
      console.error(
        `Erreur lors de la récupération des données de l'utilisateur ${userId}: ${error.message}`
      );
      return null; // En cas d'erreur, retournez null ou un objet vide
    }
  };

  useEffect(() => {
    const fetchAnnonces = async () => {
      try {
        const response = await axios.get(`${API_REMOTE}/annonces/oui`);
        // console.log("annonces:", response.data);
        setAnnonces(response.data);

        // Récupérer les informations de l'utilisateur pour chaque annonce
        const userPromises = response.data.map((annonce) =>
          fetchUserData(annonce.user_id)
        );
        const usersData = await Promise.all(userPromises);
        setAnnonceUsers(usersData);

        // console.log("global", response.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchAnnonces();
  }, []);

  useEffect(() => {
    const fetchNext = async () => {
      try {
        const response = await axios.get(`${API_REMOTE}/annonces/non`);
        if (response) {
          console.log(response.data);
          setNexts(response.data);
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchNext();
  }, []);
  
  useEffect(() => {
    const fetchRetro = async () => {
      try {
        const response = await axios.get(`${API_REMOTE}/annonces/maybe`);
        setRetros(response.data);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchRetro();
  }, []);

  const truncateName = (name) => {
    if (name.length > 28) {
      return name.substring(0, 28) + "...";
    }
    return name;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const notify = () => toast("Le produit a été ajouté dans votre panier");

  // const addToCart = (annonceId) => {
  //   const userId = currentUser && currentUser.id;
  //   const quantity = 1;

  //   const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");

  //   axios
  //     .post(
  //       `${API_REMOTE}/panier`,
  //       { userId, annonceId, quantity },
  //       {
  //         headers: {
  //           Authorization: jwtCookie,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       notify();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  useEffect(() => {
    const fetchAnnonceById = async (annonceId) => {
      try {
        if (annonceId) {
          const response = await axios.get(
            `${API_REMOTE}/annonces/id/${annonceId}`
          );
          setHasOffer(false);
          console.log(response.data);
          setAnnonce(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getUserData = async () => {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      try {
        const response = await axios.get(
          `${API_REMOTE}/users/${annonce.user_id}`,
          {
            headers: {
              authorization: `${jwtCookie}`,
            },
          }
        );
        setUserObj(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données utilisateur :",
          error
        );
      }
    };

    fetchAnnonceById(annonceId);
    // Conditionner l'appel à getUserData uniquement si annonce.user_id est défini
    if (annonce.user_id) {
      getUserData();
    }
  }, [annonceId, annonce.user_id]);

  //   {
  //     id: 1,
  //     categorie: "Next-Gen",
  //     image: image1,
  //     name: "Zenbonsakura ",
  //     prix: 175,
  //     user: { firstName: "leo", lastName: "Hannibal", image: imageUser1 },
  //   },
  //   {
  //     id: 2,
  //     categorie: "New-Gen",
  //     image: image2,
  //     name: "WADO ISHIMONJI ",
  //     prix: 215,
  //     user: { firstName: "lea", lastName: "oignon", image: imageUser2 },
  //   },
  //   {
  //     id: 3,
  //     categorie: "Retro-Gen",
  //     image: image4,
  //     name: "WAZAMONO ",
  //     prix: 75,
  //     user: { firstName: "leon", lastName: "vanilla", image: null },
  //   },
  // ];

  return (
    <div className="w-full  flex flex-col justify-center items-center">
      {/* toutes annonce confondues */}
      <div
        className=" max-md: p-1 md:max-w-[1200px] "
        style={{ backgroundImage: `url('/market.jpg')` }}
      >
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl py-4 flex flex-col justify-center items-center font-semibold uppercase">
          les nouveautés
        </h1>
        <div className="grid grid-cols-1 gap-4   md:px-8 md:py-8  ">
          {annonces.map((annonce, index) => {
            const user = annonceUsers[index];
            return (
              <div
                key={index}
                className="w-full rounded-[4px] overflow-hidden shadow-lg bg-[#6d318e80] hover:bg-[#6d318eff] hover:shadow-xl border-2 border-purple-700 transition duration-900  ease-in mb-4 md:px-4 hover:scale-105 hover:translate-y-[-16px]"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2">
                  <div className="w-full h-full  ">
                    <Link to={`/annonces/id/${annonce.id}`} key={annonce.id}>
                      <div className="w-full h-full ">
                        <Slider {...settings} className="">
                          {annonce &&
                            annonce.images !== null &&
                            annonce.images
                              .slice(1, -1)
                              .split(",")
                              .map((image, index) => (
                                <div className="pr-4 py-2   max-md:pr-0 max-md:py-0 rounded flex justify-center items-center w-full h-full ">
                                  <div className=" flex justify-center items-center">
                                    <img
                                      key={index}
                                      className="max-w-[250px] h-[250px] max-md:w-full max-md:h-full rounded-md max-md:rounded max-md:p-4 bg-cover bg-center"
                                      src={API_REMOTE + "/" + image}
                                      alt={annonce.name}
                                    />
                                  </div>
                                </div>
                              ))}
                        </Slider>
                      </div>
                    </Link>
                  </div>
                  <div className="p-4 ">
                    <div className="font-bold text-xl mb-2 text-slate-200  ">
                      <div className="flex items-center justify-between text-3xl uppercase">
                        {truncateName(annonce.name)}
                        <div className="flex space-x-2">
                          <FaHeart className="text-lg sm:text-xl mr-4 " />
                        </div>
                      </div>
                    </div>

                    <p className="text-slate-200 text-base font-semibold mb-2">
                      <span className="font-bold uppercase">Catégorie : </span>
                      {annonce.categorie}
                    </p>
                    <p className="text-slate-200 text-base font-semibold mb-2">
                      <span className="font-bold uppercase">Etat : </span>
                      {annonce.etat}
                    </p>
                    <p className="text-slate-200 text-base mb-2">
                      <span className="font-bold uppercase">Prix : </span>
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(annonce.prix / 100)}
                    </p>

                    {user && (
                      <div className="flex items-center">
                        {user.image ? (
                          <img
                            className="w-12 h-12 object-cover rounded-full border-[1px] border-slate-700"
                            src={API_REMOTE + "/" + user.image}
                            alt={user.firstname}
                          />
                        ) : (
                          <img
                            className="w-12 h-12 object-cover rounded-full"
                            src="/joker.jpg"
                            alt="Joker"
                          />
                        )}
                        <p className="text-xl font-semibold ml-3 text-slate-200">
                          {user.firstname}
                        </p>
                      </div>
                    )}
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                      <button className="px-4 py-2 flex justify-center items-center bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-200 text-sm sm:text-base"onClick={handleOpenChatModal}>
                        <BsFillChatDotsFill
                          className="mr-2 text-lg sm:text-xl"
                          
                        />
                        Message
                      </button>
                      <button
                        className="px-4 py-2 flex items-center justify-center bg-green-500 text-white rounded hover:bg-green-700 focus:outline-none focus:ring focus:ring-green-200 text-sm sm:text-base"
                        onClick={openOfferModal}
                      >
                        <FaShoppingCart className="mr-2 text-lg sm:text-xl" />
                        Acheter
                      </button>
                      <button
                        className="px-4 py-2 flex items-center justify-center bg-yellow-500 text-white rounded hover:bg-yellow-700 focus:outline-none focus:ring focus:ring-yellow-200 text-sm sm:text-base"
                        // onClick={() => addToCart(annonce.id)}
                      >
                        <AiOutlineStar className="mr-2 text-lg sm:text-xl" />
                        Ajouter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {chatModalIsOpen && (
            <ChatModal
              selectedUserDefault={userObj}
              closeModal={handleCloseChatModal}
              modalIsOpen={true}
            />
          )}

          {offerModalIsOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
              <div className="bg-white p-6 rounded-lg w-80">
                <h2 className="text-xl font-semibold mb-4 text-black">
                  Proposer une offre
                </h2>
                <form onSubmit={handleOfferSubmit}>
                  <label className="block mb-2 text-black">
                    Nouveau prix :
                  </label>
                  <input
                    type="number"
                    name="newPrice"
                    className="w-full border text-black rounded p-2 mb-4"
                    required
                  />
                  <div className="flex justify-between">
                    <button
                      type="button"
                      onClick={closeOfferModal}
                      className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="bg-green-500 text-white px-4 py-2 rounded"
                    >
                      Proposer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* toutes la next gen */}
      <div
        className=" max-md: p-1"
        style={{ backgroundImage: `url('/market.jpg')` }}
      >
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl py-4 flex flex-col justify-center items-center font-semibold uppercase">
          les next gen
        </h1>
        <div className="grid grid-cols-1 gap-4   md:px-8 md:py-8  ">
          {nexts.map((next, index) => {
            const user = annonceUsers[index];
            return (
              <div
                key={index}
                className="w-full rounded-[4px] overflow-hidden shadow-lg bg-[#6d318e80] hover:bg-[#6d318eff] hover:shadow-xl border-2 border-purple-700 transition duration-900 hover:translate-y-[-16px] ease-in mb-4 md:px-4"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2">
                  <div className="w-full h-full  ">
                    <Link to={`/annonces/id/${next.id}`} key={next.id}>
                      <div className="w-full h-full ">
                        <Slider {...settings} className="">
                          {next &&
                            next.images !== null &&
                            next.images
                              .slice(1, -1)
                              .split(",")
                              .map((image, index) => (
                                <div className=" pr-4 py-2 max-md:pr-0 max-md:py-0 rounded flex justify-center items-center w-full h-full ">
                                  <div className=" flex justify-center items-center">
                                    <img
                                      key={index}
                                      className="max-w-[250px] h-[250px] max-md:w-full max-md:h-full rounded-md max-md:rounded max-md:p-4 bg-cover bg-center"
                                      src={API_REMOTE + "/" + image}
                                      alt={next.name}
                                    />
                                  </div>
                                </div>
                              ))}
                        </Slider>
                      </div>
                    </Link>
                  </div>
                  <div className="p-4 ">
                    <div className="font-bold text-xl mb-2 text-slate-200  ">
                      <div className="flex items-center justify-between text-3xl uppercase">
                        {truncateName(next.name)}
                        <div className="flex space-x-2">
                          <FaHeart className="text-lg sm:text-xl mr-4 " />
                        </div>
                      </div>
                    </div>

                    <p className="text-slate-200 text-base font-semibold mb-2">
                      <span className="font-bold uppercase">Catégorie : </span>
                      {next.categorie}
                    </p>
                    <p className="text-slate-200 text-base font-semibold mb-2">
                      <span className="font-bold uppercase">Etat : </span>
                      {next.etat}
                    </p>
                    <p className="text-slate-200 text-base mb-2">
                      <span className="font-bold uppercase">Prix : </span>
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(next.prix / 100)}
                    </p>

                    {user && (
                      <div className="flex items-center">
                        {user.image ? (
                          <img
                            className="w-12 h-12 object-cover rounded-full border-[1px] border-slate-700"
                            src={API_REMOTE + "/" + user.image}
                            alt={user.firstname}
                          />
                        ) : (
                          <img
                            className="w-12 h-12 object-cover rounded-full"
                            src="/joker.jpg"
                            alt="Joker"
                          />
                        )}
                        <p className="text-xl font-semibold ml-3 text-slate-200">
                          {user.firstname}
                        </p>
                      </div>
                    )}
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                      <button className="px-4 py-2 flex justify-center items-center bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-200 text-sm sm:text-base" onClick={handleOpenChatModal}>
                        <BsFillChatDotsFill
                          className="mr-2 text-lg sm:text-xl"
                        />
                        Message
                      </button>
                      <button
                        className="px-4 py-2 flex items-center justify-center bg-green-500 text-white rounded hover:bg-green-700 focus:outline-none focus:ring focus:ring-green-200 text-sm sm:text-base"
                        onClick={openOfferModal}
                      >
                        <FaShoppingCart className="mr-2 text-lg sm:text-xl" />
                        Acheter
                      </button>
                      <button
                        className="px-4 py-2 flex items-center justify-center bg-yellow-500 text-white rounded hover:bg-yellow-700 focus:outline-none focus:ring focus:ring-yellow-200 text-sm sm:text-base"
                        // onClick={() => addToCart(annonce.id)}
                      >
                        <AiOutlineStar className="mr-2 text-lg sm:text-xl" />
                        Ajouter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          {offerModalIsOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
              <div className="bg-white p-6 rounded-lg w-80">
                <h2 className="text-xl font-semibold mb-4 text-black">
                  Proposer une offre
                </h2>
                <form onSubmit={handleOfferSubmit}>
                  <label className="block mb-2 text-black">
                    Nouveau prix :
                  </label>
                  <input
                    type="number"
                    name="newPrice"
                    className="w-full border text-black rounded p-2 mb-4"
                    required
                  />
                  <div className="flex justify-between">
                    <button
                      type="button"
                      onClick={closeOfferModal}
                      className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="bg-green-500 text-white px-4 py-2 rounded"
                    >
                      Proposer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* tout le retrogaming */}
      <div
        className=" max-md: p-1"
        style={{ backgroundImage: `url('/market.jpg')` }}
      >
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl py-4 flex flex-col justify-center items-center font-semibold uppercase">
          le retrogaming
        </h1>
        <div className="grid grid-cols-1 gap-4   md:px-8 md:py-8  ">
          {retros.map((retro, index) => {
            const user = annonceUsers[index];
            return (
              <div
                key={index}
                className="w-full rounded-[4px] overflow-hidden shadow-lg bg-[#6d318e80] hover:bg-[#6d318eff] hover:shadow-xl border-2 border-purple-700 transition duration-900 hover:translate-y-[-16px] ease-in mb-4 md:px-4"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2">
                  <div className="w-full h-full  ">
                    <Link to={`/annonces/id/${retro.id}`} key={retro.id}>
                      <div className="w-full h-full ">
                        <Slider {...settings} className="">
                          {retro &&
                            retro.images !== null &&
                            retro.images
                              .slice(1, -1)
                              .split(",")
                              .map((image, index) => (
                                <div className=" pr-4 py-2 max-md:pr-0 max-md:py-0 rounded flex justify-center items-center w-full h-full ">
                                  <div className=" flex justify-center items-center">
                                    <img
                                      key={index}
                                      className="max-w-[250px] h-[250px] max-md:w-full max-md:h-full rounded-md max-md:rounded max-md:p-4 bg-cover bg-center"
                                      src={API_REMOTE + "/" + image}
                                      alt={retro.name}
                                    />
                                  </div>
                                </div>
                              ))}
                        </Slider>
                      </div>
                    </Link>
                  </div>
                  <div className="p-4 ">
                    <div className="font-bold text-xl mb-2 text-slate-200  ">
                      <div className="flex items-center justify-between text-3xl uppercase">
                        {truncateName(retro.name)}
                        <div className="flex space-x-2">
                          <FaHeart className="text-lg sm:text-xl mr-4 " />
                        </div>
                      </div>
                    </div>

                    <p className="text-slate-200 text-base font-semibold mb-2">
                      <span className="font-bold uppercase">Catégorie : </span>
                      {retro.categorie}
                    </p>
                    <p className="text-slate-200 text-base font-semibold mb-2">
                      <span className="font-bold uppercase">Etat : </span>
                      {retro.etat}
                    </p>
                    <p className="text-slate-200 text-base mb-2">
                      <span className="font-bold uppercase">Prix : </span>
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(retro.prix / 100)}
                    </p>

                    {user && (
                      <div className="flex items-center">
                        {user.image ? (
                          <img
                            className="w-12 h-12 object-cover rounded-full border-[1px] border-slate-700"
                            src={API_REMOTE + "/" + user.image}
                            alt={user.firstname}
                          />
                        ) : (
                          <img
                            className="w-12 h-12 object-cover rounded-full"
                            src="/joker.jpg"
                            alt="Joker"
                          />
                        )}
                        <p className="text-xl font-semibold ml-3 text-slate-200">
                          {user.firstname}
                        </p>
                      </div>
                    )}
                    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                      <button className="px-4 py-2 flex justify-center items-center bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-200 text-sm sm:text-base"onClick={handleOpenChatModal}>
                        <BsFillChatDotsFill
                          className="mr-2 text-lg sm:text-xl"
                          
                        />
                        Message
                      </button>
                      <button
                        className="px-4 py-2 flex items-center justify-center bg-green-500 text-white rounded hover:bg-green-700 focus:outline-none focus:ring focus:ring-green-200 text-sm sm:text-base"
                        onClick={openOfferModal}
                      >
                        <FaShoppingCart className="mr-2 text-lg sm:text-xl" />
                        Acheter
                      </button>
                      <button
                        className="px-4 py-2 flex items-center justify-center bg-yellow-500 text-white rounded hover:bg-yellow-700 focus:outline-none focus:ring focus:ring-yellow-200 text-sm sm:text-base"
                        // onClick={() => addToCart(annonce.id)}
                      >
                        <AiOutlineStar className="mr-2 text-lg sm:text-xl" />
                        Ajouter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          
          {offerModalIsOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
              <div className="bg-white p-6 rounded-lg w-80">
                <h2 className="text-xl font-semibold mb-4 text-black">
                  Proposer une offre
                </h2>
                <form onSubmit={handleOfferSubmit}>
                  <label className="block mb-2 text-black">
                    Nouveau prix :
                  </label>
                  <input
                    type="number"
                    name="newPrice"
                    className="w-full border text-black rounded p-2 mb-4"
                    required
                  />
                  <div className="flex justify-between">
                    <button
                      type="button"
                      onClick={closeOfferModal}
                      className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className="bg-green-500 text-white px-4 py-2 rounded"
                    >
                      Proposer
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* ******************************************************* */}
    </div>
  );
}
