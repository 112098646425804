import React, { useState } from "react";
import HeaderAdmin from "./HeaderAdmin";
import User from "../../requests/currentUser/User";
import Users from "../../requests/users/Users";
// import DeleteUser from "../../requests/users/DeleteUser";
import FilterSortUsers from "../../requests/filter/FilterSortUsers";
import { CiSearch } from "react-icons/ci";
import { BsArrowBarUp, BsArrowBarDown } from "react-icons/bs";
import AccessDenied from "../../Assets/AccessDenied";

export default function AdminRead() {
  const [users, setUsers] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const userObj = User();

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  if (userObj && userObj.user.roles.includes("admin")) {
    return (
      <section className="flex h-full">
        <HeaderAdmin />
        <div
          className="flex flex-col items-center p-6 w-full"
          style={{ backgroundImage: `url('/market.jpg')` }}
        >
          <div className="flex flex-col w-full">
            <div className="relative flex items-center md:w-2/3 max-w-full h-12 rounded-lg focus-within:shadow-lg bg-white overflow-hidden mb-4">
              <div className="grid place-items-center h-full w-12 text-gray-300">
                <CiSearch/>
              </div>
              <input
                className="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
                type="text"
                id="search"
                placeholder="Recherche..."
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
            </div>
            <h1 className="text-3xl underline">
              Liste des utilisateurs ({users.length}):
            </h1>
            <table className="md:w-full">
              <thead>
                <tr>
                  <th>
                    <button onClick={() => handleSort("firstname")}>
                      Prénom {sortConfig.key === "firstname" && (sortConfig.direction === 'asc' ? <BsArrowBarUp/> : <BsArrowBarDown/>)}
                    </button>
                  </th>
                  <th>
                    <button onClick={() => handleSort("lastname")}>Nom</button>
                  </th>
                  <th>
                    <button onClick={() => handleSort("email")}>Email</button>
                  </th>
                  <th>
                    <button onClick={() => handleSort("roles")}>Rôles</button>
                  </th>
                  <th>
                    <button>Actions</button>
                  </th>
                </tr>
              </thead>
              <Users setUsers={setUsers} />
              <FilterSortUsers
                users={users}
                searchKeyword={searchKeyword}
                sortConfig={sortConfig}
              />
            </table>
          </div>
        </div>
      </section>
    );
  }
  return (
    <div className="w-full flex justify-center">
      <AccessDenied/>
    </div>
  );
}
