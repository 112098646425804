import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import ReactRating from "react-rating";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
// import Store from "../pages/Store";
import { API_REMOTE } from "../routes";
import useDocumentTitle from "../components/DocumentTitle"
import User from "../requests/currentUser/User";

const Note = () => {
  const [rating, setRating] = useState(0);
  const [panier, setPanier] = useState([]);
  const navigate = useNavigate();

    // Récupération de l'utilisateur courant
    const userObj = User()

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const fetchPanier = async () => {
    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      const userId = userObj && userObj.id;
      // console.log(userId);
      

      if (userId) {
        const response = await axios.get(
          `${API_REMOTE}/panier/${userId}`,
          {
            headers: {
              Authorization: `${jwtCookie}`,
            },
          }
        );
        // const {user_id , annonce_id}= response.data
        const datas = response.data[0].annonce.user_id 
        // console.log(response.data[0].user_id);
        setPanier(datas);
        console.log(panier);
        // console.log(panier)
      }
    } catch (error) {
      console.error(error);
    }
    
  };

  const submitRatingAndPanier = async () => {
    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      const userId = userObj && userObj.id;

      if (userId && rating !== 0) {
        console.log(panier);
        alert(`voulez vous vraiment donner la note de : ${rating}/5`)
        await axios.post(
          `${API_REMOTE}/note`, // Endpoint pour soumettre la note et les informations du panier
          {
           
            rating,
            panier,
          },
          {
            headers: {
              Authorization: `${jwtCookie}`,
            },
          }
          );
      }
    //  console.log(res.data);
    } catch (error) {
      console.error(error);
    }
    // console.log( panier)
    navigate("/panier");
  };

  useEffect(() => {
    fetchPanier();
  }, [rating ]);

  useDocumentTitle("Avis - Retrogems");

  return (
    <div className="text-center mt-6 w-full min-h-[500px]">
      <p className="text-lg font-semibold mb-2">
        {userObj.lastname + " " + userObj.firstname} comment s'est passé votre transaction:
      </p>
      <ReactRating
        initialRating={rating}
        emptySymbol={<FaStar size={24} className="rating-icon empty text-gray-400" />}
        fullSymbol={<FaStar size={24} className="rating-icon full text-purple-400" />}
        fractions={1}
        onChange={handleRatingChange}
      />
      {rating !== 0 && (
        <p className="text-gray-600 mt-2">{`${rating>1?`Vous avez attribué une note de ${rating} étoiles`:`Vous avez attribué une note de ${rating} étoile` }`}</p>
      )}

      <button onClick={submitRatingAndPanier}
      className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md mt-4"
      >Soumettre</button>
      
      {/* ... */}
    </div>
  );
};

export default Note;
