import React from "react";
function BtnPWicon({id,contenu,action,icon,alt}){
    return(
        <div id ={id} className="action flex justify-between items-center cursor-pointer" onClick={action}>
                  <span>{contenu}</span>
                  <img src={icon} className="w-8" alt={alt} />
        </div>
    )
}

export default BtnPWicon