import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { API_REMOTE } from "../routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toastify/dist/ReactToastify.css";
import ChatModal from "../components/Chat/ChatModal";
import User from "../requests/currentUser/User";

const StoreCard = ({
  annonce,
  toggleFavorite,
  isAnnouncementInFavorites,
  numberFavorites,
}) => {
  const [userObj, setUserObj] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [annonces, setAnnonce] = useState({});
  const [offerModalIsOpen, setOfferModalIsOpen] = useState(false);
  const [proposedPrice, setProposedPrice] = useState("");
  const [hasOffer, setHasOffer] = useState(false);

  // Récupération de l'utilisateur courant
  const currentUser = User();

  const truncateName = (name) => {
    if (name.length > 40) {
      return name.substring(0, 40) + "...";
    }
    return name;
  };

  const openOfferModal = () => {
    setOfferModalIsOpen(true);
  };

  const closeOfferModal = () => {
    setOfferModalIsOpen(false);
  };

  const handleUserClick = () => {
    setSelectedUser(annonce.user_id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setModalIsOpen(false);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleOfferSubmit = async (e) => {
    e.preventDefault();

    const newPrice = e.target.newPrice.value;

    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.post(
        `${API_REMOTE}/offer/`,
        {
          annonceId: annonce.id,
          userId: currentUser.user.id,
          price: newPrice * 100,
        },
        {
          headers: {
            Authorization: jwtCookie,
          },
        }
      );

      // Mettez à jour l'état du prix de l'offre proposée
      setProposedPrice(newPrice * 100);
      setHasOffer(true);
      closeOfferModal();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      if (currentUser) {
        async function getOffer() {
          const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
          const response = await axios.get(
            `${API_REMOTE}/offer/${currentUser.user.id}/${annonce.id}`,
            {
              headers: {
                Authorization: jwtCookie,
              },
            }
          );
          if (response && response.data[0]) {
            setHasOffer(true);
            setProposedPrice(response.data[0].prix);
            console.log(response.data[0].prix);
          }
        }
        getOffer();
      }
    } catch (error) {
      console.error(error);
    }
  }, [userObj, annonce.id, hasOffer]);

  useEffect(() => {
    const fetchAnnonceById = async (annonceId) => {
      try {
        if (annonceId) {
          const response = await axios.get(
            `${API_REMOTE}/annonces/id/${annonceId}`
          );
          setHasOffer(false);
          setAnnonce(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getUserData = async () => {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      try {
        const response = await axios.get(
          `${API_REMOTE}/users/${annonce.user_id}`,
          {
            headers: {
              authorization: `${jwtCookie}`,
            },
          }
        );
        setUserObj(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données utilisateur :",
          error
        );
      }
    };

    fetchAnnonceById(annonce.id);
    // Conditionner l'appel à getUserData uniquement si annonce.user_id est défini
    if (annonce.user_id) {
      getUserData();
    }
  }, [annonce.id, annonce.user_id]);

  return (
    <div className="w-full" id="store-card">
      <div
        className="flex w justify-between
      "
      >
        <Link
          className="w-11/12"
          to={`/annonces/id/${annonce.id}`}
          key={annonce.id}
        >
          {" "}
          <div className="flex  gap-10 p-6">
            <div className="" style={{ width: 191, height: 200 }}>
              <Slider {...settings}>
                {annonce.images !== null &&
                  annonce.images
                    .slice(1, -1)
                    .split(",")
                    .map((image, index) => (
                      <div key={index} className="">
                        <img
                          style={{ width: 191, height: 200 }}
                          className=" object-cover "
                          src={API_REMOTE + "/" + image}
                          alt=""
                        />
                      </div>
                    ))}
              </Slider>
            </div>
            <div id="store-info" className="w-5/6">
              <p className="pt-4 text-left text-3xl font-semibold">
                {truncateName(annonce.name)}
              </p>
              <p className="pt-2 text-left font-semibold">
                {annonce.categorie}
              </p>
              <p className="font-bold text-xl pt-4 text-left">
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(annonce.prix / 100)}
              </p>
              {userObj.image ? (
                <div className="w-12 h-12 flex items-center gap-3 mt-6">
                  <img
                    className="w-full h-full object-cover rounded-full"
                    src={API_REMOTE + "/" + userObj.image}
                    alt=""
                  />
                  <p className="text-xl font-semibold">{userObj.firstname} </p>
                </div>
              ) : (
                <div className="w-12 h-12 flex items-center gap-3 mt-6">
                  <img
                    className="w-full h-full object-cover rounded-full"
                    src="/joker.jpg"
                    alt="Joker"
                  />
                </div>
              )}
            </div>
          </div>
        </Link>
        <div className="  flex flex-col items-center justify-around">
          <FontAwesomeIcon
            icon={faHeart}
            className={`text-${
              isAnnouncementInFavorites ? "red-500" : "black"
            } p-3 ml-2 rounded-full bg-white cursor-pointer`}
            onClick={() => toggleFavorite(annonce.id)}
          />
          <span className="m-1">{numberFavorites && numberFavorites}</span>{" "}
          {/* <button onClick={addToCart}>
            <img src="/panier.png" className="w-8" alt="acheter" />
          </button> */}
          <button onClick={openOfferModal}>
            {" "}
            <img src="/acheter.png" className="w-8" alt="" />
          </button>
          <button onClick={handleUserClick}>
            <img src="/message.png" className="w-8" alt="" />
          </button>
          <Link to={`/users/${userObj.id}`}>
            {" "}
            <img src="/vitrine.png" className="w-8" alt="vitrine" />
          </Link>
        </div>
      </div>

      {modalIsOpen && (
        <ChatModal
          selectedUserDefault={userObj}
          closeModal={closeModal}
          modalIsOpen={true}
        />
      )}
      {offerModalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg w-80">
            <h2 className="text-xl font-semibold mb-4 text-black">
              Proposer une offre
            </h2>
            <form onSubmit={handleOfferSubmit}>
              <label className="block mb-2 text-black">Nouveau prix :</label>
              <input
                type="number"
                name="newPrice"
                className="w-full border text-black rounded p-2 mb-4"
                required
              />
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={closeOfferModal}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Proposer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreCard;
