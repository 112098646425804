import React, { useEffect, useState } from "react";
import { API_REMOTE } from "../../routes";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import "../../css/panier/panier.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import User from "../../requests/currentUser/User";



const Panier = ({ articles }) => {
  const navigate = useNavigate();
  const [panier, setPanier] = useState();

  useEffect(() => {
    setPanier(articles);
  }, [articles]);

  // Récupération de l'utilisateur courant
  const userObj = User()

  const calculateTotal = (cartItems) => {
    return (
      cartItems &&
      cartItems.reduce(
        (total, item) => total + item.annonce.prix * item.quantity,
        0
      )
    );
  };

  // État pour contrôler l'affichage du modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  if (!Cookies.get("jwt")) {
    navigate("/login");
  }

  // Fonction pour mettre à jour la quantité d'un article dans le panier
  const updateQuantity = async (articleId, newQuantity) => {
    try {
      // Récupérer le JWT du cookie
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");

      await axios.put(
        `${API_REMOTE}/panier/${articleId}`,
        { quantity: newQuantity },
        {
          headers: {
            Authorization: `${jwtCookie}`,
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  // Fonction pour supprimer un article du panier
  const removeFromCart = async (articleId) => {
    const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cet article du panier ?");
    if (!confirmed) {
      return;
    }
    try {
      // Récupérer le JWT du cookie
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");

      await axios
        .delete(`${API_REMOTE}/panier/${articleId}`, {
          headers: {
            Authorization: `${jwtCookie}`,
          },
        })
        .then(
          // Mise à jour de l'état local articles après la suppression
          setPanier((prevPanier) =>
            prevPanier.filter((item) => item.id !== articleId)
          )
        );
    } catch (error) {
      console.error(error);
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // Fonction pour effectuer l'achat
  const effectuerAchat = async () => {
    const confirmed = window.confirm("Êtes-vous sûr de vouloir payer maintenant ?");
    if (!confirmed) {
      return;
    }
    try {
      // Récupérer le JWT du cookie
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      const userId = userObj && userObj.user.id;

      // Récupérer le solde de l'utilisateur
      const response = await axios.get(`${API_REMOTE}/users/${userId}`, {
        headers: {
          Authorization: `${jwtCookie}`,
        },
      });
      const soldeUtilisateur = response.data.solde;
      const reqUserId = response.data.id;

      // Calculer le total du panier
      const totalPanier = calculateTotal(panier);
      // Vérifier si le solde est suffisant
      if (soldeUtilisateur >= totalPanier) {
        // Mettre à jour le solde de l'utilisateur (retrait du montant total du panier)
        await axios.post(
          `${API_REMOTE}/users/solde`,
          { userId: reqUserId, amount: -totalPanier * 100 },
          {
            headers: {
              Authorization: `${jwtCookie}`,
            },
          }
        );
        navigate('/panier/note')
         

        // Supprimer le contenu du panier
        // for (const article of panier) {
        //   await removeFromCart(article.id);
        // }



        // Afficher le modal d'achat réussi
        setIsModalOpen(true);
        setModalMessage("Achat réussi !");
      } else {
        // Afficher le modal de solde insuffisant
        setIsModalOpen(true);
        setModalMessage("Solde insuffisant. Veuillez recharger votre compte.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fonction pour fermer le modal
  const closeModal = () => {
    setIsModalOpen(false);
    // Rediriger l'utilisateur vers une autre page si nécessaire
    // ... (code de redirection ici)
  };

  return (
    <section>
      <div
        id="panier-container"
        style={{ backgroundImage: `url('/market.jpg')` }}
        className="min-h-[500px]"
      >
        <h1 className="p-3 text-3xl font-semibold underline underline-offset-4" id="title">
          VOTRE PANIER :
        </h1>
        <div className="flex" id="panier-flex">
          <ul className=" px-16 py-6 w-2/3">
            {panier?.map((article) => (
              <li
              id="fav-card"
                //   bg-slate-300
                className="p-4 flex w-full mt-4"
                key={article.id}
              >
                <div className="overflow-hidden border" style={{ width: 250, height: 200 }}>
                  <Slider {...settings}>
                    {article.annonce.images !== null &&
                      article.annonce.images
                        .slice(1, -1)
                        .split(",")
                        .map((image, index) => (
                          <div
                            key={index}
                            className="flex justify-center"
                          >
                            <img
                              style={{ width: 250, height: 200 }}
                              className=" object-cover "
                              src={API_REMOTE + "/" + image}
                              alt=""
                            />
                          </div>
                        ))}
                  </Slider>
                </div>
                <div className="flex w-full ml-6">
                  <div className="w-5/6 p-1">
                    <h1 className="text-3xl font-semibold">
                      {article.annonce.name}
                    </h1>
                    <p>{article.annonce.description}</p>
                    <p><span className="text-purple-600">#</span> {article.annonce.categorie}</p>
                    {article.annonce.sous_categorie && (<p> <span className="text-purple-600">#</span> {article.annonce.sous_categorie}</p>)}
                    {article.annonce.sous_sous_categorie && (<p><span className="text-purple-600">#</span> {article.annonce.sous_sous_categorie}</p>)}
                    {article.annonce.etat && (<p><span className="text-purple-600">#</span> {article.annonce.etat}</p>)}
                  </div>
                  <div className="flex flex-col justify-center">
                    <span className="text-3xl font-semibold text-center">
                      {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(article.annonce.prix / 100)}
                    </span>
                    <button
                      id="delpanier"
                      className=""
                      onClick={() => removeFromCart(article.id)}
                    >
                      Supprimer
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="flex flex-col justify-center items-center w-1/3">
            <span className="text-4xl font-bold">Total</span>
            <span className="text-5xl">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(calculateTotal(panier) / 100)}</span>
            <button id="pay" className="" onClick={effectuerAchat}>
              PAYER
            </button>
          </div>

          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-8 rounded-lg text-center">
                <h2 className="text-2xl font-bold mb-4">{modalMessage}</h2>
                <button
                  className="bg-blue-600 px-4 py-2 rounded-lg text-white"
                  onClick={closeModal}
                >
                  Fermer
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Panier;
