import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import "../../css/bankCard/bankCard.scss";
import { useEffect, useState } from "react";
import { API_REMOTE } from "../../routes";
import User from "../../requests/currentUser/User";

const CheckoutPaymentForm = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  // Récupération de l'utilisateur courant
  const userObj = User()

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });
    if (!error) {
      console.log("Token : ", paymentMethod);
      try {
        const { id } = paymentMethod;
        const response = await axios.post(`${API_REMOTE}/stripe/charge`, {
          amount: e.target.amount.value,
          id: id,
        });
        if (response.data.success) {
          console.log("Transaction réussie");
          // Récupérer le JWT du cookie
          const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
          const servReponse = await axios.post(
            `${API_REMOTE}/addSolde`,
            {
              userId: userObj && userObj.user.id,
              amount: e.target.amount.value,
            },
            {
              headers: {
                Authorization: `${jwtCookie}`,
              },
            }
          );
          console.log("Ajout du solde réussi");
          navigate("/profil");
        }
      } catch (error) {
        console.log("Erreur. : ", error);
      }
    } else {
      console.log(error.message);
    }
  };

  return (
    <section>
      <div id="payment-form" style={{ backgroundImage: `url('/market.jpg')` }}>
        <div id="payment-contain">
          <form onSubmit={handleSubmit}>
            <label htmlFor="amount" className="">
              Montant de l'opération
            </label>
            <input
              className="text-black m-2 py-1"
              id="amount"
              name="amount"
              type="number"
            />
            <CardElement
              options={{
                hidePostalCode: true,
              }}
            />
            <button className="mt-5">CREDITER</button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CheckoutPaymentForm;
