import React from "react";

function BtnPW({id,contenu,action}){
    return(
        <button id={id} className="bg-mauve text-principal mt-3 px-10 py-3 rounded-full" onClick={action}>
              {contenu}
        </button>
    )
}

export default BtnPW