import React, { useEffect } from 'react'
import Btn from '../btn/Btn'
import '../filter/filterCategory.scss'

export default function FilterCategory({setActiveCategory, activeCategory,setFiltered,annonce,handleClick}) {

  useEffect(()=>{
    if(activeCategory === "Tout"){
      setFiltered(annonce);
      return;
    }
    const filtered = annonce.filter((newVal)=> newVal.categorie.includes(activeCategory))
    setFiltered(filtered)
  },[activeCategory]);

  return (
    <div id='filter-container'>
        <h2 className="sidebar-title font-bold text-xl mb-4">Categories</h2>
        <div id="filter-list">
          <Btn classe={activeCategory === "Tout" ? "active filter-list-item" : "filter-list-item"}action={()=> setActiveCategory("Tout")} value="" contenu="Tout" />
          <Btn classe={activeCategory === "RETROGAMING" ? "active filter-list-item" : "filter-list-item"} action={()=> setActiveCategory("RETROGAMING")} value="RETROGAMING" contenu="Retrogaming" />
          <Btn classe={activeCategory === "NEXTGEN" ? "active filter-list-item" : "filter-list-item"} action={()=> setActiveCategory("NEXTGEN")} contenu="Next Gen" />
          <Btn classe={activeCategory === "FIGURINES" ? "active filter-list-item" : "filter-list-item"} action={()=> setActiveCategory("FIGURINES")} contenu="Figurines" />
          <Btn classe={activeCategory === "COLLECTOR" ? "active filter-list-item" : "filter-list-item"} action={()=> setActiveCategory("COLLECTOR")} contenu="Collector" />
          <Btn classe={activeCategory === "GOODIES" ? "active filter-list-item" : "filter-list-item"} action={()=> setActiveCategory("GOODIES")} contenu="Goodies" />
          <Btn classe={activeCategory === "GEEKART" ? "active filter-list-item" : "filter-list-item"} action={()=> setActiveCategory("GEEKART")} contenu="Geek Art" />
          <Btn classe={activeCategory === "CARTES A COLLECTIONNER" ? "active filter-list-item" : "filter-list-item"} action={()=> setActiveCategory("CARTES A COLLECTIONNER")} contenu="Carte de collection" />
          <Btn classe={activeCategory === "LESPEPITES" ? "active filter-list-item" : "filter-list-item"} action={()=> setActiveCategory("LESPEPITES")} contenu="Les pépites" />
          
        </div>
    </div>
  )
}
