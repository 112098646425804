import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import Cookies from "js-cookie";
import { API_REMOTE } from "../../routes";
import UserList from "../Profil/UserList";
import moment from "moment/moment";
import User from "../../requests/currentUser/User";

const socket = io.connect(API_REMOTE, {
  transports: ["websocket"],
  withCredentials: true,
});

const ChatModal = ({ selectedUserDefault, closeModal, modalIsOpen }) => {
  const jwtToken = Cookies.get("jwt") && Cookies.get("jwt");
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const chatContainerRef = useRef(null);
  const inputRef = useRef(10);
  const [modalHeight, setModalHeight] = useState(500);
  const [isResizing, setIsResizing] = useState(false);
  const [chatHeight, setChatHeight] = useState(modalHeight);
  const modalRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState({});

  // Récupération de l'utilisateur courant
  const userObj = User();

  const handleUserModalClick = (user) => {
    // markMessageAsRead()
    setSelectedUser(user);
    console.log(user);
  };

  useEffect(() => {
    if (selectedUserDefault) {
      setSelectedUser(selectedUserDefault);
    }
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    };

    // Après chaque mise à jour des messages, défilez automatiquement vers le bas
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (userObj && userObj.user.id) {
      // Si userObj existe et userObj.user.id est défini, alors appelez socket.emit("joinRoom")
      socket.emit("joinRoom", userObj.user.id);
    }

    // Nettoyez l'effet lors du démontage du composant
    return () => {
      if (userObj && userObj.user.id) {
        // Si userObj existe et userObj.user.id est défini, alors appelez socket.emit("leaveRoom")
        socket.emit("leaveRoom", userObj.user.id);
      }
    };
  }, [userObj]);

  useEffect(() => {
    const handleResize = (event) => {
      if (isResizing) {
        const modalRect = modalRef.current.getBoundingClientRect();
        const newHeight = modalRect.bottom - event.clientY;
        const maxHeight = window.innerHeight * 0.75; // 75% de la hauteur de la vue
        setModalHeight(Math.min(newHeight, maxHeight));
      }
    };

    setChatHeight(modalHeight - 180);

    const handleResizeEnd = () => {
      setIsResizing(false);
    };

    document.addEventListener("mousemove", handleResize);
    document.addEventListener("mouseup", handleResizeEnd);

    return () => {
      document.removeEventListener("mousemove", handleResize);
      document.removeEventListener("mouseup", handleResizeEnd);
    };
  }, [isResizing]);

  const handleResizeStart = (event) => {
    event.preventDefault();
    setIsResizing(true);
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
    // adjustInputHeight(); // Appel de la fonction pour ajuster la hauteur de l'input
  };

  //   const adjustInputHeight = () => {
  //     if (inputRef.current) {
  //       // Réinitialiser la hauteur de l'input à 0 pour obtenir la hauteur du contenu réel
  //       inputRef.current.style.height = "0";
  //       // Ajuster la hauteur de l'input en fonction de son contenu
  //       inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;

  //       // Limiter la hauteur du textarea à 3 lignes (ou une valeur fixe)
  //       const lineHeight = 20; // Ajustez cette valeur en fonction de la taille de police et des marges du textarea
  //       const maxLines = 4;
  //       const maxHeight = lineHeight * maxLines;
  //       if (inputRef.current.scrollHeight > maxHeight) {
  //         inputRef.current.style.overflowY = "scroll"; // Activer le défilement
  //         inputRef.current.style.height = `${maxHeight}px`;
  //         setModalHeight(modalHeight + inputRef.current.scrollHeight);
  //       } else {
  //         inputRef.current.style.overflowY = "hidden"; // Désactiver le défilement s'il n'est pas nécessaire
  //       }
  //     }
  //   };

  useEffect(() => {
    if (selectedUser && selectedUser) {
      fetchMessages();
    }
  }, [selectedUser]);

  useEffect(() => {
    if (userObj && userObj.user.id) {
      // Si userObj existe et userObj.user.id est défini, alors appelez socket.emit("joinRoom")
      socket.emit("joinRoom", userObj.user.id);
      // adjustInputHeight();
    }

    // Nettoyez l'effet lors du démontage du composant
    return () => {
      if (userObj && userObj.user.id) {
        // Si userObj existe et userObj.user.id est défini, alors appelez socket.emit("leaveRoom")
        socket.emit("leaveRoom", userObj.user.id);
      }
    };
  }, [selectedUser, userObj]);

  useEffect(() => {
    // Créer une fonction de rappel séparée pour gérer les nouveaux messages
    const handleNewMessage = (message) => {
      if (message.sender_id === selectedUser.id) {
        setMessages((prevMessages) => [...prevMessages, message]);

        socket.on("updateUnreadCount", (count) => {
          setUnreadMessageCount((prevCount) => ({
            ...prevCount,
            [selectedUser.id]: count,
          }));
        });
      }
    };

    // Ajouter l'écouteur d'événement "newMessage" avec la fonction de rappel créée ci-dessus
    socket.on("newMessage", handleNewMessage);

    // Nettoyer l'effet lors du démontage du composant
    return () => {
      // Supprimer l'écouteur d'événement "newMessage" en utilisant la même fonction de rappel
      socket.off("newMessage", handleNewMessage);
    };
  }, [selectedUser, userObj]);

  const fetchMessages = async () => {
    try {
      if ((selectedUser && selectedUser?.id) !== (userObj && userObj?.user.id)) {
        const response = await axios.get(
          `${API_REMOTE}/messages/${selectedUser?.id}/${userObj?.user.id}`, // Utilisez userObj?.id pour éviter les erreurs lorsque userObj est null ou undefined
          {
            headers: {
              Authorization: `${jwtToken}`,
            },
          }
        );
        setMessages(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Si l'utilisateur sélectionné change, fermez la modal actuellement ouverte
    if (modalIsOpen) {
      // closeModal();
    }
  }, [selectedUser]);

  const sendMessage = async (receiverId, messageContent) => {
    try {
      const newMessage = {
        sender_id: userObj.user.id,
        receiver_id: receiverId,
        content: messageContent,
        timestamp: moment().format(),
      };
      // Émettre l'événement "sendMessage" pour envoyer le message au serveur
      if (newMessage.content.trim() !== "") {
        socket.emit("sendMessage", newMessage);
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setMessage("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Vérifier si la touche pressée est "Enter"
    if (event.key === "Enter" && !event.shiftKey) {
      sendMessage(selectedUser.id, message);
      setMessage("");
    }
  };

  return (
    <>
      <style>
        {`

                    /* Personnaliser la scrollbar pour les navigateurs WebKit (Chrome, Safari) */

                    .scrollbar ::-webkit-scrollbar {
                        width: 0px;
                    }

                    .scrollbar ::-webkit-scrollbar-thumb {
                        background-color: rgba(0, 0, 0, 0.3);
                        border-radius: 5px;
                    }

                    .scrollbar ::-webkit-scrollbar-thumb:hover {
                        background-color: rgba(0, 0, 0, 0.5);
                    }

                    /* Personnaliser la scrollbar pour Firefox */
                    .scrollbar scrollbar-width: thin;
                    .scrollbar scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
                `}
      </style>
      {modalIsOpen && (
        <>
          <div
            style={{ bottom: modalHeight + 4 }}
            className="hidden md:flex fixed right-4 w-1/4 h-4 cursor-ns-resize"
            onMouseDown={handleResizeStart}
          />
          <div
            ref={modalRef}
            className={`${
              modalIsOpen ? "appear" : ""
            } hidden md:flex scrollbar fixed right-4 bottom-4 bg-white w-1/4 rounded-lg shadow-2xl text-black z-50 ${
              modalIsOpen
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-full"
            } transition-opacity duration-300 ease-in-out`}
            style={{ height: modalHeight }}
          >
            <div className="w-24 hidden md:flex items-center flex-col border border-r-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-14 h-14 my-6 text-black"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z"
                />
              </svg>
              <div
                className="eachuser mb-4 w-full"
                style={{
                  overflowY: "scroll",
                }}
              >
                <UserList
                  currentUser={handleUserModalClick}
                  direction={"col"}
                />
              </div>
            </div>
            <div className="flex flex-col w-full p-6">
              {(selectedUser && selectedUser?.id) ===
              (userObj && userObj?.user?.id) ? (
                <div className="flex">
                  <h1>
                    Veuillez sélectionner un utilisateur pour commencer une
                    discution
                  </h1>
                  <button
                    className="text-gray-500 hover:text-gray-800"
                    onClick={closeModal}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.293 3.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              ) : (
                <>
                  <div className="relative flex items-center justify-between mb-3">
                    <div className="flex items-center">
                      {selectedUser.is_connected && (
                        <span class="relative right-0 mr-1 mt-1 flex h-3 w-3">
                          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                          <span class="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                        </span>
                      )}
                      <h2 className="text-lg font-semibold text-black">
                        {selectedUser.firstname} {selectedUser.lastname}
                      </h2>
                    </div>
                    <button
                      className="text-gray-500 hover:text-gray-800"
                      onClick={closeModal}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3.293 3.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div
                    ref={chatContainerRef}
                    className="rounded px-2 w-full"
                    style={{ height: chatHeight, overflowY: "scroll" }}
                  >
                    {messages.map((message, i) => {
                      const timestamp = message.timestamp; // Récupérez le timestamp du message
                      const date = new Date(timestamp);
                      // console.log(message.content, timestamp);
                      const localTimestamp = new Date(
                        date.getTime() - date.getTimezoneOffset() * 60000
                      );
                      // console.log(message.content, date);

                      return (
                        <div
                          key={i}
                          className={`relative my-2 mb-4 ${
                            message.sender_id === userObj.user.id
                              ? "flex justify-end ml-16"
                              : "flex justify-start mr-16"
                          }`}
                        >
                          <span
                            className={`text-white p-2 pb-3 rounded-t-lg ${
                              message.sender_id === userObj.user.id
                                ? "rounded-l-lg"
                                : "rounded-r-lg"
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: message.content.replace(/\n/g, "<br>"),
                            }}
                            style={{
                              minWidth: "3em",
                              backgroundColor: `${
                                message.sender_id === userObj.user.id
                                  ? "#6D308E"
                                  : "#5E5E5E"
                              }`,
                            }}
                          />
                          <span
                            className={`absolute bottom-0 text-xs text-gray-300 ${
                              message.sender_id === userObj.user.id
                                ? "right-1"
                                : "left-1"
                            }`}
                          >
                            {date.toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                  <form>
                    <textarea
                      ref={inputRef}
                      value={message}
                      onChange={handleInputChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault(); // Empêche le saut de ligne
                          sendMessage(selectedUser.id, message);
                        }
                      }}
                      placeholder="Écrire un message..."
                      className="border border-gray-300 p-2 w-full mt-2 resize-none overflow-hidden"
                    />
                    <button
                      type="button"
                      onClick={() => sendMessage(selectedUser.id, message)}
                      className="bg-[#6D308E] text-white py-2 px-4 mt-2 rounded-lg hover:bg-blue-600"
                    >
                      Envoyer
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChatModal;
