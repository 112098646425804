import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutPaymentForm from '../Payment/CheckoutPaymentForm'

const PUBLIC_KEY = 'pk_test_51NY40yJDuVWPEShtBHO2yux8TKxEbAXfnIYMH599xjgn9XZZ3sa34Gfc0BWzIWaGb5CpkFWBzu9M85iscbef8N6W00eXFSgZbB';
const stripePromise = loadStripe(PUBLIC_KEY);

const PaymentContainer = () => {
    return (
        <Elements stripe={stripePromise} >
            <CheckoutPaymentForm />
        </Elements>
    )
}

export default PaymentContainer