import { useNavigate } from "react-router-dom";
import "../../css/register/register.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLock,
  faHouse,
  faPhoneFlip,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { API_REMOTE } from "../../routes";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import useDocumentTitle from "../DocumentTitle"
import User from "../../requests/currentUser/User";

export default function Register() {
  const navigate = useNavigate();
  let { register, handleSubmit } = useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [role,setRole] = useState('')
  // const [isLoading, setIsLoading] = useState(false);
  const roles = ["customer", "admin"]
  const isCategorie = (e) => {
    setRole(e.target.value);
  };

  // Récupération de l'utilisateur courant
  const userObj = User()


  let onSubmit = async (e) => {
    try {
      // setIsLoading(true);
      const response = await axios.post(`${API_REMOTE}/register`, e);
      console.log(response.data);
      Cookies.set("user", JSON.stringify(response.data)); // enregistrement des données de l'utilisateur dans les cookies
      // setIsLoading(false);
      navigate('/admin')
    } catch (error) {
      console.log(error.response.data);
      setErrorMessage(error.response.data);
      // setIsLoading(false);
    }
  };


  if(userObj && userObj.user.roles.includes('admin')){

    return (
      <section>
        <div
          id="subscribe-contain"
          className=""
          style={{ backgroundImage: `url("/market.jpg")` }}
        >
          <form class="subscribe-form border" onSubmit={handleSubmit(onSubmit)}>
            <div id="subscribe-flex">
              <h1>INSCRIPTION</h1>
              <div className="subscribe-name space-x-5">
                <div className="subscribe-name-container">
                  <input
                    type="text"
                    id="nom"
                    name="lastname"
                    required
                    placeholder="Nom"
                    {...register("lastname", { required: true })}
                  />
                  <FontAwesomeIcon icon={faUser} className="text-black" />
                </div>
                <div className="subscribe-name-container">
                  <input
                    type="text"
                    id="prenom"
                    name="firstname"
                    required
                    placeholder="Prénom"
                    {...register("firstname", { required: true })}
                  />
                  <FontAwesomeIcon icon={faUser} className="text-black" />
                </div>
              </div>
              <div className="subscribe-info">
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  placeholder="E-mail"
                  {...register("email", { required: true })}
                />
                <FontAwesomeIcon icon={faEnvelope} className="text-black" />
              </div>
              <div className="subscribe-info">
                <input
                  type="number"
                  id="numero"
                  name="number"
                  required
                  placeholder="Numéro de téléphone"
                  {...register("number", { required: true })}
                />
                <FontAwesomeIcon icon={faPhoneFlip} className="text-black" />
              </div>
              <div className="subscribe-info">
                <input
                  type="text"
                  id="adresse"
                  name="address"
                  required
                  placeholder="Adresse"
                  {...register("address", { required: true })}
                />
                <FontAwesomeIcon icon={faHouse} className="text-black" />
              </div>
              <div className="subscribe-info">
                <input
                  type="password"
                  id="password"
                  name="password"
                  required
                  placeholder="Mot de passe"
                  {...register("password", { required: true })}
                />
                <FontAwesomeIcon icon={faLock} className="text-black" />
              </div>
              <div>
              <select
                      name="roles"
                      value={role}
                      {...register("roles", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ${
                        role && "mr-2"
                      }`}
                      onChange={isCategorie}
                    >
                      <option value="">Rôle</option>
                      {roles.map((role) => {
                        return <option value={role}>{role}</option>;
                      })}
                    </select>
              </div>
              <div id="subscribe-btn-container" className="mt-4">
                <button id="subscribe-btn" type="submit">
                  INSCRIRE LA PERSONNE
                </button>
              </div>
            </div>
          </form>
          {errorMessage && (
            <p className="text-red-500 my-4 max-w-[10rem] text-center">
              {errorMessage.message}
            </p>
          )}
        </div>
      </section>
    );
  }

  return (
    <div className="w-full flex justify-center">
      <iframe src="https://giphy.com/embed/bKj0qEKTVBdF2o5Dgn" width="480" height="352" frameBorder="0" className="giphy-embed" allowFullScreen></iframe>
    </div>
  )
}