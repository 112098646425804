import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_REMOTE } from "../routes";
import Store from "./Store";
import "../css/getAnnonce/getAnnonce.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookies from "js-cookie";
import ChatModal from "../components/Chat/ChatModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BtnPWicon from "../components/asset/btn/BtnPWicon";
import User from "../requests/currentUser/User";
import Seo from "../components/Seo";

export default function GetAnnonce() {
  const { annonceId } = useParams();
  const [annonce, setAnnonce] = useState({});
  const [userObj, setUserObj] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [offerModalIsOpen, setOfferModalIsOpen] = useState(false);
  const [proposedPrice, setProposedPrice] = useState("");
  const [hasOffer, setHasOffer] = useState(false)

  // Récupération de l'utilisateur courant
  const currentUser = User();

  const openOfferModal = () => {
    setOfferModalIsOpen(true);
  };

  const closeOfferModal = () => {
    setOfferModalIsOpen(false);
  };

  const handleOfferSubmit = async (e) => {
    e.preventDefault();

    const newPrice = e.target.newPrice.value;

    try {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.post(
        `${API_REMOTE}/offer/`,
        {
          annonceId: annonceId,
          userId: currentUser.id,
          price: newPrice * 100,
        },
        {
          headers: {
            Authorization: jwtCookie,
          },
        }
      );

      // Mettez à jour l'état du prix de l'offre proposée
      setProposedPrice(newPrice * 100);
      setHasOffer(true)
      closeOfferModal();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    try {
      if(currentUser){
        async function getOffer() {
          console.log(currentUser);
          const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
          const response = await axios.get(`${API_REMOTE}/offer/${currentUser && currentUser.user.id}/${annonceId}`,
            {
              headers: {
                Authorization: jwtCookie,
              },
            });
          if (response && response.data[0]) {
            setHasOffer(true);
            setProposedPrice(response.data[0].prix);
            console.log(response.data[0].prix);
          }
        }
        getOffer()
      }
    } catch (error) {
      console.error(error);
    }
    console.log(proposedPrice);
  }, [userObj, annonceId, hasOffer]);

  const notify = () => toast("Le produit a été ajouté dans votre panier");

  const addToCart = () => {
    const annonceId = annonce.id;
    const userId = currentUser && currentUser.id;
    const quantity = 1;

    const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");

    axios
      .post(
        `${API_REMOTE}/panier`,
        { userId, annonceId, quantity },
        {
          headers: {
            Authorization: jwtCookie,
          },
        }
      )
      .then((response) => {
        console.log(response.data); 
        notify(); //
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleUserClick = () => {
    setSelectedUser(annonce.user_id)
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null)
    setModalIsOpen(false);
  };


  useEffect(() => {
    const fetchAnnonceById = async (annonceId) => {
      try {
        if (annonceId) {
          const response = await axios.get(
            `${API_REMOTE}/annonces/id/${annonceId}`
          );
          setHasOffer(false)
          setAnnonce(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getUserData = async () => {
      const jwtCookie = Cookies.get("jwt") && Cookies.get("jwt");
      try {
        const response = await axios.get(
          `${API_REMOTE}/users/${annonce.user_id}`,
          {
            headers: {
              authorization: `${jwtCookie}`,
            },
          }
        );
        setUserObj(response.data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données utilisateur :",
          error
        );
      }
    };

    fetchAnnonceById(annonceId);
    // Conditionner l'appel à getUserData uniquement si annonce.user_id est défini
    if (annonce.user_id) {
      getUserData();
    }
  }, [annonceId, annonce.user_id]);

  if (!annonce) {
    return <p>Loading...</p>;
  }

  return (
    <section>
      <Seo title={"Acheter vos jeux rétros, next gen, cartes de collection ,figurines en toute sécurité"} description={""}/>
      <style>
        {`
        .test {
          background-color:black;
          transform: rotate(21deg);
          width: 2em;
          height: 2px;
        }
        `}
      </style>
      <div
        className=""
        id="get-annonce-contain"
        style={{ backgroundImage: `url('/market.jpg')` }}
      >
        <div id="get-annonce-size">
          <div id="get-annonce-name" className="">
            <h1 className="underline text-center">
              {annonce.name} <br /> {annonce.categorie}
            </h1>
          </div>
          <div className="flex justify-evenly mt-6" id="get-annonce-flex">
            <div className="annonce-pic relative h-[320px] w-[270px] bg-white/50">
              <Slider {...settings}>
                {annonce.images !== undefined &&
                  annonce.images
                    .slice(1, -1)
                    .split(",")
                    .map((image, index) => (
                      <div
                        key={index}
                        className=" flex justify-center h-[320px] w-full"
                      >
                        <img
                          className="object-cover w-full h-full"
                          src={API_REMOTE + "/" + image}
                          alt=""
                        />
                      </div>
                    ))}
              </Slider>
            </div>

            <div id="get-annonce-info">
              <h2 className="text-center underline font-bold mb-3">
                VENDU PAR :
              </h2>
              <div id="sell-by" className="flex items-center gap-2">
                {userObj.image ? (
                  <img
                    className="w-full rounded-full"
                    src={API_REMOTE + "/" + userObj.image}
                    alt=""
                  />
                ) : (
                  <img
                    className="w-full rounded-full"
                    src="/joker.jpg"
                    alt="Joker"
                  />
                )}
                <h3>{userObj.firstname}</h3>
              </div>
              <p className="text-black text-center font-bold mt-5">
                {" "}
                <div className="relative">
                  <span className={`${hasOffer ? 'line-through' : 'text-black'}`}>
                    {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(annonce.prix/100)}
                  </span>
                  {hasOffer &&
                    (<span className="font-black ml-4">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(proposedPrice/100)}</span>)
                  }
                </div>
              </p>
              <div id="get-annonce-action" className="mt-5">
                <BtnPWicon
                  action={addToCart}
                  alt={"panier"}
                  contenu={"Ajouter à votre panier"}
                  icon={"/panier.png"}
                />
                <BtnPWicon
                  action={openOfferModal}
                  alt={"acheter"}
                  contenu={"Proposer une offre"}
                  icon={"/acheter.png"}
                />
                <BtnPWicon
                  action={handleUserClick}
                  alt={"message"}
                  contenu={"Écrire un message"}
                  icon={"/message.png"}
                />
                </div>
            </div>
          </div>
          <div className="max-w-3xl mt-5 mx-auto mb-5" id="get-annonce-desc">
            <h3 className="underline font-bold">DESCRIPTION :</h3>
            <p className="pt-4">{annonce.description}</p>
            {annonce.sous_categorie && (
              <p className="pt-2"><span className="text-purple-600">#</span> {annonce.sous_categorie}</p>
            )}
            {annonce.sous_sous_categorie && (
              <p className="pt-2"><span className="text-purple-600">#</span>{annonce.sous_sous_categorie}</p>
            )}
            {annonce.etat && <p className="pt-2"><span className="text-purple-600">#</span> {annonce.etat}</p>}
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <ChatModal selectedUserDefault={userObj} closeModal={closeModal} modalIsOpen={true} />
      )}
      {offerModalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg w-80">
            <h2 className="text-xl font-semibold mb-4">Proposer une offre</h2>
            <form onSubmit={handleOfferSubmit}>
              <label className="block mb-2">Nouveau prix :</label>
              <input
                type="number"
                name="newPrice"
                className="w-full border rounded p-2 mb-4"
                required
              />
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={closeOfferModal}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Proposer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
        <ToastContainer />
      <Store />
    </section>
  );
}
