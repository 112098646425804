import "../../../css/profil/profil.scss";
import HeaderProfil from "../../../components/Profil/HeaderProfil";
import Favoris from "./Favoris";
import VerifProfil from "./VerifProfil";
import User from "../../../requests/currentUser/User";

export default function Profil() {
  // Récupération de l'utilisateur courant
  const userObj = User();

  if (userObj && userObj.user.is_verified === 1) {
    return (
      <section style={{ backgroundImage: `url('/market.jpg')` }}>
        {userObj ? (
          <div id="profil-contain">
            <div id="profil-grid">
              <HeaderProfil />
              <div id="profil-fav">
                <Favoris />
              </div>
            </div>
          </div>
        ) : (
          <p>Aucun utilisateur connecté.</p>
        )}
      </section>
    );
  }
  return (
    <>
      <VerifProfil />
    </>
  );
}
