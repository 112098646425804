import axios from "axios";
import { API_REMOTE } from "../routes";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";

export default function UpdateAnnonce() {
  const { announcementId } = useParams();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();

  const [selectedCategorie, setSelectedCategorie] = useState("");
  const [selectedSubCategorie, setSelectedSubCategorie] = useState("");
  const [selectedSubSubCategorie, setSelectedSubSubCategorie] = useState("");
  const [selectedEtat, setSelectedEtat] = useState("");
  const [selectedLivraison, setSelectedLivraison] = useState("");
  const [selectedColis, setSelectedColis] = useState("");

  const isCategorie = (e) => {
    setSelectedCategorie(e.target.value);
    setSelectedSubCategorie("");
    setSelectedSubSubCategorie("");
  };
  const isSubCategorie = (e) => {
    setSelectedSubCategorie(e.target.value);
    setSelectedSubSubCategorie("");
  };
  const isSubSubCategorie = (e) => {
    setSelectedSubSubCategorie(e.target.value);
  };
  const isEtat = (e) => {
    setSelectedEtat(e.target.value);
  };
  const isLivraison = (e) => {
    setSelectedLivraison(e.target.value);
  };
  const isColis = (e) => {
    setSelectedColis(e.target.value);
  };

  const [errorMessage, setErrorMessage] = useState("");

  const categories = [
    "NEXT GEN",
    "RETROGAMING",
    "GOODIES",
    "CARTES A COLLECTIONNER",
    "FIGURINES",
    "JEUX DE CONSTRUCTION",
    "JOUETS ANCIEN",
    "MINIATURE AUTOMOBILE",
  ];

  const nextGen = [
    "PS5",
    "PS4",
    "PS3",
    "NINTENDO SWITCH",
    "XBOX SERIES X / S",
    "XBOX ONE",
    "XBOX 360",
    "WII U",
    "COLLECTOR",
    "PS VITA",
  ];

  const retrogaming = [
    "CONSOLE DE JEUX",
    "NINTENDO NES",
    "SNES",
    "NINTENDO 64",
    "GAMECUBE",
    "GAME BOY / GBC / GBA",
    "GAME & WATCH",
    "PS1",
    "WII",
    "PS2",
    "PSP",
    "PS VITA",
    "MASTER SYSTEM",
    "MEGADRIVE 1/2",
    "GAMEGEAR",
    "MEGA CD",
    "SEGA SATURN",
    "DREAMCAST",
    "XBOX",
  ];

  const figurines = [
    "FUNKO POP",
    "HIKARI FUNKO",
    "BANDAI",
    "SQUARE ENIX",
    "AMIIBO",
    "DC COMICS",
    "MARVEL",
    "TSUME",
  ];
  const figurinesBigIndex = ["BANDAI", "SQUARE ENIX", "TSUME"];
  const figurinesBandai = [
    "DRAGON BALL",
    "POWER RANGERS",
    "TORTUES NINJA",
    "NARUTO",
    "SAINT SEIYA",
    "COWBOY BEBOP",
    "FAIRY TAIL",
    "GTO",
    "BLEACH",
    "DEATH NOTE",
    "MY HERO ACADEMIA",
    "ONE PIECE",
    "GOLDORAK",
    "AUTRES...",
  ];
  const figurinesSquareEnix = [
    "FINAL FANTASY",
    "DRAGON QUEST",
    "KINGDOM HEARTS",
  ];
  const figurinesTsume = [
    "DRAGON BALL",
    "SAINT SEIYA",
    "COWBOY BEBOP",
    "FAIRY TAIL",
    "GTO",
    "BLEACH",
    "DEATH NOTE",
    "MY HERO ACADEMIA",
    "TERMINATOR",
    "DC COMICS",
    "ONE PIECE",
    "GOLDORAK",
    "AUTRES...",
  ];

  const goodies = [
    "OBJET PROMOTIONNEL / PLV",
    "VÊTEMENT GEEK",
    "PORTE-CLÉS",
    "AUTRES...",
  ];

  const jeuxConstruction = [
    "LEGO",
    "PLAYMOBIL",
    "MECANO",
    "KAPLA",
    "AUTRES...",
  ];

  const jouetsAncien = [
    "BARBIE",
    "COROLLE",
    "BANDAI",
    "JOUET EN BOIS",
    "AUTRES...",
  ];
  const jouetsAncienBigIndex = ["BANDAI"];

  const jouetsAncienBandai = [
    "DRAGON BALL",
    "POWER RANGERS",
    "SAINT SEIYA",
    "GOLDORAK",
    "DC COMICS",
    "TORTUES NINJA",
    "AUTRES...",
  ];

  const carteCollectionner = [
    "POKEMON",
    "YU-GI-OH",
    "DRAGON BALL",
    "PANINI",
    "CARTE NINTENDO",
    "MAGIC",
    "AUTRES...",
  ];

  const miniatureAuto = ["HOTWHEELS", "MAJORETTE", "BURAGO", "AUTRES..."];

  const etat = [
    "LOOSE - MAUVAIS ÉTAT",
    "LOOSE - ÉTAT SATISFAISANT",
    "LOOSE - EXCELLENT ÉTAT",
    "EN BOÎTE SANS NOTICE - MAUVAIS ÉTAT",
    "EN BOÎTE SANS NOTICE - ÉTAT SATISFAISANT",
    "EN BOÎTE - EXCELLENT ÉTAT",
    "CIB - MAUVAIS ÉTAT",
    "CIB - ÉTAT SATISFAISANT",
    "CIB - EXCELLENT ÉTAT",
    "NEUF SOUS BLISTER",
    "NEUF SOUS SCELLÉ",
    "NEUF GRADÉ",
  ];
  useEffect(() => {
    const getAnnonce = async () => {
      try {
        const response = await axios.get(
          `${API_REMOTE}/annonces/id/${announcementId}`
        );
        // console.log(response.data)
        setValue("name", response.data.name);
        setValue("sous_sous_categorie", response.data.sous_sous_categorie);
        setValue("sous_categorie", response.data.sous_categorie);
        setValue("categorie", response.data.categorie);
        setValue("etat", response.data.etat);
        setValue("type_livraison", response.data.type_livraison);
        setValue("taille_colis", response.data.taille_colis);
        setValue("prix", response.data.prix / 100);
        setValue("description", response.data.description);
        setValue("images", response.data.images);
      } catch (error) {
        console.error(error);
        setErrorMessage(
          "Une erreur s'est produite lors du chargement de l'annonce."
        );
      }
    };

    getAnnonce();
  }, [announcementId, setValue]);

  const onSubmit = async (data) => {
    try {
      const jwt = Cookies.get("jwt") && Cookies.get("jwt");
      await axios.put(`${API_REMOTE}/annonces/${announcementId}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${jwt}`,
        },
      });
      navigate("/ma_vitrine");
    } catch (e) {
      console.log(e.message);
      console.log(data);
    }
  };
  return (
    <section>
      <div
        className="w-full flex justify-center"
        id="subscribe-contain"
        style={{ backgroundImage: `url("/market.jpg")` }}
      >
        <form
          class="w-3/4 p-4 bg-white rounded-xl flex justify-center"
          onSubmit={handleSubmit(onSubmit)}
          enctype="multipart/form-data"
        >
          {" "}
          {/*  subscribe-form */}
          <div className="w-3/5" id="">
            {" "}
            {/* subscribe-flex */}
            <h1 className="text-3xl text-center my-8 font-bold">
              MODIFIER MON ANNONCE
            </h1>
            <div className="flex flex-col w-full">
              {" "}
              {/* subscribe-name space-x-5 */}
              <div className="">
                {" "}
                {/* subscribe-name-container */}
                <input
                  className="w-full  border-2 border-black rounded-full p-2 my-2"
                  type="text"
                  id="name"
                  name="name"
                  required
                  placeholder="Titre de l'annonce..."
                  {...register("name", { required: true })}
                />
              </div>
              <div className="subscribe-name-container">
                <div className="flex">
                  <select
                    name="Categorie"
                    value={selectedCategorie}
                    {...register("categorie", { required: true })}
                    class={`w-full  border-2 border-black rounded-full p-2 my-2 ${
                      selectedCategorie && "mr-2"
                    }`}
                    onChange={isCategorie}
                  >
                    <option value="">Catégorie...</option>
                    {categories.map((categorie) => {
                      return <option value={categorie}>{categorie}</option>;
                    })}
                  </select>

                  {selectedCategorie === "NEXT GEN" && (
                    <select
                      name="SousCategorie"
                      value={selectedSubCategorie}
                      {...register("sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2`}
                      onChange={isSubCategorie}
                    >
                      <option value="">Sous catégorie</option>
                      {nextGen.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}

                  {selectedCategorie === "RETROGAMING" && (
                    <select
                      name="SousCategorie"
                      value={selectedSubCategorie}
                      {...register("sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2`}
                      onChange={isSubCategorie}
                    >
                      <option value="">Sous catégorie</option>
                      {retrogaming.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}

                  {selectedCategorie === "FIGURINES" && (
                    <select
                      name="SousCategorie"
                      value={selectedSubCategorie}
                      {...register("sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2 ${
                        figurinesBigIndex.includes(selectedSubCategorie) &&
                        "mr-2"
                      }`}
                      onChange={isSubCategorie}
                    >
                      <option value="">Sous catégorie</option>
                      {figurines.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}

                  {selectedCategorie === "FIGURINES" &&
                    selectedSubCategorie === "BANDAI" && (
                      <select
                        name="SousSousCategorie"
                        value={selectedSubSubCategorie}
                        {...register("sous_sous_categorie", { required: true })}
                        class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2`}
                        onChange={isSubSubCategorie}
                      >
                        <option value="">Sous sous catégorie</option>
                        {figurinesBandai.map((subcategorie) => {
                          return (
                            <option value={subcategorie}>{subcategorie}</option>
                          );
                        })}
                      </select>
                    )}

                  {selectedSubCategorie === "SQUARE ENIX" && (
                    <select
                      name="SousSousCategorie"
                      value={selectedSubSubCategorie}
                      {...register("sous_sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2`}
                      onChange={isSubSubCategorie}
                    >
                      <option value="">Sous sous catégorie</option>
                      {figurinesSquareEnix.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}

                  {selectedSubCategorie === "TSUME" && (
                    <select
                      name="SousSousCategorie"
                      value={selectedSubSubCategorie}
                      {...register("sous_sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2`}
                      onChange={isSubSubCategorie}
                    >
                      <option value="">Sous sous catégorie</option>
                      {figurinesTsume.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}

                  {selectedCategorie === "GOODIES" && (
                    <select
                      name="SousCategorie"
                      value={selectedSubCategorie}
                      {...register("sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2`}
                      onChange={isSubCategorie}
                    >
                      <option value="">Sous catégorie</option>
                      {goodies.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}

                  {selectedCategorie === "JEUX DE CONSTRUCTION" && (
                    <select
                      name="SousCategorie"
                      value={selectedSubCategorie}
                      {...register("sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2`}
                      onChange={isSubCategorie}
                    >
                      <option value="">Sous catégorie</option>
                      {jeuxConstruction.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}

                  {selectedCategorie === "JOUETS ANCIEN" && (
                    <select
                      name="SousCategorie"
                      value={selectedSubCategorie}
                      {...register("sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2 ${
                        jouetsAncienBigIndex.includes(selectedSubCategorie) &&
                        "mr-2"
                      }`}
                      onChange={isSubCategorie}
                    >
                      <option value="">Sous catégorie</option>
                      {jouetsAncien.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}

                  {selectedCategorie === "JOUETS ANCIEN" &&
                    selectedSubCategorie === "BANDAI" && (
                      <select
                        name="SousSousCategorie"
                        value={selectedSubSubCategorie}
                        {...register("sous_sous_categorie", { required: true })}
                        class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2`}
                        onChange={isSubSubCategorie}
                      >
                        <option value="">Sous sous catégorie</option>
                        {jouetsAncienBandai.map((subcategorie) => {
                          return (
                            <option value={subcategorie}>{subcategorie}</option>
                          );
                        })}
                      </select>
                    )}

                  {selectedCategorie === "CARTES A COLLECTIONNER" && (
                    <select
                      name="SousCategorie"
                      value={selectedSubCategorie}
                      {...register("sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2 ${
                        jouetsAncienBigIndex.includes(selectedSubCategorie) &&
                        "mr-2"
                      }`}
                      onChange={isSubCategorie}
                    >
                      <option value="">Sous catégorie</option>
                      {carteCollectionner.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}

                  {selectedCategorie === "MINIATURE AUTOMOBILE" && (
                    <select
                      name="SousCategorie"
                      value={selectedSubCategorie}
                      {...register("sous_categorie", { required: true })}
                      class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2 ${
                        jouetsAncienBigIndex.includes(selectedSubCategorie) &&
                        "mr-2"
                      }`}
                      onChange={isSubCategorie}
                    >
                      <option value="">Sous catégorie</option>
                      {miniatureAuto.map((subcategorie) => {
                        return (
                          <option value={subcategorie}>{subcategorie}</option>
                        );
                      })}
                    </select>
                  )}
                </div>
                {/* {categorie === "underfined" ? "vous devez choisir une categorie" : ""} */}
                <div className="flex">
                  <select
                    name="état"
                    value={selectedEtat}
                    {...register("etat", { required: true })}
                    class={`w-full  border-2 border-black rounded-full p-2 my-2 mr-2`}
                    onChange={isEtat}
                  >
                    <option value="">État...</option>
                    {etat.map((subcategorie) => {
                      return (
                        <option value={subcategorie}>{subcategorie}</option>
                      );
                    })}
                  </select>

                  <input
                    className="w-full  border-2 border-black rounded-full p-2 my-2 ml-2 h-[39px]"
                    type="text"
                    id="prix"
                    name="prix"
                    required
                    placeholder="Prix"
                    {...register("prix", { required: true })}
                  />
                </div>
                <div className="flex">
                  <select
                    name="type_livraison"
                    value={selectedLivraison}
                    {...register("type_livraison", { required: true })}
                    class={`w-full  border-2 border-black rounded-full p-2 my-2 mr-2`}
                    onChange={isLivraison}
                  >
                    <option value="">Type de livraison...</option>
                    <option value="1">Default</option>
                    {/* {etat.map((subcategorie) => {
                          return <option value={subcategorie}>{subcategorie}</option>
                        })} */}
                  </select>

                  <select
                    name="taille_colis"
                    value={selectedColis}
                    {...register("taille_colis", { required: true })}
                    class={`w-full  border-2 border-black rounded-full p-2 my-2 ml-2`}
                    onChange={isColis}
                  >
                    <option value="">Taille du colis...</option>
                    <option value="1">Default</option>
                    {/* {etat.map((subcategorie) => {
                          return <option value={subcategorie}>{subcategorie}</option>
                        })} */}
                  </select>
                </div>
              </div>
            </div>
            <div className="subscribe-info"></div>
            <div className="subscribe-info">
              <textarea
                className="w-full  border-2 border-black rounded-3xl p-2 my-2"
                style={{ height: "150px", resize: "none" }}
                type="text"
                id="description"
                name="description"
                required
                placeholder="Description de l'objet..."
                {...register("description", { required: true })}
              />
            </div>
            <div className="subscribe-info">
              <input
                type="file"
                id="image"
                name="images"
                accept="image/*"
                multiple
                {...register("images")}
              />
            </div>
            <div id="" className="flex justify-center mt-4">
              {" "}
              {/* subscribe-btn-container */}
              <button className="" id="publier" type="submit">
                {" "}
                {/* subscribe-btn */}
                MODIFIER CETTE ANNONCE
              </button>
            </div>
          </div>
        </form>
        {errorMessage && (
          <p className="text-red-500 my-4 max-w-[10rem] text-center">
            {errorMessage.message}
          </p>
        )}
      </div>
    </section>
  );
}
